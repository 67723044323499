<template>
    <div class="section-wrapper">
        <div class="section-content">
            <div class="left">
                <div class="circle first">
                    <i class="material-icons">pets</i>
                </div>
                <div class="circle second">
                    <i class="material-icons">pets</i>
                </div>
                <div class="circle third">
                    <i class="material-icons">pets</i>
                </div>
            </div>
            <div class="right">
                <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam hendrerit, mauris ac euismod dignissim, nunc enim commodo magna, eget tempus turpis metus eu enim.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam hendrerit, mauris ac euismod dignissim, nunc enim commodo magna, eget tempus turpis metus eu enim.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam hendrerit, mauris ac euismod dignissim, nunc enim commodo magna, eget tempus turpis metus eu enim.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Section4',
}
</script>

<style lang="scss" scoped>
    p {
        font-weight: 400;
        font-size: 13px;
        text-align: justify;
        color: #fff;
    }
    h4 {
        font-weight: 600;
        font-size: 35px;
        margin: 0;
        margin-bottom: 50px;
        color: #fff;
    }
    .section-wrapper {
        padding: 100px 0;
        display: flex;
        justify-content: center;
        background: linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
    }
    .section-content {
        display: flex;
        max-width: 1200px;
        min-height: 500px;
        align-items: center;
        justify-content: center;
    }
    .right {
        width: 40%;
        margin-right: 25px;
    }
    .left {
        width: 60%;
        height: 100%;
        position: relative;
    }
    .left .circle {
        width: 250px;
        height: 250px;
        background: rgba(#fff, .5);
        border-radius: 50%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .left .circle.first {
        left: 70px;
    }
    .left .circle.second {
        left: 350px;
        top: 150px;
    }
    .left .circle.third {
        top: 300px;
    }
    .left .circle i {
        background: -webkit-linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 150px;
    }
    @media (max-width: 1050px) {
        .section-content {
            flex-direction: column;
            align-items: center;
        }
        .right {
            max-width: 500px;
            width: 90%;
            margin: 0;
            margin-top: 600px;
        }
        .left {
            width: 100%;
        }
    }
    @media (max-width: 750px) {
        .left .circle {
            width: 200px;
            height: 200px;
        }
        .left .circle.first {
            left: 70px;
        }
        .left .circle.second {
            left: 250px;
            top: 150px;
        }
        .left .circle.third {
            top: 250px;
        }
        .left .circle i {
            font-size: 120px;
        }
    }
    @media (max-width: 460px) {
        .left .circle {
            width: 170px;
            height: 170px;
        }
        .left .circle.first {
            left: 70px;
        }
        .left .circle.second {
            left: 200px;
            top: 150px;
        }
        .left .circle.third {
            top: 200px;
        }
        .left .circle i {
            font-size: 100px;
        }
        .right {
            margin-top: 450px;
        }
    }
    @media (max-width: 380px) {
        .left .circle.first {
            left: 40px;
            top: 20px;
        }
        .left .circle.second {
            left: 185px;
            top: 150px;
        }
        .left .circle.third {
            left: 10px;
            top: 200px;
        }
        .left .circle i {
            font-size: 100px;
        }
    }
</style>
