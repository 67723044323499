<template>
  <section id="theme1-section1">
    <article>
      <h3>Lorem ipsum</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id sollicitudin lectus. Proin rhoncus dui ut turpis imperdiet, in dignissim sem finibus. Aenean auctor nulla sit amet erat dictum, at interdum nibh vehicula. Nunc non ullamcorper augue, ut interdum nunc. Nulla non sagittis turpis. Suspendisse condimentum magna ut auctor lobortis. Aliquam tincidunt tincidunt ipsum id condimentum. Proin feugiat interdum luctus. Donec vestibulum lectus erat, eget tincidunt felis tincidunt ac. Nulla venenatis, quam porttitor sodales gravida, lorem ipsum volutpat quam, eget eleifend arcu tellus sit amet lacus.</p>
    </article>
  </section>
</template>

<script>
export default {
  name: 'Theme1Section1',
 
}
</script>

<style scoped lang="scss">
  section#theme1-section1 { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; padding: 100px 0; background: rgb(1,130,226); 
    background: linear-gradient(90deg, rgba(1,130,226,1) 0%, rgba(0,237,147,1) 100%); }

  article { width: 1000px; margin: 0 50px; }
  article h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 300; color: #fff; text-align: center; }
  article p { margin: 0; padding: 0; font-size: 18px; color: rgba(255, 255, 255, 0.8); line-height: 30px; text-align: center; }


  @media (max-width: 700px) {
    section#theme1-section1 { padding: 50px 0; }
    article h3 { font-size: 36px; }
  }
</style>
