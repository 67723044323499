<template>
    <div id="theme1-section5" class="section-wrapper">
        <div class="head">
            <h3>Lorem ipsum dolor sit</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
        </div>
        <div class="boxes">
            <div class="box">
                <div class="icon">
                    <i class="material-icons">person_outline</i>   
                </div>
                <h4>Lorem ipsum dolor</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant.</p>
            </div>
            <div class="box">
                <div class="icon">
                    <i class="material-icons">person_outline</i>   
                </div>
                <h4>Lorem ipsum dolor</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant.</p>
            </div>
            <div class="box">
                <div class="icon">
                    <i class="material-icons">person_outline</i>   
                </div>
                <h4>Lorem ipsum dolor</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Section3',
}
</script>

<style lang="scss" scoped>
    p {
        font-weight: 300;
        font-size: 18px;
        line-height: 25px;
        color: rgba(0, 0, 0, 0.5);
    }
    h3 {
        padding: 10px;
        font-weight: 400;
        font-size: 50px;
        margin-bottom: 20px;
        color: #0182e2;
    }
    h4 {
        font-size: 22px;
        margin: 0;
        padding: 0;
        color: #0182e2;
        font-weight: 400;
    }
    .section-wrapper {
        border-top: 1px solid rgba(1,130,226,0.3);
        box-shadow: inset 0 5px 5px rgba(1,130,226,0.1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 0;   
        position: relative;
    }
    .head {
        max-width: 600px;
        width: 90%;
        text-align: center;
        margin-bottom: 30px;
    }
    .boxes {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
    }
    .boxes .box {
        width: 350px;
        margin: 15px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .boxes .box p { font-size: 15px; }
    .boxes .box .icon {
        background: rgb(1,130,226);
        background: linear-gradient(90deg, rgba(1,130,226,1) 0%, rgba(0,237,147,1) 100%);
        box-shadow: 8px 2px 2px #e0e0e0;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .boxes .box .icon i {
        color: #fff;
        font-size: 45px;
    }
    .diamond {
        width: 200px;
        height: 200px;
        background: rgba(#0182e2, .15);
        transform: rotate(45deg);
        z-index: -1;
        position: absolute;
    }
    .diamond.first {
        left: -100px;
        top: 20px;
    }
    .diamond.second {
        right: -100px;
        bottom: 20px;
    }
    .diamond.third {
        width: 150px;
        height: 150px;
        top: 350px;
        left: 300px;
    }
    .diamond.fourth {
        width: 150px;
        height: 150px;
        bottom: 350px;
        right: 300px;
    }
    @media (max-width: 900px) {
        .diamond.fourth {
            top: 100px;
            left: 200px;
        }
    }

    @media (max-width: 700px) {
        .section-wrapper { padding: 50px 0;; }
        h3 { font-size: 36px; }
        .boxes .box { margin: 20px;  }
    }

    @media (max-width: 300px) {
        .boxes .box { width: calc(100vw - 40px);  }
    }
    
</style>
