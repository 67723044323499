<template>
  <div class="sectionline">
    <div class="line"></div>
    <div class="circles">
      <div class="circle" :class="{ active: activeSection == 'theme1-card' }" @click.prevent="scrollToElement('theme1-card')">
        <p>1</p>
      </div>
      <div class="circle" :class="{ active: activeSection == 'theme1-section5' }" @click.prevent="scrollToElement('theme1-section5')">
        <p>2</p>
      </div>
      <div class="circle" :class="{ active: activeSection == 'theme1-section1' }" @click.prevent="scrollToElement('theme1-section1')">
        <p>3</p>
      </div>
      <div class="circle" :class="{ active: activeSection == 'theme1-section3' }" @click.prevent="scrollToElement('theme1-section3')">
        <p>4</p>
      </div>
      <div class="circle" :class="{ active: activeSection == 'theme1-section4' }" @click.prevent="scrollToElement('theme1-section4')">
        <p>5</p>
      </div>
      <div class="circle" :class="{ active: activeSection == 'theme1-section2' }" @click.prevent="scrollToElement('theme1-section2')">
        <p>6</p>
      </div>
      <div class="circle" :class="{ active: activeSection == 'theme1-section-contact' }" @click.prevent="scrollToElement('theme1-section-contact')">
        <p>7</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionLine',
  computed:{
    activeSection() {
      return this.$store.state.onePageActiveSection;
    },
  },
  methods: {
    scrollToElement(id) {
      const element = document.getElementById(id);
      const offset = 70;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - offset + window.pageYOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    },
  }
}
</script>

<style scoped lang="scss">
  div.sectionline { width: 75px; height: 100vh; position: fixed; top: 0; left: 0; z-index: 4; }
  div.line { width: 1px; height: 60%; background: rgba(0, 0, 0, 0.1); position: absolute; top: calc((100vh - 60%) / 2); left: 50%; z-index: 1; }

  div.circles { display: flex; flex-direction: column; justify-content: center; align-items: center; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 2; }
  div.circle { display: flex; justify-content: center; align-items: center; width: 30px; height: 30px; margin: 15px 0; border-radius: 50%; border: 1px solid rgba(0, 0, 0, 0.3); background: #fff; transition: 0.5s; }
  div.circle:hover { border: 1px solid #00ed93; cursor: pointer; }
  div.circle.active { background: #00ed93; border: 1px solid #0BD64F; transition: 1s; }
  div.circle p { margin: 0; padding: 0; font-size: 12px; color: rgba(0, 0, 0, 0.8); font-weight: 500; }
  div.circle:hover p { color: #00ed93; }
  div.circle.active p { color: #fff; }

  @media (max-width: 1000px) {
    div.sectionline { display: none; }
  }  
</style>
