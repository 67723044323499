<template>
  <section class="theme2-faq">
    <header>
      <h3>Często zadawane pytania</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id sollicitudin lectus. Proin rhoncus dui ut turpis imperdiet.</p>
    </header>
    <div class="flex">
      <article>
        <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus gravida lacus sit amet nulla lacinia?</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus gravida lacus sit amet nulla lacinia, et suscipit eros finibus. Sed dapibus commodo elit, at iaculis libero placerat sit amet. Donec sed convallis eros. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean in nisi auctor turpis sodales porttitor. Quisque et orci at purus placerat varius. Donec dui enim, bibendum at lectus a.</p>
      </article>
      <article>
        <h5>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus gravida lacus sit amet nulla lacinia?</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus gravida lacus sit amet nulla lacinia, et suscipit eros finibus. Sed dapibus commodo elit, at iaculis libero placerat sit amet. Donec sed convallis eros. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean in nisi auctor turpis sodales porttitor. Quisque et orci at purus placerat varius. Donec dui enim, bibendum at lectus a.</p>
      </article>
      <article>
        <h5>Lorem ipsum dolor sit amet?</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus gravida lacus sit amet nulla lacinia, et suscipit eros finibus. Sed dapibus commodo elit, at iaculis libero placerat sit amet. Donec sed convallis eros. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean in nisi auctor turpis sodales porttitor. Quisque et orci at purus placerat varius. Donec dui enim, bibendum at lectus a.</p>
      </article>
      <article>
        <h5>Lorem ipsum dolor sit amet?</h5>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus gravida lacus sit amet nulla lacinia, et suscipit eros finibus. Sed dapibus commodo elit, at iaculis libero placerat sit amet. Donec sed convallis eros. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean in nisi auctor turpis sodales porttitor. Quisque et orci at purus placerat varius. Donec dui enim, bibendum at lectus a.</p>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Theme2Faq',
 
}
</script>

<style scoped lang="scss">
  section.theme2-faq { display: flex; flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap; padding: 100px 0; }

  header { width: 600px; }
  header h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 500; color: #5041a0; text-align: center; }
  header p { margin: 0; padding: 0; font-size: 16px; color: rgba(0, 0, 0, 0.8); line-height: 25px; text-align: center; }

  div.flex { display: flex; flex-wrap: wrap; width: 1280px; margin: 50px 0 0 0; }

  article { width: 600px; margin: 20px; }
  article h5 { margin: 0 0 10px 0; padding: 20px; box-shadow: 0 0 5px #5041a0; font-weight: 500; font-size: 18px; color: #5041a0; border-radius: 3px; }
  article p { margin: 0; padding: 20px; box-shadow: 0 0 5px #5041a0; font-size: 15px; color: rgba(0, 0, 0, 0.8); border-radius: 3px; }
</style>
