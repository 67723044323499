<template>
    <div class="gallery-wrapper">
        <div class="boxes-wrapper">
            <div class="box" v-for="(element, index) in gallery" :key="index + element" @click="toggleZoom(element.img)">
                <div class="image" :style="{ 'background-image': `url( ${ element.img } )`, height: photoHeight + 'px' }">
                    <div class="mask">
                        <h3>{{ element.title }}</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="image-zoom" v-if="imageZoom" @click="toggleZoom('')">
            <div :style="{ 'background-image': `url( ${ imageZoom } )`, height: zoomPhotoHeight + 'px' }" @click.stop>
                <button class="material-icons" @click="toggleZoom('')">close</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Theme4GalleryComponent',
    data() {
        return {
            gallery: [{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1613190511341-fc41f047193c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1600047509358-9dc75507daeb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1568605115459-4b731184f961?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1549517045-bc93de075e53?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1351&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1588012886079-baef0ac45fbd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1347&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1576941089067-2de3c901e126?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1443&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1591170715502-fbc32adc4f52?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            },],
            imageZoom: '',
            windowWidth: 0,
            photoHeight: null,
            zoomPhotoHeight: null,
        }
    },
    watch: {
        windowWidth() {
            this.setPhotoHeight();
        },
    },
    methods: {
        toggleZoom(img) {
            if(img) {
                document.body.style = "overflow-y: hidden";
            } else {
                document.body.style = "overflow-y: overlay";
            };
            this.imageZoom = img;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        setPhotoHeight() {
            if(this.windowWidth <= 800) {
                this.zoomPhotoHeight = this.windowWidth * 0.9 / 16 * 9;
                this.photoHeight = this.windowWidth * 0.8 / 16 * 9;
            }else if(this.windowWidth <= 1050) {
                this.zoomPhotoHeight = this.windowWidth * 0.9 / 16 * 9;
                this.photoHeight = this.windowWidth * 0.8 / 2 / 16 * 9;
            } else {
                this.zoomPhotoHeight = 1000 / 16 * 9;
                this.photoHeight = this.windowWidth * 0.8 / 3 / 16 * 9;
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    h3 {
        color: $Theme4Primary;
    }
    .gallery-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
        position: relative;
        background-color: $Theme4Secondary;
    }
    div.boxes-wrapper {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    div.box {
        width: calc(100% / 3 - 30px);
        overflow: hidden;
        text-align: center;
        margin: 5px;
    }
    div.box div.image {
        width: 100%;
        background-color: #000;
        background-size: cover;
        position: relative;
    }
    div.box div.mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($Theme4Secondary, .8);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity .3s;
    }
    div.box:hover {
        cursor: pointer;
    }
    div.box:hover > div.image div.mask {
        opacity: 1;
    }
    div.image-zoom {
        position: fixed;
        top: 0;
        background: rgba(#000, .8);
        width: 100vw;
        height: 100vh;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.image-zoom div {
        width: 1000px;
        display: flex;
        background-size: cover;
        justify-content: flex-end;
        align-items: flex-start;
    }
    div.image-zoom div button {
        color: #fff;
        background: rgba(#000, .5);
        border: none;
        padding: 0;
        font-size: 30px;
        margin: 10px;
    }
    div.image-zoom div button:hover {
        cursor: pointer;
    }
    @media (max-width: 1050px) {
        div.image-zoom div {
            width: 90vw;
        }
        div.box {
            width: calc(100% / 2 - 20px);
        }
    }
    @media (max-width: 800px) {
        div.box {
            width: calc(100% - 10px);
        }
    }
</style>
