<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created(){
    this.checkProject();
  },
  watch: {
    $route(to, from){
      this.checkProject();
    }
  },
  methods: {
    checkProject(){
      if(this.$route.path == '/projekt' && typeof this.$route.params.number == 'undefined'){
        this.$router.push({ name: 'Project', params: { number: 1 } });
      }
    },
  }
}
</script>

<style lang="scss">
div#app { margin: 0; padding: 0; }
</style>
