<template>
  <section class="theme2-section3">
    <div class="flex">
      <div class="container">
        <h3>Lorem ipsum</h3>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce suscipit sem lorem, eu consequat dui pretium sit amet. Sed malesuada sapien urna, viverra vulputate urna efficitur eu. Phasellus et nibh tincidunt ex egestas dictum et imperdiet mauris. Maecenas feugiat odio porta feugiat posuere. Morbi egestas consectetur sapien in dapibus.</p>
      </div>
      <div class="bubbles">
        <div class="bubble first">
          <h6>16</h6>
          <p>ipsum</p>
        </div>
        <div class="bubble second">
          <h6>16</h6>
          <p>ipsum</p>
        </div>
        <div class="bubble third">
          <h6>16</h6>
          <p>ipsum</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Theme2Section3',
 
}
</script>

<style scoped lang="scss">
  section.theme2-section3 { display: flex; flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap; padding: 100px 0; background: #f6f4f9; }

  div.flex { display: flex; align-items: center; }

  div.container { width: 600px; margin-right: 50px; }
  h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 500; color: #5041a0;  }
  p { margin: 0; padding: 0; font-size: 16px; color: rgba(0, 0, 0, 0.8); line-height: 25px; }

  div.bubbles { width: 400px; height: 300px; position: relative; }
  div.bubbles div.bubble { display: flex; flex-direction: column; align-items: center; justify-content: center; width: 200px; height: 200px; position: absolute; background: rgba(80,65,160,0.6); border-radius: 50%; }
  div.bubbles div.bubble h6 { margin: 0; padding: 0; font-weight: 400; color: #fff; font-size: 55px; }
  div.bubbles div.bubble p { margin: 0; padding: 0; font-size: 18px; color: #fff; }
  div.bubbles div.bubble.first { width: 140px; height: 140px; top: 0; left: 0; z-index: 2; }
  div.bubbles div.bubble.first h6 { font-size: 35px; }
  div.bubbles div.bubble.first p { font-size: 15px; }
  div.bubbles div.bubble.second { top: 80px; left: 50px; background: rgba(0,237,147,0.5); }
  div.bubbles div.bubble.third { width: 100px; height: 100px; top: 60px; left: 220px; background: rgba(101,44,159,0.5); }
  div.bubbles div.bubble.third h6 { font-size: 25px; }
  div.bubbles div.bubble.third p { font-size: 13px; }
</style>
