<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
export default {
    name: 'Carousel3Wrapper',
}
</script>

<style>
    
</style>