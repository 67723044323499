<template>
    <div>
        <!-- <carousel-1></carousel-1> -->
        <!-- <carousel-2></carousel-2> -->
        <!-- <carousel-3></carousel-3> -->
        <!-- <carousel-4></carousel-4> -->
        <!-- <faq-1></faq-1> -->
        <!-- <faq-2></faq-2> -->
        <!-- <form-1></form-1> -->
        <!-- <form-3></form-3> -->
        <!-- <gallery-2></gallery-2> -->
        <!-- <gallery-3></gallery-3> -->
        <!-- <section-1></section-1> -->
        <!-- <section-2></section-2> -->
        <!-- <section-3></section-3> -->
        <!-- <section-4></section-4> -->
        <!-- <section-5></section-5> -->
        <!-- <section-6></section-6> -->
        <!-- <section-7></section-7> -->
        <!-- <gallery-1></gallery-1> -->
        <!-- <form-2></form-2> -->
    </div>
</template>

<script>
import Carousel1 from './carousels/carousel1/Carousel1';
import Carousel2 from './carousels/carousel2/Carousel2';
import Carousel3 from './carousels/carousel3/Carousel3';
import Carousel4 from './carousels/carousel4/Carousel4';
import Faq1 from './faqs/Faq1';
import Faq2 from './faqs/Faq2';
import Form1 from './forms/form1';
import Form2 from './forms/form2';
import Form3 from './forms/form3';
import Gallery1 from './galleries/Gallery1';
import Gallery2 from './galleries/Gallery2';
import Gallery3 from './galleries/Gallery3';
import Section1 from './sections/section1';
import Section2 from './sections/section2';
import Section3 from './sections/section3';
import Section4 from './sections/section4';
import Section5 from './sections/section5';
import Section6 from './sections/section6';
import Section7 from './sections/section7';

export default {
    name: 'AllElements',
    components: {
        Carousel1,
        Carousel2,
        Carousel3,
        Carousel4,
        Faq1,
        Faq2,
        Form1,
        Form2,
        Form3,
        Gallery1,
        Gallery2,
        Gallery3,
        Section1,
        Section2,
        Section3,
        Section4,
        Section5,
        Section6,
        Section7,
    },
}
</script>

<style lang="scss" scoped>

</style>
