import { render, staticRenderFns } from "./HomeIdentification.vue?vue&type=template&id=73a1ff64&scoped=true&"
import script from "./HomeIdentification.vue?vue&type=script&lang=js&"
export * from "./HomeIdentification.vue?vue&type=script&lang=js&"
import style0 from "./HomeIdentification.vue?vue&type=style&index=0&id=73a1ff64&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a1ff64",
  null
  
)

export default component.exports