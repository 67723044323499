<template>
  <div id="theme1-home">
    <theme1-the-header></theme1-the-header>
    <theme1-section-line></theme1-section-line>
    <theme1-card></theme1-card>
    <section3></section3>
    <theme1-section1></theme1-section1>
    <theme1-section3></theme1-section3>
    <theme1-section4></theme1-section4>
    <theme1-section2></theme1-section2>
    <theme1-section-contact></theme1-section-contact>
    <theme1-the-footer></theme1-the-footer>
    <theme-changer></theme-changer>
  </div>
</template>

<script>
import Theme1TheHeader from '@/components/themes/theme1/Theme1TheHeader.vue'
import Theme1TheFooter from '@/components/themes/theme1/Theme1TheFooter.vue'
import Theme1SectionLine from '@/components/themes/theme1/Theme1SectionLine.vue'

import Theme1Card from '@/components/themes/theme1/Theme1Card.vue'
import Section3 from '@/components/themes/sections/section3.vue'
import Theme1Section1 from '@/components/themes/theme1/Theme1Section1.vue'
import Theme1Section2 from '@/components/themes/theme1/Theme1Section2.vue'
import Theme1Section3 from '@/components/themes/theme1/Theme1Section3.vue'
import Theme1Section4 from '@/components/themes/theme1/Theme1Section4.vue'
import Theme1SectionContact from '@/components/themes/theme1/Theme1SectionContact.vue'

import ThemeChanger from '@/components/ThemeChanger.vue'

export default {
  name: 'Home',
  components: {
    Theme1TheHeader,
    Theme1TheFooter,
    Theme1SectionLine,
    Theme1Card,
    Section3,
    Theme1Section1,
    Theme1Section2,
    Theme1Section3,
    Theme1Section4,
    Theme1SectionContact,
    ThemeChanger
  },
  data(){
    return {
      observersOptions: {
        root: null,
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
      observers: [],
      sections: ['theme1-card', 'theme1-section5', 'theme1-section1', 'theme1-section3', 'theme1-section4', 'theme1-section2', 'theme1-section-contact'],
      sectionsIntersections: [],
      sectionVisible: 0,
    }
  },
  mounted(){
    this.$store.commit('changeOnePageActiveSection', this.sections[0])
    let target;
    let self = this;
    for(let i = 0; i < this.sections.length; i++){
      this.sectionsIntersections.push(0);
      this.observers.push(new IntersectionObserver( entries => {
        this.callback(i, self.sections[i], entries[0]);
      }, this.observersOptions));
      target = document.getElementById(this.sections[i]);
      this.observers[i].observe(target)
    }
  },
  methods: {
    callback(index, section, entry){
      this.sectionsIntersections[index] = entry.intersectionRatio;
      let max = 0;
      let maxIndex = -1;
      for(let i = 0; i < this.sectionsIntersections.length; i++){
        if(this.sectionsIntersections[i] > max){
          max = this.sectionsIntersections[i];
          maxIndex = i;
        }
      }
      if(maxIndex == index && entry.isIntersecting){
        this.sectionVisible = section;
          this.$store.commit('changeOnePageActiveSection', this.sectionVisible)
      }
    }
  },
}
</script>

<style scoped lang="scss">
  div#theme1-home { margin: 0; }
</style>
