<template>
  <div class="theme4-home">
    <theme4-home-card></theme4-home-card>
    <theme4-section1 id="theme4-section1"></theme4-section1>
    <theme4-section2></theme4-section2>
    <theme-changer></theme-changer>
  </div>
</template>

<script>
import Theme4HomeCard from '@/components/themes/theme4/Theme4HomeCard.vue';
import Theme4Section1 from '@/components/themes/theme4/Theme4Section1.vue';
import Theme4Section2 from '@/components/themes/theme4/Theme4Section2.vue';

import ThemeChanger from '@/components/ThemeChanger.vue';

export default {
  name: 'theme4Home',
  components: {
    ThemeChanger,
    Theme4HomeCard,
    Theme4Section1,
    Theme4Section2,
  }
}
</script>

<style scoped lang="scss">
  div.theme4-home { margin: 0; }
</style>
