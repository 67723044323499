<template>
  <section class="theme2-home-card">
    <div class="container">
      <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit!</h2>
      <div class="links">
        <a href="#">Get started</a>
        <a class="transparent" href="#">More</a>
      </div>
    </div>
    <div class="circle first">
      <div class="circle-small"></div>
    </div>
    <div class="circle second">
      <div class="circle-small"></div>
    </div>
    <div class="circle third">
      <div class="circle-small"></div>
    </div>
    <div class="circle fourth">
      <div class="circle-small"></div>
    </div>
    <div class="circle fifth">
      <div class="circle-small"></div>
    </div>
    <img class="svg" src="../../../assets/themes/theme2/wave2.svg">
  </section>
</template>

<script>
export default {
  name: 'Theme2HomeCard',
 
}
</script>

<style scoped lang="scss">
  section.theme2-home-card { min-height: 75vh; padding-top: 70px; display: flex; flex-direction: column; justify-content: center; align-items: center; position: relative; background: #5041a0; }
  div.container { display: flex; flex-direction: column; align-items: center; position: relative; z-index: 2; }
  img { display: block; width: 500px; height: 497px; }
  h2 { width: 700px; margin: 0 0 50px 0; padding: 0; font-weight: 500; font-size: 50px; color: #fff; text-align: center; }
  a { display: inline-block; width: 200px; margin: 0 30px; padding: 13px 0; text-align: center; background: #00ed93; border-radius: 25px; text-decoration: none; color: #fff; letter-spacing: 1px; font-size: 14px; font-weight: 500;
  border: 2px solid #00ed93; }
  a:hover { background: none; color: #00ed93; }
  a.transparent { background: none; border: 2px solid #fff; }
  a.transparent:hover { background: #fff; color: #000; }

  div.circle { display: flex; justify-content: center; align-items: center; width: 180px; height: 180px; border-radius: 50%; background: rgb(108,52,163); background: linear-gradient(135deg, rgba(108,52,163,1) 0%, rgba(0,237,147,0.3) 100%);
    position: absolute; z-index: 2; }
  div.circle div.circle-small { width: 160px; height: 160px; border-radius: 50%; background: #652c9f; }
  div.circle.first { bottom: 80px; left: 50px; }
  div.circle.second { width: 80px; height: 80px; top: 200px; left: 350px; }
  div.circle.second div.circle-small { width: 65px; height: 65px; }
  div.circle.third { width: 100px; height: 100px; top: 30px; left: 45%; }
  div.circle.third div.circle-small { width: 80px; height: 80px; }
  div.circle.fourth { width: 120px; height: 120px; top: 200px; right: 20%; }
  div.circle.fourth div.circle-small { width: 100px; height: 100px; }
  div.circle.fifth { width: 50px; height: 50px; top: 25px; right: 25px; }
  div.circle.fifth  div.circle-small { width: 40px; height: 40px; }

  img.svg { position: absolute; top: 0; left: 0; width: 100vw; height: 75vh; z-index: 1; }
</style>
