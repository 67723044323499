<template>
  <footer>
    <h6>AFK Design & Web 2021 &copy; Wszelkie prawa zastrzeżone.</h6>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data(){
    return{
    }
  },
}
</script>
<style scoped lang="scss">
  footer { height: 40px; border-top: 1px solid rgba(255, 255, 255, 0.2); overflow: hidden;
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%);
    background: rgba(0,54,112,1); }
  footer h6 { margin: 0; padding: 0; text-align: center; line-height: 40px; color: rgba(255, 255, 255, 0.3); font-weight: 400; font-size: 14px; }

  @media (max-width: 500px) {
    footer h6 { font-size: 13px; }
  }
</style>
