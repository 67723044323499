<template>
  <nav v-if="navMobile">
    <ul>
      <li><router-link to="/" :class="{ active: activeRoute == 'Home' }" @click.native="close()">Strona główna</router-link></li>
      <li><router-link to="/oferta" :class="{ active: activeRoute == 'Offer' }" @click.native="close()">Oferta</router-link></li>
      <li><router-link to="/portfolio" :class="{ active: activeRoute == 'Portfolio' }" @click.native="close()">Portfolio</router-link></li>
      <li><router-link to="/kontakt" :class="{ active: activeRoute == 'Contact' }" @click.native="close()">Kontakt</router-link></li>
      <li style="display: none"><router-link to="/" @click.native="close()">Zacznij</router-link></li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TheNavMobile',
  data(){
    return{
      activeRoute: this.$route.name,
    }
  },
  computed:{
    navMobile(){
      return this.$store.state.navMobile;
    },
  },
  watch:{
    $route(to, from){
      this.activeRoute = this.$route.name;
      this.close();
    }
  },
  methods:{
    close(){
      this.$store.commit('toggleNavMobile', 0);
      document.body.style = "overflow-y: overlay";
    },
  },
}
</script>
<style scoped lang="scss">
  nav { display: block; width: 100vw; height: 100vh; position: fixed; top: 0; z-index: 4; border-top: 1px solid #0D47A1; overflow-y: auto; background: rgb(0,6,35); 
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  nav ul { margin: 70px 0 0 0; padding: 0; list-style: none; }
  nav ul li a { display: block; height: 60px; line-height: 60px; text-align: center; color: #fff; text-decoration: none; text-transform: uppercase; font-size: 13px; letter-spacing: 1px; font-weight: 500; }
  nav ul li a:hover { background: rgba(255, 255, 255, 0.1); transition: 0.5s; }
  nav ul li a.active { background: rgba(255, 255, 255, 0.1); }

  @media (min-width: 1000px) {
    nav { display: none; }
  }
</style>
