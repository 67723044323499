<template>
    <div class="gallery-wrapper">
        <div class="box" v-for="(element, index) in gallery" :key="index + element">
            <div class="image" :style="{ 'background-image': `url( ${ element.img } )`, height: photoHeight + 'px' }"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Gallery2',
    data() {
        return {
            gallery: [{
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },],
            windowWidth: 0,
            photoHeight: null,
        }
    },
    watch: {
        windowWidth() {
            this.setPhotoHeight();
        },
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        setPhotoHeight() {
            if(this.windowWidth <= 650) {
                this.photoHeight = this.windowWidth * 0.9 * 0.5;
            } else {
                this.photoHeight = 300;
            };
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    .gallery-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
        // background: linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
    }
    div.box {
        width: 600px;
        margin: 15px;
        background-color: #fff;
    }
    div.box div.image {
        background-color: teal;
        background-size: cover;
        box-shadow: 0 0 7px gray;
        border-radius: 5px;
    }
    @media (max-width: 650px) {
        div.box {
            width: 90vw;
        }
    }
</style>
