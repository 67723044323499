<template>
  <section class="theme2-section1">
    <header>
      <h3>Lorem ipsum</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id sollicitudin lectus. Proin rhoncus dui ut turpis imperdiet, in dignissim sem finibus. Aenean auctor nulla sit amet erat dictum.</p>
    </header>
    <div class="flex">
      <article>
        <div class="circle">
          <i class="material-icons">home</i>
        </div>
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis tristique felis vitae congue. Suspendisse lobortis nisi at euismod pellentesque.</p>
      </article>
      <article>
        <div class="circle">
          <i class="material-icons">home</i>
        </div>
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis tristique felis vitae congue. Suspendisse lobortis nisi at euismod pellentesque.</p>
      </article>
      <article>
        <div class="circle">
          <i class="material-icons">home</i>
        </div>
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis tristique felis vitae congue. Suspendisse lobortis nisi at euismod pellentesque.</p>
      </article>
      <article>
        <div class="circle">
          <i class="material-icons">home</i>
        </div>
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis tristique felis vitae congue. Suspendisse lobortis nisi at euismod pellentesque.</p>
      </article>
      <article>
        <div class="circle">
          <i class="material-icons">home</i>
        </div>
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis tristique felis vitae congue. Suspendisse lobortis nisi at euismod pellentesque.</p>
      </article>
      <article>
        <div class="circle">
          <i class="material-icons">home</i>
        </div>
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis tristique felis vitae congue. Suspendisse lobortis nisi at euismod pellentesque.</p>
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Theme2Section1',
 
}
</script>

<style scoped lang="scss">
  section.theme2-section1 { display: flex; flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap; padding: 100px 0; }

  header { width: 600px; }
  header h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 500; color: #5041a0; text-align: center; }
  header p { margin: 0; padding: 0; font-size: 16px; color: rgba(0, 0, 0, 0.8); line-height: 25px; text-align: center; }

  div.flex { display: flex; flex-wrap: wrap; width: 1140px; margin-top: 50px; }

  article { display: flex; flex-direction: column; align-items: center;  width: 300px; margin: 40px; }
  article div.circle { display: flex; justify-content: center; align-items: center; width: 60px; height: 60px; border-radius: 50%; background: rgba(0,237,147,0.05); border: 1px solid rgba(0,237,147,0.3); }
  article i.material-icons { color: #00ed93; font-size: 30px; }
  article h4 { margin: 20px 0; padding: 0; color: #5041a0; font-weight: 600; font-size: 22px; }
  article p { margin: 0; padding: 0; text-align: center; font-size: 13px; line-height: 20px; }
</style>
