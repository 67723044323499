<template>
  <section id="theme1-section3">
    <article>
      <img src="../../../assets/themes/theme1/img1.png">
      <div class="container">
        <h4>Lorem ipsum sit dorlor</h4>
        <p>Aenean auctor nulla sit amet erat dictum, at interdum nibh vehicula. Nunc non ullamcorper augue, ut interdum nunc. Nulla non sagittis turpis.</p>
      </div>
    </article>
    <article>
      <img src="../../../assets/themes/theme1/img1.png">
      <div class="container">
        <h4>Maecenas dictum mollis vulputate</h4>
        <p>Vestibulum lacus urna, venenatis a velit at, mollis iaculis nisl. Vivamus vel est diam. Suspendisse diam dolor, aliquet quis finibus eu, ultrices eu augue.</p>
      </div>
    </article>
    <article>
      <img src="../../../assets/themes/theme1/img1.png">
      <div class="container">
        <h4>Vivamus maximus semper enim</h4>
        <p>Donec a placerat erat, vitae tristique erat. Sed malesuada eleifend dapibus. Integer arcu lorem, feugiat a sem at, feugiat pulvinar diam. Vivamus maximus semper enim.</p>
      </div>
    </article>
    <article>
      <img src="../../../assets/themes/theme1/img1.png">
      <div class="container">
        <h4>Aliquam erat volutpat</h4>
        <p>Donec risus tortor, viverra quis mollis a, porttitor vel augue. Quisque imperdiet, neque eu eleifend feugiat, magna urna tempus justo, vitae venenatis nisl lectus sed odio.</p>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'Theme1Section3',
 
}
</script>

<style scoped lang="scss">
  section#theme1-section3 { display: flex; flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap; padding: 100px 0; }

  article { display: flex; flex-wrap: wrap; align-items: center; margin: 30px 0; }
  article img { display: block; width: 300px; height: 298px; margin-right: 50px; }
  article div.container { width: 400px; margin-left: 50px;  }
  article div.container h4 { margin: 0 0 10px 0; padding: 0; color: #0182e2; font-size: 22px; font-weight: 400; }
  article div.container p { margin: 0; padding: 0; font-size: 16px; line-height: 25px; color: rgba(0, 0, 0, 0.5); }
  article:nth-child(even) img { order: 2; margin-right: 0px;  margin-left: 50px; }
  article:nth-child(even) div.container { margin-left: 0px; margin-right: 50px; }

  @media (max-width: 800px) {
    article img, article:nth-child(even) img { order: 1; margin: 50px auto; }
    article div.container, article:nth-child(even) div.container { width: calc(100vw - 40px); margin: 0 auto; }
  }
  @media (max-width: 700px) {
    section#theme1-section3 { padding: 50px 0; }
  }
</style>
