<template>
    <header :class="{ fixed: headerFixed, 'mobile-fixed': navMenuOpened }">
        <h1>Company Name</h1>
        <ul>
            <li :class="{ active: activeSection == 1 }" @click="activeSection = 1"><router-link :to="{ name: 'Theme4Home' }">Strona główna</router-link></li>
            <li :class="{ active: activeSection == 2 }" @click="activeSection = 2"><router-link :to="{ name: 'Theme4Sections' }">Sekcje</router-link></li>
            <li :class="{ active: activeSection == 3 }" @click="activeSection = 3"><router-link :to="{ name: 'Theme4Gallery' }">Galeria</router-link></li>
            <li :class="{ active: activeSection == 4 }" @click="activeSection = 4"><router-link :to="{ name: 'Theme4Contact' }">Kontakt</router-link></li>
        </ul>
        <div class="menu" @click="navMenuOpened = true">
            <i class="material-icons">{{ menuIcon }}</i>
        </div>
        <transition
          @before-enter="beforeTransition"
          @enter="enter"
          @after-enter="afterTransition"
          
          @before-leave="beforeTransition"
          @leave="leave"
          @after-leave="afterTransition" 
        >
          <div class="opened-menu" v-if="navMenuOpened">
            <ul>
              <router-link :to="{ name: 'Theme4Home' }"><li :class="{ active: activeSection == 1 }" @click="activeSection = 1, navMenuOpened = false">Strona główna</li></router-link>
              <router-link :to="{ name: 'Theme4Sections' }"><li :class="{ active: activeSection == 2 }" @click="activeSection = 2, navMenuOpened = false">Sekcje</li></router-link>
              <router-link :to="{ name: 'Theme4Gallery' }"><li :class="{ active: activeSection == 3 }" @click="activeSection = 3, navMenuOpened = false">Galeria</li></router-link>
              <router-link :to="{ name: 'Theme4Contact' }"><li :class="{ active: activeSection == 4 }" @click="activeSection = 4, navMenuOpened = false">Kontakt</li></router-link>
            </ul>
          </div>
        </transition>
        <div class="opened-menu-mask" v-if="navMenuOpened" @click="navMenuOpened = false"></div>
    </header>
</template>

<script>
const raf = window.requestAnimationFrame;

export default {
  name: "Theme4TheHeader",
  data() {
    return {
      headerFixed: 0,
      scrollListener: 0,
      activeRoute: this.$route.name,
      menuIcon: "menu",
      activeSection: 1,
      navMenuOpened: false,
    };
  },
  created(){
    let self = this;
    this.scrollListener = window.addEventListener("scroll", function(){
      if(document.documentElement.scrollTop > 0){
        self.headerFixed = 1;
      }else{
        self.headerFixed = 0;
      }
    });
    if(document.documentElement.scrollTop > 0){
      self.headerFixed = 1;
    }else{
      self.headerFixed = 0;
    }
  },
  watch: {
    $route(to, from){
      this.activeRoute = this.$route.name;
    },
  },
  methods: {
    toggleNavMobile(){
      if(!this.navMobile){
        this.$store.commit('toggleNavMobile', 1);
        document.body.style = "overflow-y: hidden";
      }else{
        this.$store.commit('toggleNavMobile', 0);
        document.body.style = "overflow-y: overlay";
      }
    },
    nextFrame (fn) {
      raf(() => {
        raf(fn);
      });
    },
    beforeTransition (el) {
      el.classList.add('width-transition');
    },
    enter (el, done) {
      const height = el.clientHeight;

      el.style['max-width'] = `0px`;

      this.nextFrame(() => {
        el.style['max-width'] = `${height + 5}px`;
      });
    },
    leave (el) {
      el.style['max-width'] = `${el.clientHeight}px`;

      this.nextFrame(() => {
        el.style['max-width'] = `0px`;
      });
    },
    afterTransition (el) {
      el.classList.remove('width-transition');
      el.style['max-width'] = '';
    },
  },
  destroyed(){
    window.removeEventListener('scroll');
  },
};
</script>
<style scoped lang="scss">
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        height: 100px;
        padding: 0 100px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        transition: all .2s ease-in-out;
    }
    header.fixed,
    header.mobile-fixed {
        background: $Theme4Secondary;
        height: 70px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    h1 {
        margin: 0;
        padding: 0;
    }
    h1 {
        display: block;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        font-size: 24px;
    }
    h1:hover {
        color: $Theme4Primary;
        cursor: pointer;
    }
    ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    ul li a{
        display: inline-block;
        padding: 7px 5px;
        margin: 0 50px 0 0;
        color: rgba(#fff, .9);
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }
    ul li:last-child a {
        margin-right: 0;
    }
    ul li:hover a {
        color: $Theme4Primary;
        cursor: pointer;
    }
    ul li.active a {
        color: $Theme4Primary;
        border-bottom: 2px solid $Theme4Primary;
    }
    div.menu {
        display: none;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
    }
    div.menu:hover {
        transition: 0.5s;
        cursor: pointer;
    }
    div.menu i.material-icons {
        color: #fff;
        font-size: 32px;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }
    div.opened-menu {
      // display: none;
      position: fixed;
      top: 70px;
      left: 0;
      width: 80vw;
      height: 100vh;
      background: #000;
      color: rgba(#fff, .9);
      z-index: 7;
      overflow: hidden;
    }
    div.opened-menu ul {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        margin-top: 15px;
        margin-left: 15px;
        padding: 0;
        list-style: none;
        white-space: nowrap;
    }
    div.opened-menu ul li {
      padding: 15px 0;
    }
    div.opened-menu ul a {
        width: 100%;
        padding: 0;
        margin: 0;
        color: rgba(#fff, .9);
        text-decoration: none;
        font-size: 20px;
        font-weight: 700;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }
    div.opened-menu ul a li.active {
        color: $Theme4Primary;
        border-bottom: none;
    }
    div.opened-menu-mask {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 6;
    }
    .width-transition {
        transition: all .4s ease;
    }
    @media (max-width: 1000px) {
      ul {
        display: none;
      }
      header {
        height: 70px;
        padding: 0 30px;
        padding-right: 0;
        transition: all .4s ease;
      }
      div.menu {
        display: flex;
      }
    }
</style>
