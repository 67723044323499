<template>
    <div class="theme2-home">
        <theme3-the-header></theme3-the-header>
        <theme3-mobile-nav></theme3-mobile-nav>
        <carousel-4 id="carousel-4"></carousel-4>
        <section-6 id="section-6"></section-6>
        <section-7 id="section-7"></section-7>
        <gallery-1 id="gallery-1"></gallery-1>
        <form-2 id="form-2"></form-2>
        <theme3-contact id="theme3-contact"></theme3-contact>
        <theme3-the-footer></theme3-the-footer>
        <theme-changer></theme-changer>
    </div>
</template>

<script>
import Carousel4 from '../../../components/themes/carousels/carousel4/Carousel4';
import Form2 from '../../../components/themes/forms/form2';
import Gallery1 from '../../../components/themes/galleries/Gallery1';
import Section6 from '../../../components/themes/sections/section6';
import Section7 from '../../../components/themes/sections/section7';
import Theme3TheHeader from '../../../components/themes/theme3/Theme3TheHeader';
import Theme3MobileNav from '../../../components/themes/theme3/Theme3MobileNav';
import Theme3TheFooter from '../../../components/themes/theme3/Theme3TheFooter';
import Theme3Contact from '../../../components/themes/theme3/Theme3Contact';

import ThemeChanger from '@/components/ThemeChanger.vue'

export default {
    name: 'Theme3Home',
    components: {
        Carousel4,
        Form2,
        Gallery1,
        Section6,
        Section7,
        Theme3TheHeader,
        Theme3MobileNav,
        Theme3TheFooter,
        Theme3Contact,
        ThemeChanger,
    },
    data(){
    return {
      observersOptions: {
        root: null,
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
      observers: [],
      sections: ['carousel-4', 'section-6', 'section-7', 'gallery-1', 'form-2', 'theme3-contact'],
      sectionsIntersections: [],
      sectionVisible: 0,
    }
  },
  mounted(){
    this.$store.commit('changeOnePage2ActiveSection', this.sections[0])
    let target;
    let self = this;
    for(let i = 0; i < this.sections.length; i++){
      this.sectionsIntersections.push(0);
      this.observers.push(new IntersectionObserver( entries => {
        this.callback(i, self.sections[i], entries[0]);
      }, this.observersOptions));
      target = document.getElementById(this.sections[i]);
      this.observers[i].observe(target)
    }
  },
  methods: {
    callback(index, section, entry){
      this.sectionsIntersections[index] = entry.intersectionRatio;
      let max = 0;
      let maxIndex = -1;
      for(let i = 0; i < this.sectionsIntersections.length; i++){
        if(this.sectionsIntersections[i] > max){
          max = this.sectionsIntersections[i];
          maxIndex = i;
        }
      }
      if(maxIndex == index && entry.isIntersecting){
        this.sectionVisible = section;
          this.$store.commit('changeOnePage2ActiveSection', this.sectionVisible)
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
