<template>
  <div class="home-internet">
    <article>
      <h4>Reklama Internetowa</h4>
      <p>Zaprojektujemy każdy rodzaj reklamy - banery www,<br> posty social media, newslettery, Google Ads itd.<br> Sprawimy, że Twoja Firma będzie widoczna!</p>
    </article>
    <transition name="fade">
      <img src="../../assets/internet.webp" alt="Identyfikacja wizualna" v-show="staticImg" :load="staticImg = 1">
    </transition>
  </div>
</template>

<script>
export default {
  name: 'HomeAd',
  data(){
    return{
      staticImg: 0,
    }
  }
}
</script>

<style scoped lang="scss">
  div.home-internet { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; margin: 100px 0; background: #fff; }

  img { display: block; width: 1100px; height: 645px; margin: 0 50px; }

  article { width: 600px; margin: 0 50px; }
  article h4 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 300; color: $black; }
  article p { margin: 0; padding: 0; font-size: 18px; color: $gray; line-height: 30px; text-align: justify; }

  @media (max-width: 1900px) {
    article { margin: 0 25px; }
    img { width: 800px; height: 469px; margin: 0 25px; }
  }
  @media (max-width: 1300px) {
    img { width: 500px; height: 293px; }
  }
  @media (max-width: 1140px) {
    img { margin: 25px 0 0 0; }
    article { margin: 0 0 25px 0; }
    article h4 { text-align: center; }
    article p { text-align: center; }
  }
  @media (max-width: 640px) {
    article { width: calc(100vw - 40px); margin: 0 auto; }
    img { width: 300px; height: 176px; }
  }
  @media (max-width: 500px) {
    div.home-internet { margin: 50px 0; }
    article h4  { font-size: 40px; }
  }
</style>
