<template>
    <transition :name="direction">
        <div v-show="visibleSlide === index">
            <slot></slot>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Carousel1Slide',
    props: [
        'visibleSlide',
        'index',
        'direction',
    ],
}
</script>

<style lang="scss" scoped>
    .left-enter-active {
        animation: leftInAnimation .4s ease-in;
    }
    .left-leave-active {
        animation: leftOutAnimation .4s ease-in;
    }
    @keyframes leftInAnimation {
        from { transform: translateX(100%); }
        to { transform: translateX(0); }
    }
    @keyframes leftOutAnimation {
        from { transform: translateX(0); }
        to { transform: translateX(-100%); }
    }
    .right-enter-active {
        animation: rightInAnimation .4s ease-in;
    }
    .right-leave-active {
        animation: rightOutAnimation .4s ease-in;
    }
    @keyframes rightInAnimation {
        from { transform: translateX(-100%); }
        to { transform: translateX(0); }
    }
    @keyframes rightOutAnimation {
        from { transform: translateX(0); }
        to { transform: translateX(100%); }
    }
</style>
