<template>
  <header :class="{ bg: headerBackground, 'no-bg': navMobile}">
    <h1><router-link to="/"><span class="afk">AFK</span> <span class="logo">Design & Web</span></router-link></h1>
    <ul>
      <li :class="{ active: activeRoute == 'Home' }"><router-link to="/">Strona główna</router-link></li>
      <li :class="{ active: activeRoute == 'Offer' }"><router-link to="/oferta">Oferta</router-link></li>
      <li :class="{ active: activeRoute == 'Portfolio' }"><router-link to="/portfolio">Portfolio</router-link></li>
      <li :class="{ active: activeRoute == 'Contact' }"><router-link to="/kontakt">Kontakt</router-link></li>
      <li style="display: none"><a href="#">Zacznij</a></li>
    </ul>
    <div class="menu" @click="toggleNavMobile()">
      <i class="material-icons">{{ menuIcon }}</i>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HomeCard',
  data(){
    return{
      headerBackground: 0,
      scrollListener: 0,
      activeRoute: this.$route.name,
      menuIcon: 'menu',
    }
  },
  computed:{
    navMobile(){
      return this.$store.state.navMobile;
    },
  },
  created(){
    let self = this;
    this.scrollListener = window.addEventListener("scroll", function(){
      if(document.documentElement.scrollTop > 0){
        self.headerBackground = 1;
      }else{
        self.headerBackground = 0;
      }
    });
    if(document.documentElement.scrollTop > 0){
      self.headerBackground = 1;
    }else{
      self.headerBackground = 0;
    }
  },
  watch: {
    $route(to, from){
      this.activeRoute = this.$route.name;
    },
    navMobile(){
      if(this.navMobile){
        this.menuIcon = 'close';
      }else{
        this.menuIcon = 'menu';
      }
    }
  },
  methods: {
    toggleNavMobile(){
      if(!this.navMobile){
        this.$store.commit('toggleNavMobile', 1);
        document.body.style = "overflow-y: hidden";
      }else{
        this.$store.commit('toggleNavMobile', 0);
        document.body.style = "overflow-y: overlay";
      }
    }
  },
  destroyed(){
    window.removeEventListener('scroll');
  }
}
</script>
<style scoped lang="scss">
  header { display: flex; align-items: center; justify-content: space-between; width: 100vw; height: 70px; padding: 0 100px; position: fixed; top: 0; left: 0; z-index: 5; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
  header.bg { background: rgb(0,6,35); background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  header.no-bg { background: none; }
  h1 { margin: 0; padding: 0; background: url('../assets/logo_27_white.png') no-repeat 0 50%; }
  h1 span.afk { display: none; }
  h1 a { display: inline-block; padding: 0 0 0 67px; line-height: 50px; color: #fff; text-decoration: none; font-weight: 500; font-size: 22px; }
  ul { display: flex; margin: 0; padding: 0; list-style: none; }
  ul li a { display: inline-block; margin: 0 0 0 10px; padding: 12px 27px; color: #fff; text-decoration: none; text-transform: uppercase; letter-spacing: 1px; font-size: 12px; font-weight: 500; border-radius: 3px; transition: .5s; }
  ul li a:hover { background: rgba(255, 255, 255, 0.1); transition: .5s;}
  ul li.active a { background: rgba(255, 255, 255, 0.1); } 
  ul li:last-child a { padding: 11px 26px; background: rgba(255, 255, 255, 0.1); border: 1px solid rgba(255, 255, 255, 0.5); }
  ul li:last-child a:hover { background: #fff; color: $blue; font-weight: 600; border: 1px solid $blue; }
  div.menu { display: none; justify-content: center; align-items: center; width: 70px; height: 70px; }
  div.menu:hover { background: rgba(0,129,168,0.1); transition: .5s; cursor: pointer; }
  div.menu i.material-icons { color: #fff; font-size: 32px; }

  @media (max-width: 1250px) {
    header { padding: 0 20px; }
  }

  @media (max-width: 1000px) {
    header { padding: 0 0 0 20px; }
    ul { display: none; }
    div.menu { display: flex; }
  }

  @media (max-width: 300px) {
    h1 span.logo { display: none; }
  }
</style>
