<template>
    <section class="theme4-section4">
        <div class="section-wrapper">
            <div class="row">
                <div class="text">
                    <h4>Lorem Ipsum</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend. </p>
                </div>
                <div class="image"></div>
            </div>
            <div class="row">
                <div class="image"></div>
                <div class="text">
                    <h4>Lorem Ipsum</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Theme4Section4',

}
</script>

<style lang="scss" scoped>
    h4 {
        color: #fff;
        width: 80%;
    }
    p {
        color: #fff;
        width: 80%;
    }
    div.section-wrapper {
        width: 100vw;
        height: 900px;
        display: flex;
        flex-direction: column;
    }
    div.row {
        display: flex;
        height: 100%;
    }
    div.section-wrapper div.image {
        width: calc(100% / 2);
        height: 100%;
        background: $Theme4Secondary;
        background-size: cover;
    }
    div.section-wrapper div:nth-of-type(2) {
        background-image: url('https://images.unsplash.com/photo-1604014238170-4def1e4e6fcf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
    }
    div.section-wrapper div:nth-of-type(1) {
        background-image: url('https://images.unsplash.com/photo-1491489226161-1d38cb39ec64?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
    }
    div.section-wrapper div.text {
        width: calc(100% / 2);
        height: 100%;
        background: $Theme4Secondary;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 1000px) {
        div.section-wrapper {
            height: 1200px;
        }
        div.row {
            flex-direction: column;
        }
        div.row:nth-child(2) {
            flex-direction: column-reverse;
        }
        div.section-wrapper div.text {
            width: 100%;
            height: calc(100% / 2);
        }
        div.section-wrapper div.image {
            width: 100%;
            height: calc(100% / 2);
        }
    }
</style>
