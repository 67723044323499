<template>
    <section class="theme4-section4">
        <div class="section-wrapper" :style="{ height: boxHeight + 'px' }">
            <div class="image"></div>
            <div class="text">
                <h3>Lorem Ipsum</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend. </p>
            </div>
            <div class="image"></div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Theme4Section4',
    data() {
        return {
            windowWidth: 0,
            boxHeight: null,
        }
    },
    watch: {
        windowWidth() {
            this.setboxHeight();
        },
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        setboxHeight() {
            this.boxHeight = this.windowWidth / 3;
            if(this.windowWidth <= 1000) {
                this.boxHeight = this.windowWidth * 3;
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    h3 {
        color: #fff;
        width: 80%;
    }
    p {
        color: #fff;
        width: 80%;
    }
    div.section-wrapper {
        width: 100vw;
        display: flex;
    }
    div.section-wrapper div.image {
        width: calc(100% / 3);
        height: 100%;
        background: $Theme4Secondary;
        background-size: cover;
    }
    div.section-wrapper div:nth-of-type(1) {
        background-image: url('https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80');
    }
    div.section-wrapper div:nth-of-type(3) {
        background-image: url('https://images.unsplash.com/photo-1522050212171-61b01dd24579?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80');
    }
    div.section-wrapper div.text {
        width: calc(100% / 3);
        height: 100%;
        background: $Theme4Primary;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 1000px) {
        div.section-wrapper {
            flex-direction: column;
        }
        div.section-wrapper div.image {
            width: 100%;
            height: calc(100% / 3);
        }
        div.section-wrapper div.text {
            width: 100%;
            height: calc(100% / 3);
        }
    }
</style>
