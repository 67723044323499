<template>
    <div class="section-wrapper">
        <div class="head">
            <h4>Lorem ipsum</h4>
            <p>Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
        </div>
        <div class="grid-box">
            <div class="row">
                <div class="text">
                    <div class="text-container">
                        <h5>Lorem ipsum</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                    </div>
                </div>
                <div class="image-container">
                    <div class="image"><div class="img"></div></div>
                </div>
            </div>
            <div class="row">
                <div class="text">
                    <div class="text-container">
                        <h5>Lorem ipsum</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                    </div>
                </div>
                <div class="image-container">
                    <div class="image"><div class="img"></div></div>
                </div>
                <div class="text">
                    <div class="text-container">
                        <h5>Lorem ipsum</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                    </div>
                </div>
                <div class="image-container">
                    <div class="image"><div class="img"></div></div>
                </div>
            </div>
            <div class="row">
                <div class="text">
                    <div class="text-container">
                        <h5>Lorem ipsum</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                    </div>
                </div>
                <div class="image-container">
                    <div class="image"><div class="img"></div></div>
                </div>
            </div>
        </div>
        <div class="diamond first"></div>
        <div class="diamond second"></div>
        <div class="diamond third"></div>
        <div class="diamond fourth"></div>
    </div>
</template>

<script>
export default {
    name: 'Section1',
    data() {
        return {
            asd: []
        }
    },
}
</script>

<style lang="scss" scoped>
    h4 {
        font-size: 50px;
        font-weight: 400;
        margin: 15px;
        color: rgba(1,130,226, 1);
    }
    p {
        font-size: 13px;
        font-weight: 300;
    }
    .section-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 100px 0;
        position: relative;
    }
    div.head {
        width: 95%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
    }
    div.grid-box {
        display: flex;
        flex-direction: column;
        transform: rotate(45deg);
        margin: 100px 0;
    }
    div.row {
        display: flex;
        justify-content: center;
    }
    div.text {
        width: 250px;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        // box-shadow: 0 0 7px rgba(0,237,147, 1);
        background: linear-gradient(90deg, rgba(1,130,226,1) 0%, rgba(0,237,147,1) 100%);
        // color:  rgba(1,130,226,1)
        margin: 5px;
    }
    div.text div.text-container {
        transform: rotate(-45deg);
        text-align: center;
    }
    div.text p {
        font-weight: 500;
        color: #fff;
    }
    div.text h5 {
        font-size: 30px;
        font-weight: 500;
        margin: 0;
        margin-bottom: 30px;
        color: #fff;
    }
    div.image-container {
        background-color: #fff;
    }
    div.image {
        width: 250px;
        height: 250px;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: linear-gradient(270deg, rgba(1,130,226,1) 0%, rgba(0,237,147,1) 100%);
        background: rgba(1,130,226,.15);
        // background-color: rgba(1,130,226,1);
        margin: 5px;
    }
    div.image div.img {
        width: 80%;
        height: 80%;
        background-image: url('../../../assets/themes/theme1/img1.png');
        background-size: cover;
        transform: rotate(-45deg);
    }
    .diamond {
        width: 200px;
        height: 200px;
        background: rgba(#0182e2, .15);
        transform: rotate(45deg);
        z-index: -1;
        position: absolute;
    }
    .diamond.first {
        left: -100px;
        top: 20px;
    }
    .diamond.second {
        right: -100px;
        bottom: 20px;
    }
    .diamond.third {
        width: 150px;
        height: 150px;
        bottom: 0;
        left: 10px;
    }
    .diamond.fourth {
        width: 150px;
        height: 150px;
        top: 60px;
        right: 10px;
    }
    @media (max-width: 1000px) {
        div.grid-box {
            display: flex;
            flex-direction: column;
            transform: rotate(0deg);
            margin: 100px 0;
        }
        div.row {
            flex-wrap: wrap;
            width: 520px;
        }
        div.text div.text-container {
            transform: rotate(0deg);
        }
        div.image div.img {
            transform: rotate(0deg);
        }
    }
    @media (max-width: 520px) {
        div.row {
            width: 100%;
        }
        div.image {
            margin-bottom: 20px;
        }
        .diamond.second {
            display: none;
        }
        .diamond.third {
            display: none;
        }
    }
</style>
