<template>
  <div class="theme2-home">
    <theme2-home-card></theme2-home-card>
    <theme2-section1></theme2-section1>
    <theme2-section3></theme2-section3>
    <theme2-section4></theme2-section4>
    <theme2-section2></theme2-section2>
    <theme2-faq></theme2-faq>
    <theme-changer></theme-changer>
  </div>
</template>

<script>
import Theme2HomeCard from '@/components/themes/theme2/Theme2HomeCard.vue'
import Theme2Section1 from '@/components/themes/theme2/Theme2Section1.vue'
import Theme2Section2 from '@/components/themes/theme2/Theme2Section2.vue'
import Theme2Section3 from '@/components/themes/theme2/Theme2Section3.vue'
import Theme2Section4 from '@/components/themes/theme2/Theme2Section4.vue'
import Theme2Faq from '@/components/themes/theme2/Theme2Faq.vue'

import ThemeChanger from '@/components/ThemeChanger.vue'

export default {
  name: 'Theme2Home',
  components: {
    Theme2HomeCard,
    Theme2Section1,
    Theme2Section2,
    Theme2Section3,
    Theme2Section4,
    Theme2Faq, 
    ThemeChanger
  }
}
</script>

<style scoped lang="scss">
  div.theme2-home { margin: 0; }
</style>
