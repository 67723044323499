<template>
    <div class="section-wrapper">
        <div class="section-content">
            <div class="left">
                <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam hendrerit, mauris ac euismod dignissim, nunc enim commodo magna, eget tempus turpis metus eu enim.</p>
            </div>
            <div class="right">
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">memory</i>
                    </div>
                    <h5>Lorem Ipsum</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">memory</i>
                    </div>
                    <h5>Lorem Ipsum</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">memory</i>
                    </div>
                    <h5>Lorem Ipsum</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">memory</i>
                    </div>
                    <h5>Lorem Ipsum</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">memory</i>
                    </div>
                    <h5>Lorem Ipsum</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">memory</i>
                    </div>
                    <h5>Lorem Ipsum</h5>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
            </div>
        </div>
        <div class="diamond first"></div>
        <div class="diamond second"></div>
        <div class="diamond third"></div>
        <div class="diamond fourth"></div>
        <div class="diamond fifth"></div>
    </div>
</template>

<script>
export default {
    name: 'Section2',
}
</script>

<style lang="scss" scoped>
    p {
        font-weight: 300;
        font-size: 13px;
        text-align: justify;
    }
    h4 {
        font-weight: 600;
        font-size: 35px;
        margin: 0;
        margin-bottom: 50px;
    }
    h5 {
        font-weight: 600;
        font-size: 17px;
        margin: 0;
        margin-bottom: 10px;
        color: #ee0979;
    }
    .section-wrapper {
        padding: 100px 0;
        display: flex;
        justify-content: center;
        position: relative;
        overflow: hidden;
    }
    .section-content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
    .left {
        width: 30%;
        margin-right: 50px;
        background: rgba(#fff, 1);
        box-shadow: 0 0 10px #e0e0e0;
        padding: 10px;
    }
    .right {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
    }
    .right .box {
        width: 230px;
        height: 300px;
        padding: 20px;
        margin: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 10px #e0e0e0;
        background-color: #fff;
    }
    .right .box .icon {
        background: linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
        border-radius: 50%;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }
    .right .box .icon i {
        color: #fff;
        font-size: 40px;
    }
    .right .box p {
        text-align: center;
    }
    .diamond {
        background: rgba(#ff6a00, .7);
        border-radius: 50%;
        z-index: -1;
        position: absolute;
    }
    .diamond.first {
        width: 600px;
        height: 600px;
        left: -300px;
        top: -300px;
    }
    .diamond.second {
        width: 1200px;
        height: 1200px;
        left: 800px;
        top: 300px;
    }
    .diamond.third {
        width: 100px;
        height: 100px;
        left: 100px;
        top: 650px;
    }
    .diamond.fourth {
        width: 200px;
        height: 200px;
        left: 400px;
        top: 80px;
    }
    .diamond.fifth {
        width: 150px;
        height: 150px;
        left: 500px;
        top: 350px;
    }
    @media (max-width: 1050px) {
        .section-content {
            flex-direction: column;
        }
        .left {
            width: 500px;
            max-width: 100%;
            margin-right: 0;
        }
        .right {
            width: 100%;
            justify-content: center;
        }   
    }
</style>
