import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: process.env.VUE_APP_API,
    navMobile: 0,
    alert:{
      visibility: 0,
      type: 0,
      msg: '',
      site: ''
    },
    particles: {
      fpsLimit: 60,
      particles: {
          color: {
              value: '#666666'
          },
          links: {
              color: '#666666',
              distance: 150,
              enable: true,
              opacity: 0.4,
              width: 1
          },
          move: {
              enable: true,
              outMode: 'bounce',
              speed: 0.5,
          },
          opacity: {
              value: 0.5
          },
          shape: {
              type: 'circle'
          },
          size: {
              random: true,
              value: 4
          }
      },
    },
    onePageActiveSection: 0,
    onePage2ActiveSection: 0,
  },
  mutations: {
    toggleNavMobile(state, payload){
        this.state.navMobile = payload;
    },
    toggleAlert(state, payload){
      state.alert.visibility = payload.visibility;
      state.alert.type = payload.type;
      state.alert.msg = payload.msg;
      state.alert.site = payload.site;
    },
    changeOnePageActiveSection(state, payload){
      state.onePageActiveSection = payload;
    },
    changeOnePage2ActiveSection(state, payload){
      state.onePage2ActiveSection = payload;
    }
  },
  actions: {
  },
  modules: {
  }
})
