<template>
  <div class="home-identification">
    <div class="particles">
      <Particles id="particles6" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
    <div class="container">
      <transition name="fade">
        <img src="../../assets/identification.webp" alt="Identyfikacja wizualna" v-show="staticImg" :load="staticImg = 1">
      </transition>
      <article>
        <h4>Identyfikacja wizualna</h4>
        <p>Indywidualny projekt Logo + nieograniczona ilość materiałów, na których można je zaprezentować. Zawsze zgodnie z wizją Klienta i aktualnymi trendami. Wykreujemy każdą markę!</p>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeIdentification',
  data(){
    return{
      particlesInit: 0,
      particlesLoaded: 0,
      staticImg: 0,
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    }
  },
}
</script>
<style scoped lang="scss">
  div.home-identification { padding: 100px 0; position: relative; box-shadow: 0 0 5px #000; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  div.container { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; position: relative; z-index: 2;}

  img { display: block; width: 850px; height: 686px; margin: 0 50px; }

  article { width: 600px; margin: 0 50px; }
  article h4 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 300; color: #fff }
  article p { margin: 0; padding: 0; font-size: 18px; color: #fff; line-height: 30px; text-align: justify; }

  @media (max-width: 1650px) {
    img { width: 600px; height: 484px; margin: 0 25px; }
    article { margin: 0 25px;}
  }
  @media (max-width: 1300px) {
    img { width: 500px; height: 404px;}
  }
  @media (max-width: 1100px) {
    img { margin: 0 0 25px 0; }
    article { margin: 25px 0 0 0; }
    article h4 { text-align: center; }
    article p { text-align: center; }
  }
  @media (max-width: 640px) {
    article { width: calc(100vw - 40px); margin: 0 auto; }
    img { width: 300px; height: 242px; }
  }
  @media (max-width: 500px) {
    div.home-identification { padding: 50px 0; }
    article h4  { font-size: 40px; }
  }
</style>
