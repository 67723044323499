<template>
    <section class="contact">
        <div class="content">
            <div class="left">
                <h3>Lorem Ipsum</h3>
                <ul>
                    <li>
                        <i class="material-icons">phone</i>
                        <p>+48 71 336 95 21</p>
                    </li>
                    <li>
                        <i class="material-icons">email</i>
                        <p>bok@afkcob.pl</p>
                    </li>
                    <li>
                        <i class="material-icons">place</i>
                        <p>ul. Świeradowska 51-57 , 50-559 Wrocław</p>
                    </li>
                </ul>
            </div>
            <div class="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.7708189388136!2d17.039452815754117!3d51.0757805795665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc2f9ae63608d%3A0xa80ec26f8880e6d0!2sBiuro%20Rachunkowe%20AFK%20Centrum%20Obs%C5%82ugi%20Biznesu!5e0!3m2!1spl!2spl!4v1617876451230!5m2!1spl!2spl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Theme3Contact'    
}
</script>

<style lang="scss" scoped>
    h3 {
        margin: 20px;
        font-size: 50px;
        letter-spacing: 1px;
        color: #f5af19;
    }
    section.contact {
        min-height: 70vh;
        height: 600px;
    }
    div.content {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
    }
    div.content div.map {
        background: #000;
        width: 50%;
        height: 100%;
    }
    div.content div.left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5%;
    }
    div.content ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0;
    }
    div.content li {
        display: flex;
        align-items: center;
        margin: 20px;
    }
    div.content li i {
        color: #f5af19;
        margin-right: 20px;
        font-size: 60px;
    }
    div.content li p {
        font-size: 25px;
        font-weight: 300;
        letter-spacing: 1px;
    }
    @media (max-width: 1100px) {
        div.content {
            flex-direction: column;
            height: 1200px;
        }
        div.content div.map {
            width: 100%;
            height: 50%;
        }
        div.content div.left {
            margin: 0 auto;
            width: 90%;
            height: 50%;
        }
    }
</style>
