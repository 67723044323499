<template>
  <header :class="{ fixed: headerFixed, 'no-bg': navMobile}">
    <h1><a href="" @click.prevent="scrollToElement('theme1-card')">Company Name</a></h1>
    <ul>
      <li :class="{ active: activeSection == 'theme1-card' }"><a href="" @click.prevent="scrollToElement('theme1-card')">Strona główna</a></li>
      <li :class="{ active: activeSection == 'theme1-section5' }"><a href="" @click.prevent="scrollToElement('theme1-section5')">Quisque</a></li>
      <li :class="{ active: activeSection == 'theme1-section1' }"><a href="" @click.prevent="scrollToElement('theme1-section1')">Finibus</a></li>
      <li :class="{ active: activeSection == 'theme1-section3' }"><a href="" @click.prevent="scrollToElement('theme1-section3')">Proin</a></li>
      <li :class="{ active: activeSection == 'theme1-section4' }"><a href="" @click.prevent="scrollToElement('theme1-section4')">Purus</a></li>
      <li :class="{ active: activeSection == 'theme1-section2' }"><a href="" @click.prevent="scrollToElement('theme1-section2')">Egestas</a></li>
      <li :class="{ active: activeSection == 'theme1-section-contact' }"><a href="" @click.prevent="scrollToElement('theme1-section-contact')">Kontakt</a></li>
    </ul>
    <div class="menu">
      <i class="material-icons">{{ menuIcon }}</i>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  data(){
    return{
      headerFixed: 0,
      scrollListener: 0,
      activeRoute: this.$route.name,
      menuIcon: 'menu',
    }
  },
  computed:{
    activeSection() {
      return this.$store.state.onePageActiveSection;
    },
    navMobile(){
      return this.$store.state.navMobile;
    },
  },
  created(){
    let self = this;
    this.scrollListener = window.addEventListener("scroll", function(){
      if(document.documentElement.scrollTop > 0){
        self.headerFixed = 1;
      }else{
        self.headerFixed = 0;
      }
    });
    if(document.documentElement.scrollTop > 0){
      self.headerFixed = 1;
    }else{
      self.headerFixed = 0;
    }
  },
  watch: {
    $route(to, from){
      this.activeRoute = this.$route.name;
    },
    navMobile(){
      if(this.navMobile){
        this.menuIcon = 'close';
      }else{
        this.menuIcon = 'menu';
      }
    }
  },
  methods: {
    toggleNavMobile(){
      if(!this.navMobile){
        this.$store.commit('toggleNavMobile', 1);
        document.body.style = "overflow-y: hidden";
      }else{
        this.$store.commit('toggleNavMobile', 0);
        document.body.style = "overflow-y: overlay";
      }
    },
    scrollToElement(id) {
      const element = document.getElementById(id);
      const offset = 70;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition - offset + window.pageYOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    },
  },
  destroyed(){
    window.removeEventListener('scroll');
  }
}
</script>
<style scoped lang="scss">
  header { display: flex; align-items: center; justify-content: space-between; width: 100vw; height: 100px; padding: 0 100px; position: fixed; top: 0; left: 0; z-index: 5; }
  header.fixed { background: #fff; height: 70px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
  h1 { margin: 0; padding: 0; }
  h1 a { display: block; color: #0182e2; text-decoration: none; font-weight: 600; font-size: 24px; }
  ul { display: flex; margin: 0; padding: 0; list-style: none; }
  ul li a { display: inline-block; padding: 7px 5px; margin: 0 50px 0 0; color: rgba(0, 0, 0, 0.5); text-decoration: none; font-size: 16px; font-weight: 500; }
  ul li:last-child a { margin-right: 0; }
  ul li:hover a { color: #00ed93; }
  ul li.active a { color: #00ed93; border-bottom: 2px solid #00ed93; }
  div.menu { display: none; justify-content: center; align-items: center; width: 70px; height: 70px; }
  div.menu:hover { transition: .5s; cursor: pointer; }
  div.menu i.material-icons { color: rgba(0, 0, 0, 0.5); font-size: 32px; }
  div.menu:hover i.material-icons { color: #00ed93; }

  @media (max-width: 1200px) {
    header { padding: 0 20px; }
  }
  @media (max-width: 1100px) {
    ul li a { margin: 0 25px 0 0; }
  }
  @media (max-width: 1000px) {
    header { padding: 0; }
    ul { display: none; }
    div.menu { display: flex; }
    h1 a { padding: 0 20px; font-size: 22px; }
  }

  @media (max-width: 700px) {
    header { height: 70px; }
  }
</style>
