<template>
  <div id="theme-changer" :class="{ landingpage1: this.checkTheme('Theme2', 1), onepage2: this.checkTheme('Theme3', 1), landingpage2: this.checkTheme('Theme4', 1), }">
    <router-link :to="{ name: 'Home' }" class="theme">
      <div class="number">
        <div class="dot"></div>
        <h6>0</h6>
      </div>
      <p>Design&Web</p>
    </router-link>
    <router-link :to="{ name: 'Theme1Home' }" class="theme" :class="{ active: this.checkTheme('Theme1', 1) }">
      <div class="number">
        <div class="dot"></div>
        <h6>1</h6>
      </div>
      <p>One Page 1</p>
    </router-link>
    <router-link :to="{ name: 'Theme2Home' }" class="theme" :class="{ active: this.checkTheme('Theme2', 1) }">
      <div class="number">
        <div class="dot"></div>
        <h6>2</h6>
      </div>
      <p>Landing Page 1</p>
    </router-link>
    <router-link :to="{ name: 'Theme3Home' }" class="theme" :class="{ active: this.checkTheme('Theme3', 1) }">
      <div class="number">
        <div class="dot"></div>
        <h6>3</h6>
      </div>
      <p>OnePage 2</p>
    </router-link>
    <router-link :to="{ name: 'Theme4Home' }" class="theme" :class="{ active: this.checkTheme('Theme4', 1) }">
      <div class="number">
        <div class="dot"></div>
        <h6>4</h6>
      </div>
      <p>Landing Page 2</p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ThemeChanger',
  data(){
    return{

    }
  },
  methods: {
    checkTheme(search, had){
      if(had == 0){
        if(this.$route.name.indexOf(search) == -1){
          return true;
        }else{
          return false;
        }
      }else{
        if(this.$route.name.indexOf(search) != -1){
          return true;
        }else{
          return false;
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
  div#theme-changer { position: fixed; top: calc(50vh - 125px); right: -140px; cursor: pointer; transition: 0.5s; z-index: 5; }
  div#theme-changer:hover { right: 0; transition: 0.5s; }
  div#theme-changer a.theme { display: flex; justify-content: center; align-items: center; background: #00ed93; text-decoration: none; 
    border-bottom: 1px solid rgba(255, 255, 255, 0.4); border-left: 1px solid rgba(255, 255, 255, 0.4); }
  div#theme-changer a.theme:first-child { border-top: 1px solid rgba(255, 255, 255, 0.4); }
  div#theme-changer a.theme div.number { display: flex; justify-content: center; align-items: center; width: 50px; height: 50px; border-right: 2px solid rgba(255, 255, 255, 0.1)}
  div#theme-changer a.theme div.number div.dot { display: none; width: 5px; height: 5px; margin: 0 7.5px 0 -12.5px; border-radius: 50%; background: #fff;; }
  div#theme-changer a.theme.active div.number div.dot { display: block; }
  div#theme-changer a.theme:hover div.number div.dot { display: block; }
  div#theme-changer a.theme div.number h6 { margin: 0; padding: 0; font-size: 18px; font-weight: 400; color: #fff; }
  div#theme-changer a.theme p { width: 140px; height: 50px; margin: 0; padding: 0; font-size: 15px; text-align: center; line-height: 50px; color: #fff; }

  div#theme-changer.landingpage1 a.theme { background: #5041a0; }
  div#theme-changer.onepage2 a.theme { background: #000; }
  div#theme-changer.onepage2 a.theme div.number div.dot { background: #f5af19; }
  div#theme-changer.onepage2 a.theme div.number h6 { color: #f5af19;  } 
  div#theme-changer.onepage2 a.theme p { color: #f5af19; }
  div#theme-changer.landingpage2 a.theme { background: $Theme4Secondary;}
  div#theme-changer.landingpage2 a.theme div.number div.dot { background: $Theme4Primary; }
  div#theme-changer.landingpage2 a.theme div.number h6 { color: $Theme4Primary;  } 
  div#theme-changer.landingpage2 a.theme p { color: $Theme4Primary; }

  @media (max-width: 600px) {
    div#theme-changer a.theme div.number { width: 40px; height: 40px; }
    div#theme-changer a.theme div.number div.dot { margin: 0 4.5px 0 -9.5px; }
    div#theme-changer a.theme p { width: 140px; height: 40px; }
  }
</style>
