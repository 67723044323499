<template>
  <div class="home-technologies">
    <div class="particles">
      <Particles id="particles3" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
    <div class="container">
      <article>
        <h4>Używamy najnowszych dostępnych technologii</h4>
        <ul>
          <li>
            <i class="material-icons">done</i>
            <p>HTML5</p>
          </li>
          <li>
            <i class="material-icons">done</i>
            <p>CSS3</p>
          </li>
          <li>
            <i class="material-icons">done</i>
            <p>Vue.js</p>
          </li>
          <li>
            <i class="material-icons">done</i>
            <p>MongoDB</p>
          </li>
          <li>
            <i class="material-icons">done</i>
            <p>MySQL, MariaDB</p>
          </li>
        </ul>
      </article>
      <div class="imgs">
        <div class="hexagon">
          <img src="../../assets/technologies/html5.png">
        </div>
        <div class="hexagon">
          <img src="../../assets/technologies/css3.png">
        </div>
        <div class="hexagon">
          <img src="../../assets/technologies/vuejs.png">
        </div>
        <div class="hexagon">
          <img src="../../assets/technologies/mongodb.png">
        </div>
        <div class="hexagon">
          <img src="../../assets/technologies/mariadb.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeTechnologies',
  data(){
    return{
      particlesInit: 0,
      particlesLoaded: 0,
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    }
  },
}
</script>
<style scoped lang="scss">
  div.home-technologies { padding: 100px 0; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); 
    position: relative; box-shadow: 0px -2px 5px -2px #000; }
  div.container { display: flex; justify-content: center; flex-wrap: wrap; position: relative; z-index: 2;}

  // div.absolute-container {
  //   position: absolute;
  //   z-index: 2;
  //   display: flex;
  //   justify-content: center;
  //   height: 100%;
  // }

  div.imgs { width: 600px; margin: 0 50px; position: relative; }
  // div.imgs img { display: block; }
  div.imgs div.hexagon { position: absolute; top: 0; left: 0; }
  div.imgs div.hexagon:nth-child(2) { top: 0; left: 210px; }
  div.imgs div.hexagon:nth-child(3) { top: 0; left: 420px; }
  div.imgs div.hexagon:nth-child(4) { top: 180px; left: 105px; }
  div.imgs div.hexagon:nth-child(5) { top: 180px; left: 315px; }
  div.imgs div.hexagon:nth-child(6) { top: 180px; left: 525px; }

  .hexagon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 200px;
    height: 115.61px;
    background: rgba(255, 255, 255, 0.2);
    margin: 57.5px auto;
    z-index: 2;
  }
  .hexagon::before,
  .hexagon::after {
    content: "";
    position: absolute;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
  }
  .hexagon::before {
    bottom: 100%;
    border-bottom: 57.5px solid rgba(255, 255, 255, 0.2);
  }
  .hexagon::after {
    top: 100%;
    border-top: 57.5px solid rgba(255, 255, 255, 0.2);
  }


  article { width: 600px; margin: 0 50px; }
  article h4 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 300; color: #fff }
  article ul { margin: 0; padding: 0; list-style: none; }
  article ul li { display: flex; align-items: center; margin-bottom: 15px; }
  article ul li i.material-icons { display: flex; justify-content: center; align-items: center; width: 20px; height: 20px; border-radius: 50%; border: 1px solid #00E676; font-size: 12px; color: #00E676; }
  article ul li p { margin: 0 0 0 10px; padding: 0; font-size: 18px; color:#fff; }

  @media (max-width: 1400px) {
    article { margin: 0 25px; }
    div.imgs { margin: 0 25px; }
    div.imgs { display: none; }
  }
   @media (max-width: 1100px) {
    article h4 { text-align: center; }
    article ul li { justify-content: center; }
  }
  @media (max-width: 640px) {
    article { width: calc(100vw - 40px); margin: 0 auto; }
  }
  @media (max-width: 500px) {
    div.home-technologies { padding: 50px 0; }
    article h4 { font-size: 40px; }
    div.imgs { display: none; }
  }
</style>
