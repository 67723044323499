<template>
  <section id="theme1-section-contact">
    <h3>Skontaktuj się z nami!</h3>
    <a href="#">example@example.com</a>
    <div class="flex">
      <div class="box">
        <h5>AFK Centrum Obsługi Biznesu Sp. z o.o.</h5>
        <div class="box-flex">
          <i class="material-icons">place</i>
          <h6>ul. Świeradowska 51-57 , 50-559 Wrocław</h6>
        </div>
        <div class="box-flex">
          <i class="material-icons">home</i>
          <h6>I piętro Centrum Handlowe Ferio Gaj</h6>
        </div>
        <div class="box-flex">
          <i class="material-icons">email</i>
          <h6>example@example.com</h6>
        </div>
        <div class="box-flex">
          <i class="material-icons">smartphone</i>
          <h6>+48 909 909 909</h6>
        </div>
      </div>
      <div class="box">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.7708189388136!2d17.039452815754117!3d51.0757805795665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc2f9ae63608d%3A0xa80ec26f8880e6d0!2sBiuro%20Rachunkowe%20AFK%20Centrum%20Obs%C5%82ugi%20Biznesu!5e0!3m2!1spl!2spl!4v1617876451230!5m2!1spl!2spl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Theme1SectionContact',
 
}
</script>

<style scoped lang="scss">
  section#theme1-section-contact { display: flex; flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap; padding: 50px 0; border-top: 1px solid rgba(1,130,226,0.3);
    box-shadow: inset 0 5px 5px rgba(1,130,226,0.1); }

  div.flex { display: flex; flex-wrap: wrap; justify-content: center; width: 100%; }

  div.box { width: 400px; height: 300px; margin: 20px; padding: 20px; box-shadow: 0 0 5px #00ed93;}
  div.box div.box-flex { display: flex; margin: 20px 0; }
  div.box i.material-icons { display: block; width: 24px; height: 24px; font-size: 23px; color: #0182e2;; }
  div.box h5 { margin: 0 0 30px 0; padding: 0; color: #0182e2; font-weight: 400; font-size: 19px; line-height: 24px; }
  div.box h6 { margin: 0 0 0 10px; padding: 0; color: rgba(0, 0, 0, 0.7); font-weight: 400; font-size: 16px; line-height: 24px; }

  h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 300; color: #0182e2; text-align: center; }
  a { display: inline-block; height: 50px; margin: 30px 0; padding: 0 18px; background: #00ed93; border-radius: 25px; text-decoration: none; color: #fff; letter-spacing: 1px; font-size: 16px; font-weight: 600;
  border: 2px solid #00ed93; line-height: 50px; }
  a:hover { background: #fff; color: #00ed93; }

  @media (max-width: 700px) {
    h3 { padding: 0 20px; font-size: 36px; }
  }

  @media (max-width: 440px) {
    div.box { width: calc(100vw - 40px); height: initial; }
  }

</style>
