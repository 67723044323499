<template>
  <div class="theme2-wrapper">
    <theme2-the-header></theme2-the-header>
    <router-view />
    <theme2-the-footer></theme2-the-footer>
    <theme-changer></theme-changer>
  </div>
</template>

<script>
import Theme2TheHeader from '@/components/themes/theme2/Theme2TheHeader.vue'
import Theme2TheFooter from '@/components/themes/theme2/Theme2TheFooter.vue'
import ThemeChanger from '@/components/ThemeChanger.vue'

export default {
  name: 'Theme2Wrapper',
  components: {
    Theme2TheHeader,
    Theme2TheFooter,
    ThemeChanger
  }
}
</script>

<style scoped lang="scss">
  div.theme2-wrapper { margin: 0; }
</style>
