<template>
    <footer>

        <h6><span>Company Name</span> 2021 &copy; All right reserved.</h6>
    </footer>
</template>

<script>
export default {
  name: "Theme4TheFooter",
};
</script>

<style scoped lang="scss">
    footer {
        height: 40px;
        background: $Theme4Secondary;        
        overflow: hidden;
    }
    footer h6 {
        margin: 0;
        padding: 0;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-weight: 500;
        font-size: 14px;
    }
    footer h6 span {
        color: $Theme4Primary;
    }
</style>