<template>
  <div class="project">
    <div class="particles">
      <Particles id="particles4" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
    <div class="wrapper">
    <img :src="require('@/assets/projects/' + projects[project].img)" alt="Wersje responsywne projektu">
    <div class="content">
       <h4>{{ projects[project].title }}</h4>
       <p>{{ projects[project].desc }}</p>
       <ul>
        <li v-for="(element, index) in projects[project].elements" :key="index">
          <i class="material-icons">{{ element.icon }}</i>
          <p>{{ element.text }}</p>
        </li>
      </ul>
      <a class="default" target="_blank" :href="projects[project].link">Przejdź do strony</a>
     </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Project',
  data(){
    return{
      particlesInit: 0,
      particlesLoaded: 0,
      project: 0,
      projects: [
        { 
          title: 'DoradzamyAuta', 
          img: 'doradzamyauta.webp', 
          link: 'https://doradzamyauta.pl/', 
          desc: 'Wykonaliśmy indywidualny projekt graficzny, zgodny z wizją Klienta z branży motoryzacyjnej. Stronę cechuje lekkość, prostota i estetyczny minimalizm. Można na niej z łatwością znaleźć wszystkie niezbędne informacje o działalności i zasadach jej funkcjonowania. Posiada formularz kontaktowy dedykowany zarówno dla firm jaki i klientów indywidualnych.',
          elements: [
            { text: 'Landing page', icon: 'done' },
            { text: 'Indywidulany projekt graficzny', icon: 'color_lens' },
            { text: 'Nowe zaprojektowane logo', icon: 'image' },
            { text: 'Headless CMS', icon: 'dashboard' },
            { text: 'Wersja mobile', icon: 'phone_android' },
          ]
        },
        { 
          title: 'AFK Wirtualne Biuro', 
          img: 'afkwirtualnebiuro.webp', 
          link: 'https://wirtualnebiuro.afk-cob.pl/', 
          desc: 'Strona informacyjna dla uslugi Wirtualnego Biura dla AFK Centrum Obsługi Biznesu. Strona dostarcza wszystkich informacji o usłudze oraz umożliwia jej zakup i konfigurację. Jej uzupełnieniem jest zaawansowany Panel Klienta w którym klient może zarządzać swoimi usługami i je monitorować.', 
          elements: [
            { text: 'Landing page', icon: 'done' },
            { text: 'Indywidulany projekt graficzny', icon: 'color_lens' },
            { text: 'Funkcja kupna usługi', icon: 'shopping_cart' },
            { text: 'Formularz kontaktowy', icon: 'phone' },
            { text: 'Wersja mobile', icon: 'phone_android' },
          ]
        },
        { 
          title: 'NovaHolding', 
          img: 'novaholding.webp', 
          link: 'https://www.novaholding.pl/', 
          desc: 'Projekt indywidualny będący odświeżeniem przestarzałej już wersji strony. Firma zajmująca się ochroną obiektów oraz sprzedażą narzędzi monitorujących. Logotyp pozostawiono oryginalny na życzenie Klienta. Strona stonowana, prosta - informacyjna. Użytkownik w łatwy sposób może przeglądać katalog produktów i usług oraz pobierać instrukcje obsługi oferowanego sprzętu.', 
          elements: [
            { text: 'Landing page', icon: 'done' },
            { text: 'Indywidulany projekt graficzny', icon: 'color_lens' },
            { text: 'Headless CMS', icon: 'dashboard' },
            { text: 'Wersja mobile', icon: 'phone_android' },
          ]
        }
      ]
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    }
  },
  created(){
    this.checkProject();
  },
  watch: {
    $route(to, from){
      this.checkProject();
    }
  },
  methods: {
    checkProject(){
      if(this.projects.length < this.$route.params.number || !Number.isInteger(parseInt(this.$route.params.number))){
        this.$router.push({ name: 'Project', params: { number: 1 } });
      }else{
        this.project = this.$route.params.number - 1;
      }
    },
  }
}
</script>

<style scoped lang="scss">
  div.project { position: relative; min-height: calc(100vh - 40px); padding-top: 70px; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  div.wrapper { display: flex; flex-wrap: wrap; justify-content: center; align-items: center; position: relative; z-index: 2; min-height: calc(100vh - 40px - 70px); }

  img { display: block; width: 1000px; height: 470px; margin: 40px 50px; padding: 0; }

  div.content { width: 600px; margin: 40px 50px; }
  div.content h4 { margin: 0; padding: 0; color: #fff; font-weight: 300; font-size: 45px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;  }
  div.content p { margin: 40px 0 40px 0; padding: 0; color: rgba(255, 255, 255, 0.8);  font-weight: 300; line-height: 25px; font-size: 16px; text-align: justify; }
  div.content ul { margin: 0 0 40px 0; padding: 0; list-style: none; }
  div.content ul li { display: flex; align-items: center; margin-bottom: 15px; }
  div.content ul li i.material-icons { display: flex; justify-content: center; align-items: center; width: 35px; height: 35px; border-radius: 50%; border: 1px solid #00E676; font-size: 17px; color: #00E676; }
  div.content ul li p { margin: 0 0 0 15px; padding: 0; font-size: 18px; color:#fff; font-weight: 300; }

  @media (max-width: 1700px) {
    img { width: 600px; height: 282px; margin: 40px 20px; }
    div.content { width: 600px; margin: 40px 20px; }
  }

  @media (max-width: 640px) {
    img { width: 400px; height: 188px; }
    div.content { width: calc(100% - 40px);}
  }
  @media (max-width: 400px) {
    img { width: 300px; height: 141px; }
    div.content { width: calc(100% - 40px); height: inherit; }
    div.content h4 { font-size: 35px; }
  }
  @media (max-width: 300px) {
    img { display: none; }
    div.content { width: calc(100% - 40px); height: inherit; }
  }
</style>
