<template>
  <div class="theme4-sections">
    <theme4-section3></theme4-section3>
    <theme4-section4></theme4-section4>
    <theme4-section5></theme4-section5>
    <theme-changer></theme-changer>
  </div>
</template>

<script>
import Theme4Section3 from '@/components/themes/theme4/Theme4Section3.vue';
import Theme4Section4 from '@/components/themes/theme4/Theme4Section4.vue';
import Theme4Section5 from '@/components/themes/theme4/Theme4Section5.vue';

import ThemeChanger from '@/components/ThemeChanger.vue';

export default {
  name: 'theme4Home',
  components: {
    ThemeChanger,
    Theme4Section3,
    Theme4Section4,
    Theme4Section5,
  }
}
</script>

<style scoped lang="scss">
  div.theme4-sections { margin: 0; }
</style>
