<template>
  <section class="theme2-section4">
    <header>
      <h3>Lorem ipsum</h3>
    </header>
    <div class="flex">
     <article :class="{ active: containerActive == 1 }">
       <i class="material-icons">done</i>
       <h5>Lorem ipsum</h5>
       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce suscipit sem lorem, eu consequat dui pretium sit amet. Sed malesuada sapien urna.</p>
     </article>
     <article :class="{ active: containerActive == 2 }">
       <i class="material-icons">public</i>
       <h5>Lorem ipsum</h5>
       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce suscipit sem lorem, eu consequat dui pretium sit amet. Sed malesuada sapien urna.</p>
     </article>
     <article :class="{ active: containerActive == 3 }">
       <i class="material-icons">poll</i>
       <h5>Lorem ipsum</h5>
       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce suscipit sem lorem, eu consequat dui pretium sit amet. Sed malesuada sapien urna.</p>
     </article>
     <article :class="{ active: containerActive == 4 }">
       <i class="material-icons">carpenter</i>
       <h5>Lorem ipsum</h5>
       <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce suscipit sem lorem, eu consequat dui pretium sit amet. Sed malesuada sapien urna.</p>
     </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Theme2Section4',
  data(){
    return{
      containerActive: 1,
      containerCount: 4,
      interval: 0,
    }
  },
  created(){
    let self = this;
    this.inverval = setInterval(function(){ 
      if(self.containerActive == self.containerCount){
        self.containerActive = 1;
      }else{
        self.containerActive++;
      }
     }, 4000);
  }
}
</script>

<style scoped lang="scss">
  section.theme2-section4 { display: flex; flex-direction: column; align-items: center; justify-content: center; flex-wrap: wrap; padding: 100px 0; }

  header { width: 600px; }
  header h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 500; color: #5041a0; text-align: center; }

  div.flex { display: flex; flex-wrap: wrap; }

  article { display: flex; flex-direction: column; width: 250px; height: 300px; margin: 40px; background: #f6f4f9; border-radius: 3px; border-bottom-left-radius: 15px; box-shadow: 0 0 5px rgba(0,0,0,0.4); transition: 1s; }
  article i.material-icons { margin: 30px 10px; font-size: 36px; color: #5041a0; }
  article h5 { margin: 0 10px 10px 10px; padding: 0; font-weight: 500; color: #5041a0; font-size: 18px; }
  article p { margin: 0 10px; padding: 0; font-size: 14px; line-height: 20px; color: rgba(0, 0, 0, 0.6); }
  article.active { background: #5041a0; transition: 1s; }
  article.active i.material-icons { color: #fff; }
  article.active h5 { color: #fff; }
  article.active p { color: rgba(255, 255, 255, 0.6); }
</style>
