<template>
  <div class="theme2-gallery">
    <header>
      <h3>Galeria</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id sollicitudin lectus. Proin rhoncus dui ut turpis imperdiet, in dignissim sem finibus. Aenean auctor nulla sit amet erat dictum.</p>
      <div class="circle first">
        <div class="circle-small"></div>
      </div>
      <div class="circle second">
        <div class="circle-small"></div>
      </div>
      <div class="circle third">
        <div class="circle-small"></div>
      </div>
    </header>
    <gallery-3></gallery-3>
  </div>
</template>

<script>
import Gallery3 from '@/components/themes/galleries/Gallery3.vue'

export default {
  name: 'Theme2Gallery',
  components: {
    Gallery3,
  }
}
</script>
<style scoped lang="scss">
  div.theme2-gallery { min-height: 100vh; margin: 0;overflow: auto; }

  header { background: #5041a0; padding: 150px; overflow: hidden; position: relative; }
  header h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 500; color: #fff; text-align: center; }
  header p { width: 600px; margin: 30px auto; padding: 0; font-size: 16px; color: rgba(255, 255, 255, 0.8); line-height: 25px; text-align: center; }
  div.circle { display: flex; justify-content: center; align-items: center; width: 180px; height: 180px; border-radius: 50%; background: rgb(108,52,163); background: linear-gradient(135deg, rgba(108,52,163,1) 0%, rgba(0,237,147,0.3) 100%);
    position: absolute; z-index: 2; }
  div.circle div.circle-small { width: 160px; height: 160px; border-radius: 50%; background: #5041a0; }
  div.circle.first { bottom: 100px; left: 50px; }
  div.circle.second { width: 80px; height: 80px; top: 100px; left: 400px; }
  div.circle.second div.circle-small { width: 65px; height: 65px; }
  div.circle.third { width: 100px; height: 100px; top: 30%; right: 100px; }
  div.circle.third div.circle-small { width: 80px; height: 80px; }
</style>
