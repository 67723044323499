<template>
    <section class="carousel4-wrapper">
        <carousel-4-wrapper class="carousel" >
            <carousel-4-slide v-for="(slide, index) in slides"
                class="carousel-slider"
                :key="slide + index" 
                :index="index"
                :visibleSlide="visibleSlide"
                :direction="direction"
            >
                <div class="text">
                    <h4>{{ slide.title }}</h4>
                    <p>{{ slide.content }}</p>
                </div>
                <div class="image" :style="{ 'background-image': `url(${ slide.image })` }"/>
            </carousel-4-slide>
            <!-- <button class="next" @click="next()">
                <i class="material-icons">expand_more</i>
            </button>
            <button class="prev" @click="prev()">
                <i class="material-icons">expand_more</i>
            </button> -->
            <div class="circles">
                <div v-for="(circleSlide, circleIndex) in slides" 
                    class="circle" 
                    :class="{ active: circleIndex === visibleSlide }"  
                    :key="circleIndex + circleSlide.title"
                    @click="changeVisibleSlide(circleIndex)"
                ></div>
            </div>
        </carousel-4-wrapper>
    </section>
</template>

<script>
import Carousel4Slide from './Carousel4Slide';
import Carousel4Wrapper from './Carousel4Wrapper';

export default {
    name: 'Carousel4',
    components: {
        Carousel4Slide,
        Carousel4Wrapper
    },
    data() {
        return {
            slides: [{
                    title: 'Lorem ipsum dolor',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    image: 'https://images.unsplash.com/photo-1499028344343-cd173ffc68a9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                },{
                    title: 'Lorem ipsum dolor',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    image: 'https://images.unsplash.com/photo-1548943487-a2e4e43b4853?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80',
                },{
                    title: 'Lorem ipsum dolor',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    image: 'https://images.unsplash.com/photo-1543788454-8f380ccd2bf8?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                },{
                    title: 'Lorem ipsum dolor',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    image: 'https://images.unsplash.com/photo-1477434779629-a454c123dcd3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                },{
                    title: 'Lorem ipsum dolor',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    image: 'https://images.unsplash.com/photo-1612039294290-d237af6812c2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2125&q=80'
                },
            ],
            visibleSlide: 0,
            direction: 'left',
            windowWidth: 0,
            imgHeight: 0,
            imgScale: {
                width: 16,
                height: 7
            },
            interval: 0,
        }
    },
    methods: {
        next() {
            if(this.visibleSlide >= this.slides.length - 1) {
                this.visibleSlide = 0;
            } else {
                this.visibleSlide++;
            };
            this.direction = 'left';
            clearInterval(this.interval);
            this.interval = setInterval(() => { 
                this.nextSlideInterval();
            }, 6000);
        },
        prev() {
            if(this.visibleSlide <= 0) {
                this.visibleSlide = this.slides.length - 1;
            } else {
                this.visibleSlide--;
            };
            this.direction = 'right';
            clearInterval(this.interval);
            this.interval = setInterval(() => { 
                this.nextSlideInterval();
            }, 6000);
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        getImgHeight() {
            this.imgHeight = this.windowWidth / this.imgScale.width * this.imgScale.height;
        },
        changeVisibleSlide(index) {
            if(this.visibleSlide > index) {
                this.direction = 'right';
            } else {
                this.direction = 'left';
            };
            this.visibleSlide = index;
            clearInterval(this.interval);
            this.interval = setInterval(() => { 
                this.nextSlideInterval();
            }, 6000);
        },
        nextSlideInterval() {
            if(this.visibleSlide >= this.slides.length - 1) {
                this.visibleSlide = 0;
            } else {
                this.visibleSlide++;
            };
            this.direction = 'left';
        },
    },
    watch: {
        windowWidth() {
            this.getImgHeight();
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
        this.interval = setInterval(() => { 
            this.nextSlideInterval();
        }, 6000);
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    p {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        margin: 0;
    }
    h4 {
        font-weight: 600;
        font-size: 55px;
        margin: 0;
        margin-bottom: 30px;
        letter-spacing: 2px;
    }
    section.carousel4-wrapper {
        width: 100vw;
        max-height: 100vh;
        overflow: hidden;
        background: #000;
    }
    section.carousel4-wrapper .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        max-height: 100vh;
        z-index: 1;
        height: 85vh;
    }
    section.carousel4-wrapper .carousel-slider {
        position:absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        overflow: hidden;
    }
    section.carousel4-wrapper div.text {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        padding-left: 100px;
        color: #fff;
        background: rgba(#000, .4);
    }
    section.carousel4-wrapper div.image {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;
    }
    section.carousel4-wrapper button {
        position: absolute;
        background: rgba(#000, .5);
        color: #fff;
        font-size: 0;
        border: none;
        padding: 5px;
        top: calc(50% - 30px);
    }
    section.carousel4-wrapper button {
        cursor: pointer;
    }
    section.carousel4-wrapper i {
        font-size: 50px;
    }
    section.carousel4-wrapper button.next {
        right: 8px;
    }
    section.carousel4-wrapper button.next i {
        transform: rotate(-90deg);
    }
    section.carousel4-wrapper button.prev i {
        transform: rotate(90deg);
    }
    section.carousel4-wrapper div.circles {
        position: absolute;
        bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    section.carousel4-wrapper div.circles div.circle {
        width: 50px;
        height: 4px;
        background-color: rgba(#fff, .5);
        // border: 2px solid rgba(#fff, .5);
        margin: 5px;
        // border-radius: 50%;
    }
    section.carousel4-wrapper div.circles div.circle:hover {
        cursor: pointer;
    }
    section.carousel4-wrapper div.circles div.circle.active {
        background-color: #fff;
    }
</style>
