<template>
    <section class="theme4-contact">
        <theme4-contact-component></theme4-contact-component>
        <theme-changer></theme-changer>
    </section>
</template>

<script>
import Theme4ContactComponent from '@/components/themes/theme4/Theme4ContactComponent.vue';

import ThemeChanger from '@/components/ThemeChanger.vue';

export default {
  name: 'Theme4Contact',
  components: {
    ThemeChanger,
    Theme4ContactComponent,
  }
}
</script>

<style scoped lang="scss">
  div.theme4-contact { margin: 0; }
</style>
