<template>
    <section class="section7-wrapper">
        <div class="text">
            <h3>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h3>
            <p>
                Sed interdum vitae libero ac eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus at ipsum consectetur, consequat elit eu, semper tellus. Vivamus ante massa, dignissim vitae maximus at, auctor id arcu. 
            </p>
        </div>
        <div class="image"></div>
    </section>
</template>

<script>
export default {
    name: 'Section7'
}
</script>

<style lang="scss" scoped>
    h3 {
        font-size: 35px;
        text-align: center;
        font-weight: 600;
        max-width: 500px;
        letter-spacing: 1px;
    }
    p {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        max-width: 500px;
    }
    section.section7-wrapper {
        margin: 50px 0;
        height: 100vh;
        display: flex;
        position: relative;
    }
    div.text {
        width: 50%;
        height: 100%;
        position: absolute;
        background: rgba(#000, .5);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    div.image {
        width: 100%;
        height: 100%;
        background-image: url('https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
        background-size: cover;
        background-position: center;
    }
    @media (max-width: 1000px) {
        div.text {
            width: 100%;
        }
        h3, p {
            width: 90%;
        }
    }
</style>
