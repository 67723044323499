<template>
  <div class="home-offer">
    <header id="offer">
      <h4>Co oferujemy?</h4>
      <h5>Poniżej znajdują się niektóre z oferowanych przez nas usług</h5>
    </header>
    <div class="flex">
      <div class="container" :class="{ active: containerActive == 1 }">
        <div class="icon">
          <img src="../../assets/offer/icon1.png" alt="Ikona1">
        </div>
        <h5>Strony One page</h5>
        <p>Składają się z jednej strony, bardzo często wydłużonej</p>
      </div>
      <div class="container" :class="{ active: containerActive == 2 }">
        <div class="icon">
          <img src="../../assets/offer/icon2.png" alt="Ikona2">
        </div>
        <h5>Strony Landing page</h5>
        <p>Mają na celu promocję lub sprzedaż produktu lub usługi</p>
      </div>
      <div class="container" :class="{ active: containerActive == 3 }">
        <div class="icon">
          <img src="../../assets/offer/icon3.png" alt="Ikona3">
        </div>
        <h5>Strony firmowe</h5>
        <p>Najważniejsze narzędzia do kreowania wizerunku firm w Internecie</p>
      </div>
      <div class="container" :class="{ active: containerActive == 4 }">
        <div class="icon">
          <img src="../../assets/offer/icon4.png" alt="Ikona4">
        </div>
        <h5>Aplikacje WWW</h5>
        <p>Zaawansowane skrypty usprawniające tworzone strony internetowe</p>
      </div>
      <div class="container design" :class="{ active: containerActive == 5 }">
        <div class="icon">
          <img src="../../assets/offer/icon5.png" alt="Ikona5">
        </div>
        <h5>Logo</h5>
        <p>Identyfikacja wizualna według potrzeb Klienta</p>
      </div>
      <div class="container design" :class="{ active: containerActive == 6 }">
        <div class="icon">
          <img src="../../assets/offer/icon6.png" alt="Ikona6">
        </div>
        <h5>Grafika internetowa</h5>
        <p>Slidery, reklamy, newslettery itp.</p>
      </div>
      <div class="container design" :class="{ active: containerActive == 7 }">
        <div class="icon">
          <img src="../../assets/offer/icon7.png" alt="Ikona7">
        </div>
        <h5>Reklama wewnętrzna</h5>
        <p>Ulotki, plakaty, katalogi, roll-upy itp.</p>
      </div>
      <div class="container design" :class="{ active: containerActive == 8 }">
        <div class="icon">
          <img src="../../assets/offer/icon8.png" alt="Ikona8">
        </div>
        <h5>Reklama zewnętrzna</h5>
        <p>Billboardy, banery, szyldy, tablice itp.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeOffer',
  data(){
    return{
      containerActive: 1,
      containerCount: 8,
      interval: 0,
    }
  },
  created(){
    let self = this;
    this.inverval = setInterval(function(){ 
      if(self.containerActive == self.containerCount){
        self.containerActive = 1;
      }else{
        self.containerActive++;
      }
     }, 3000);
  }
}
</script>
<style scoped lang="scss">
  div.home-offer { display: flex; flex-direction: column; align-items: center; margin: 100px 0; background: #fff; }
  div.flex { display: flex; flex-wrap: wrap; width: 960px; }

  header { margin: 0 20px 50px 20px; }
  header h4 { margin: 0 0 10px 0; padding: 0; font-size: 50px; font-weight: 300; text-align: center; }
  header h5 { margin: 0; padding: 0; font-size: 22px; font-weight: 300; color: $gray; text-align: center; }

  div.container { display: flex; flex-direction: column; align-items: center; width: 200px; height: 250px; margin: 20px; box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); transition: 1s; }
  div.container.active { box-shadow: 0 0 15px rgba(0,129,168,1); transition: 1s; }
  div.container.design.active { box-shadow: 0 0 15px #d2315c; transition: 1s; }
  div.container div.icon { display: flex; justify-content: center; align-items: center; width: 60px; height: 60px; margin: 30px 0; border-radius: 50%; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  div.container.design div.icon { background: rgb(168,0,95); background: linear-gradient(45deg, rgba(168,0,95,1) 0%, rgba(255,103,87,1) 100%); }
  div.container div.icon img { display: block; }
  div.container h5 { margin: 0; padding: 0; font-weight: 500; font-size: 18px; color: $black; text-align: center; }
  div.container p { margin: auto 0 0 0; padding: 0 20px; height: 100px; text-align: center; color: $gray; line-height: 25px; font-size: 14px; font-weight: 500; }

  @media (max-width: 960px) {
    div.flex { justify-content: center; width: 100%; }
  }
  @media (max-width: 500px) {
    header h4 {font-size: 40px; }
    header h5 { font-size: 19px; }
  }
</style>
