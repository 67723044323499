<template>
  <div class="theme4-wrapper">
    <theme4-the-header></theme4-the-header>
      <router-view />
    <theme4-the-footer></theme4-the-footer>
  </div>
</template>

<script>
import Theme4TheHeader from '@/components/themes/theme4/Theme4TheHeader.vue'
import Theme4TheFooter from '@/components/themes/theme4/Theme4TheFooter.vue'

export default {
  name: 'Theme4Wrapper',
  components: {
    Theme4TheHeader,
    Theme4TheFooter
  }
}
</script>

<style lang="scss">
  h4 {
    font-size: 30px;
    letter-spacing: 2px;
    font-weight: 400;
  }
  p {
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 400;
  }
  h3 {
    font-size: 40px;
  }
</style>
