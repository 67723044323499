<template>
  <div class="theme2-offer">
    <header>
      <h3>Oferta</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id sollicitudin lectus. Proin rhoncus dui ut turpis imperdiet, in dignissim sem finibus. Aenean auctor nulla sit amet erat dictum.</p>
      <div class="circle first">
        <div class="circle-small"></div>
      </div>
      <div class="circle second">
        <div class="circle-small"></div>
      </div>
      <div class="circle third">
        <div class="circle-small"></div>
      </div>
    </header>
    <div class="boxes">
      <div class="box">
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed commodo risus, non dignissim enim. Sed pellentesque quam in faucibus porttitor. Nulla et tortor sem. Aenean sit amet lectus quis mi pellentesque ultrices. Suspendisse in erat laoreet, viverra magna id, viverra ligula. Nam aliquam, nibh sit amet accumsan rhoncus, orci erat tempus mauris, et laoreet quam nisl eget velit. Phasellus gravida risus leo, vitae placerat nisl imperdiet sed. Nulla facilisi. Mauris ut pellentesque felis.</p>
      </div>
      <div class="box">
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed commodo risus, non dignissim enim. Sed pellentesque quam in faucibus porttitor. Nulla et tortor sem. Aenean sit amet lectus quis mi pellentesque ultrices. Suspendisse in erat laoreet, viverra magna id, viverra ligula. Nam aliquam, nibh sit amet accumsan rhoncus, orci erat tempus mauris, et laoreet quam nisl eget velit. Phasellus gravida risus leo, vitae placerat nisl imperdiet sed. Nulla facilisi. Mauris ut pellentesque felis.</p>
      </div>
      <div class="box">
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed commodo risus, non dignissim enim. Sed pellentesque quam in faucibus porttitor. Nulla et tortor sem. Aenean sit amet lectus quis mi pellentesque ultrices. Suspendisse in erat laoreet, viverra magna id, viverra ligula. Nam aliquam, nibh sit amet accumsan rhoncus, orci erat tempus mauris, et laoreet quam nisl eget velit. Phasellus gravida risus leo, vitae placerat nisl imperdiet sed. Nulla facilisi. Mauris ut pellentesque felis.</p>
      </div>
      <div class="box">
        <h4>Lorem ipsum sit dolor</h4>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sed commodo risus, non dignissim enim. Sed pellentesque quam in faucibus porttitor. Nulla et tortor sem. Aenean sit amet lectus quis mi pellentesque ultrices. Suspendisse in erat laoreet, viverra magna id, viverra ligula. Nam aliquam, nibh sit amet accumsan rhoncus, orci erat tempus mauris, et laoreet quam nisl eget velit. Phasellus gravida risus leo, vitae placerat nisl imperdiet sed. Nulla facilisi. Mauris ut pellentesque felis.</p>
      </div>
    </div>
    <div class="articles">
      <article>
        <img src="../../../assets/themes/theme1/img1.png">
        <div class="container">
          <h4>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</h4>
          <p>Sed non tempor lectus, porta luctus augue. Aliquam metus est, sollicitudin non porttitor sit amet, mattis quis neque. In id lorem justo. Duis tincidunt, nunc non porttitor porttitor, neque felis eleifend ligula, vitae euismod tellus purus id mi. Sed feugiat imperdiet ante, ac bibendum ante rhoncus ut. Cras et neque tellus. Phasellus augue velit, placerat vel porttitor eu, ultrices cursus metus. Mauris vel nunc id sapien bibendum porta. Fusce sollicitudin egestas urna non vestibulum. Sed tincidunt convallis turpis, sit amet vehicula massa lacinia et. Ut eget dictum justo. Suspendisse eu eros ipsum. Sed tortor mi, tempor quis venenatis vitae, dapibus sagittis metus. Praesent rhoncus urna elit, et suscipit ligula rutrum non.</p>
        </div>
      </article>
      <article>
        <img src="../../../assets/themes/theme1/img1.png">
        <div class="container">
          <h4>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</h4>
          <p>Sed non tempor lectus, porta luctus augue. Aliquam metus est, sollicitudin non porttitor sit amet, mattis quis neque. In id lorem justo. Duis tincidunt, nunc non porttitor porttitor, neque felis eleifend ligula, vitae euismod tellus purus id mi. Sed feugiat imperdiet ante, ac bibendum ante rhoncus ut. Cras et neque tellus. Phasellus augue velit, placerat vel porttitor eu, ultrices cursus metus. Mauris vel nunc id sapien bibendum porta. Fusce sollicitudin egestas urna non vestibulum. Sed tincidunt convallis turpis, sit amet vehicula massa lacinia et. Ut eget dictum justo. Suspendisse eu eros ipsum. Sed tortor mi, tempor quis venenatis vitae, dapibus sagittis metus. Praesent rhoncus urna elit, et suscipit ligula rutrum non.</p>
        </div>
      </article>
      <article>
        <img src="../../../assets/themes/theme1/img1.png">
        <div class="container">
          <h4>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</h4>
          <p>Sed non tempor lectus, porta luctus augue. Aliquam metus est, sollicitudin non porttitor sit amet, mattis quis neque. In id lorem justo. Duis tincidunt, nunc non porttitor porttitor, neque felis eleifend ligula, vitae euismod tellus purus id mi. Sed feugiat imperdiet ante, ac bibendum ante rhoncus ut. Cras et neque tellus. Phasellus augue velit, placerat vel porttitor eu, ultrices cursus metus. Mauris vel nunc id sapien bibendum porta. Fusce sollicitudin egestas urna non vestibulum. Sed tincidunt convallis turpis, sit amet vehicula massa lacinia et. Ut eget dictum justo. Suspendisse eu eros ipsum. Sed tortor mi, tempor quis venenatis vitae, dapibus sagittis metus. Praesent rhoncus urna elit, et suscipit ligula rutrum non.</p>
        </div>
      </article>
      <article>
        <img src="../../../assets/themes/theme1/img1.png">
        <div class="container">
          <h4>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet</h4>
          <p>Sed non tempor lectus, porta luctus augue. Aliquam metus est, sollicitudin non porttitor sit amet, mattis quis neque. In id lorem justo. Duis tincidunt, nunc non porttitor porttitor, neque felis eleifend ligula, vitae euismod tellus purus id mi. Sed feugiat imperdiet ante, ac bibendum ante rhoncus ut. Cras et neque tellus. Phasellus augue velit, placerat vel porttitor eu, ultrices cursus metus. Mauris vel nunc id sapien bibendum porta. Fusce sollicitudin egestas urna non vestibulum. Sed tincidunt convallis turpis, sit amet vehicula massa lacinia et. Ut eget dictum justo. Suspendisse eu eros ipsum. Sed tortor mi, tempor quis venenatis vitae, dapibus sagittis metus. Praesent rhoncus urna elit, et suscipit ligula rutrum non.</p>
        </div>
      </article>
    </div>
    <div class="pricetable">
      <h4>Cennik</h4>
      <header>
        <div class="row">
          <div class="cell">
            <p>Pakiet</p>
          </div>
          <div class="cell">
            <p>Pakiet Alfa</p>
          </div>
          <div class="cell">
            <p>Pakiet Beta</p>
          </div>
          <div class="cell">
            <p>Pakiet Gamma</p>
          </div>
          <div class="cell">
            <p>Pakiet Delta</p>
          </div>
        </div>
      </header>
      <div class="middle">
        <div class="row">
          <div class="cell title">
            <p>Lorem ipsum</p>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
        </div>
        <div class="row">
          <div class="cell title">
            <p>Lorem ipsum</p>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
        </div>
        <div class="row">
          <div class="cell title">
            <p>Donec varius est ligula, vitae ultricies diam sollicitudin sed</p>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
        </div>
        <div class="row">
          <div class="cell title">
            <p>Donec varius est ligula</p>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
        </div>
        <div class="row">
          <div class="cell title">
            <p>Donec varius est ligula, vitae ultricies diam sollicitudin sed</p>
          </div>
          <div class="cell">
            <i class="material-icons close">close</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
        </div>
        <div class="row">
          <div class="cell title">
            <p>Donec varius est ligula, vitae ultricies diam sollicitudin sed</p>
          </div>
          <div class="cell">
            <i class="material-icons close">close</i>
          </div>
          <div class="cell">
            <i class="material-icons close">close</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
        </div>
        <div class="row">
          <div class="cell title">
            <p>Donec varius est ligula</p>
          </div>
          <div class="cell">
            <i class="material-icons close">close</i>
          </div>
          <div class="cell">
            <i class="material-icons close">close</i>
          </div>
          <div class="cell">
            <i class="material-icons close">close</i>
          </div>
          <div class="cell">
            <i class="material-icons">done</i>
          </div>
        </div>
      </div>
      <footer>
        <div class="row">
          <div class="cell">
            <p>Cena</p>
          </div>
          <div class="cell">
            <p>60 PLN</p>
          </div>
          <div class="cell">
            <p>70 PLN</p>
          </div>
          <div class="cell">
            <p>80 PLN</p>
          </div>
          <div class="cell">
            <p>90 PLN</p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import form1 from '@/components/themes/forms/form1.vue'


export default {
  name: 'Theme2Contact',
  components: {
    form1,
  }
}
</script>

<style scoped lang="scss">
  div.theme2-offer { min-height: 100vh; margin: 0; overflow: auto; }
  div.theme2-offer > header { background: #5041a0; padding: 150px; overflow: hidden; position: relative; }
  div.theme2-offer > header h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 500; color: #fff; text-align: center; }
  div.theme2-offer > header p { width: 600px; margin: 30px auto; padding: 0; font-size: 16px; color: rgba(255, 255, 255, 0.8); line-height: 25px; text-align: center; }
  div.circle { display: flex; justify-content: center; align-items: center; width: 180px; height: 180px; border-radius: 50%; background: rgb(108,52,163); background: linear-gradient(135deg, rgba(108,52,163,1) 0%, rgba(0,237,147,0.3) 100%);
    position: absolute; z-index: 2; }
  div.circle div.circle-small { width: 160px; height: 160px; border-radius: 50%; background: #5041a0; }
  div.circle.first { bottom: 100px; left: 50px; }
  div.circle.second { width: 80px; height: 80px; top: 100px; left: 400px; }
  div.circle.second div.circle-small { width: 65px; height: 65px; }
  div.circle.third { width: 100px; height: 100px; top: 30%; right: 100px; }
  div.circle.third div.circle-small { width: 80px; height: 80px; }

  div.boxes { display: flex; flex-wrap: wrap; justify-content: center; padding: 80px 0; }
  div.boxes div.box { width: 800px; margin: 20px; padding: 40px; box-shadow: 0 0 5px #5041a0; }
  div.boxes div.box h4 { margin: 0 0 10px 0; padding: 0; font-weight: 500; color: #5041a0; font-size: 28px; }
  div.boxes div.box p { margin: 0; padding: 0; font-size: 14px; color: rgba(0, 0, 0, 0.8); line-height: 25px;  }

  div.articles { background: #f6f4f9; }
  div.articles article { display: flex; justify-content: center; align-items: center; padding: 80px; }
  div.articles article img { display: block; width: 350px; height: 348px; margin-right: 40px; }
  div.articles article div.container { width: 1000px; margin-left: 40px; }
  div.articles article div.container h4 { margin: 0 0 20px 0; padding: 0; font-weight: 500; color: #5041a0; font-size: 32px;  }
  div.articles article div.container p { margin: 0; padding: 0; font-size: 14px; color: rgba(0, 0, 0, 0.8); line-height: 25px; }
  div.articles article:nth-child(even) img { order: 2; margin: 0 0 0 40px; }
  div.articles article:nth-child(even) div.container { margin: 0 40px 0 0; }


  div.pricetable { display: flex; flex-direction: column; width: 1000px; margin: 50px auto; }
  div.pricetable h4 { margin: 0 0 40px 0; padding: 0; font-size: 50px; font-weight: 400; color: #5041a0; text-align: center; }
  div.pricetable div.row { display: flex; }
  div.pricetable div.cell { display: flex; justify-content: center; align-items: center; width: 200px; min-height: 50px; padding: 10px; margin: 1px; background: #f6f4f9; }
  div.pricetable div.cell p { width: 100%; margin: 0; padding: 0; text-align: center; color: rgba(0, 0, 0, 0.8); }
  div.pricetable div.cell.title p { text-align: left; line-height: 25px; }
  div.pricetable div.cell i.material-icons { font-size: 24px; color: #5041a0 }
  div.pricetable div.cell i.material-icons.close { color: rgba(80,65,160,0.4); }

  header div.row div.cell:nth-child(2), footer div.row div.cell:nth-child(2) { background: #7661ED; }
  header div.row div.cell:nth-child(3), footer div.row div.cell:nth-child(3) { background: #5041a0;}
  header div.row div.cell:nth-child(4), footer div.row div.cell:nth-child(4) { background: #4250B8; }
  header div.row div.cell:nth-child(5), footer div.row div.cell:nth-child(5) { background: #3E69AD; }
  header div.row div.cell p, footer div.row div.cell p { color: #fff; }
  header div.row div.cell:nth-child(1) p, footer div.row div.cell:nth-child(1) p { color: rgba(0, 0, 0, 0.5); text-align: left; }

</style>
