<template>
  <section id="theme1-section2">
    <div class="imgs">
      <img src="../../../assets/cms1.webp">
      <img src="../../../assets/cms2.webp">
    </div>
    <article>
      <h3>Proin lorem lorem</h3>
      <p>Aenean auctor nulla sit amet erat dictum, at interdum nibh vehicula. Nunc non ullamcorper augue, ut interdum nunc. Nulla non sagittis turpis. Suspendisse condimentum magna ut auctor lobortis.</p>
      <ul>
        <li>Donec arcu ligula, blandit</li>
        <li>Mauris imperdiet, purus sed convallis pharetra</li>
        <li>Morbi semper ex quam, sit amet accumsan turpis dictum sit amet</li>
      </ul>
    </article>
  </section>
</template>

<script>
export default {
  name: 'Theme1Section2',
 
}
</script>

<style scoped lang="scss">
  section#theme1-section2 { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; padding: 100px 0; }

  div.imgs { width: 600px; height: 381px; margin: 0 50px; position: relative; }
  div.imgs img { display: block; position: absolute; top: 0; left: 0; box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6); }
  div.imgs img:nth-child(2) { top: 100px; left: 100px; }

  article { width: 600px; margin: 0 50px; }
  article h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 300; color: #0182e2; }
  article p { margin: 0; padding: 0; font-size: 18px; color: rgba(0, 0, 0, 0.5);; line-height: 30px; }
  article ul { margin: 30px 0 30px 25px; padding: 0; list-style: none; }
  article ul li { font-size: 18px; color: rgba(0, 0, 0, 0.5); line-height: 30px; }
  article ul li::before { content: "\2022";  color: #0182e2;  font-weight: bold;  display: inline-block;  width: 1em; margin-left: -1em;  font-size: 25px; }

  @media (max-width: 1300px) {
    h3 { font-size: 36px; }
  }

  @media (max-width: 700px) {
    section#theme1-section2 { padding: 50px 0; }
    article h3 { font-size: 36px; }
  }

  @media (max-width: 640px) {
    img { width: calc(100vw - 40px); margin: 0 auto; }
    article { width: calc(100vw - 40px); margin: 0 auto; }
    div.imgs { height: 250px; }
    div.imgs img:nth-child(2) { top: 10%; left: 10%; }
    div.imgs img { width: 100%; }
  }
</style>
