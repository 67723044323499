<template>
  <div class="form-wrapper">
    <div class="form-box">
      <div class="input-box" :class="{ error: checkError(1) }">
        <input type="text" required />
        <span class="label">Imię i nazwisko</span>
        <transition name="expand">
          <div class="error" v-if="checkError(1)">
            <i class="material-icons">error_outline</i>
            <span>Nieudana walidacja</span>
          </div>
        </transition>
      </div>
    </div>
    <div class="form-box">
      <div class="input-box" :class="{ error: checkError(2) }">
        <input type="text" required />
        <span class="label">Imię i nazwisko</span>
        <transition name="expand">
          <div class="error" v-if="checkError(2)">
            <i class="material-icons">error_outline</i>
            <span>Nieudana walidacja</span>
          </div>
        </transition>
      </div>
      <div class="input-box" :class="{ error: checkError(3) }">
        <input type="text" required />
        <span class="label">Imię i nazwisko</span>
        <transition name="expand">
          <div class="error" v-if="checkError(3)">
            <i class="material-icons">error_outline</i>
            <span>Nieudana walidacja</span>
          </div>
        </transition>
      </div>
    </div>
    <div class="form-box">
      <div class="input-box textarea" :class="{ error: checkError(7) }">
        <textarea cols="30" rows="10" required></textarea>
        <span class="label">Imię i nazwisko</span>
        <transition name="expand">
          <div class="error" v-if="checkError(7)">
            <i class="material-icons">error_outline</i>
            <span>Nieudana walidacja</span>
          </div>
        </transition>
      </div>
    </div>
    <div class="flex">
      <button>Wyślij</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Theme4FormComponent",
  data() {
    return {
      error: [1],
    };
  },
  methods: {
    checkError(index) {
      if (this.error.includes(index)) {
        return 1;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-box {
  width: 90%;
  display: flex;
  justify-content: center;
}
.input-box {
  width: 100%;
  margin: 10px 5px;
  position: relative;
}
.input-box input,
.input-box textarea {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.01);
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  border-radius: 2px;
  padding-left: 10px;
  outline: none;
}
.input-box textarea {
  resize: none;
  padding: 10px;
}
.input-box input {
  height: 40px;
  padding: 6px;
}
.input-box span.label {
  position: absolute;
  left: 8px;
  top: 12px;
  font-size: 13px;
  pointer-events: none;
  color: rgba(255, 255, 255, 0.5);
  transition: 0.2s ease all;
}
.input-box.textarea span.label {
  top: 10px;
  left: 10px;
}
.input-box input:focus ~ span.label,
.input-box input:not(:focus):valid ~ span.label,
.input-box textarea:focus ~ span.label,
.input-box textarea:not(:focus):valid ~ span.label {
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  top: -15px;
  padding: 0 3px;
}
.input-box div.error {
  color: red;
  font-weight: 500;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;
  overflow: hidden;
}
.input-box.textarea div.error {
  bottom: -2px;
}
.input-box div.error i.material-icons {
  font-size: 15px;
  margin-right: 3px;
}
.input-box.error input,
.input-box.error textarea {
  border-color: red;
}
.input-box.error input:focus ~ span.label,
.input-box.error input:not(:focus):valid ~ span.label,
.input-box.error textarea:focus ~ span.label,
.input-box.error textarea:not(:focus):valid ~ span.label {
  color: red;
}
.expand-enter-active,
.expand-leave-active {
  transition: all 0.1s linear;
  max-height: 16px;
}
.expand-enter,
.expand-leave-to {
  max-height: 0;
}

div.flex {
  display: flex;
  justify-content: flex-end;
  width: 90%;
  padding: 0 5px;
}
button {
  display: inline-block;
  width: 200px;
  margin: 10px 0 0 0;
  padding: 13px 0;
  text-align: center;
  background: transparent;
  border-radius: 2px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid rgba(255, 255, 255, 0.15);
}
button:hover {
  background: none;
  color: $Theme4Primary;
  border: 2px solid $Theme4Primary;
  cursor: pointer;
}
</style>
