<template>
  <div id="wrapper">
    <the-header></the-header>
    <router-view />
    <the-footer></the-footer>
    <the-nav-mobile></the-nav-mobile>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheNavMobile from '@/components/TheNavMobile.vue'

export default {
  name: 'Wrapper',
  components: {
    TheHeader,
    TheFooter,
    TheNavMobile
  }
}
</script>

<style scoped lang="scss">
  div#wrapper { margin: 0; }
</style>
