<template>
    <section class="theme4-contact">
        <div class="boxes-wrapper">
            <div class="left-box">
                <h3>Lorem Ipsum</h3>
                <ul>
                    <li>
                        <i class="material-icons">phone</i>
                        <p>+48 71 336 95 21</p>
                    </li>
                    <li>
                        <i class="material-icons">email</i>
                        <p>bok@afkcob.pl</p>
                    </li>
                    <li>
                        <i class="material-icons">place</i>
                        <p>ul. Świeradowska 51-57 , 50-559 Wrocław</p>
                    </li>
                </ul>
            </div>
            <div class="right-box">
                <theme4-form-component></theme4-form-component>
            </div>
        </div>
        <div class="map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.7708189388136!2d17.039452815754117!3d51.0757805795665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc2f9ae63608d%3A0xa80ec26f8880e6d0!2sBiuro%20Rachunkowe%20AFK%20Centrum%20Obs%C5%82ugi%20Biznesu!5e0!3m2!1spl!2spl!4v1617876451230!5m2!1spl!2spl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </section>
</template>

<script>
import Theme4FormComponent from './Theme4FormComponent';

export default {
    name: 'Theme4ContactComponent',
    components: {
        Theme4FormComponent,
    },
}
</script>

<style lang="scss" scoped>
    h3 {
        color: $Theme4Primary;
        margin-top: 0;
    }
    p {
        font-size: 20px;
        font-weight: 500;
        color: #fff;
    }
    section.theme4-contact {
        width: 100vw;
        min-height: 100vh;
        height: 1px;
        background: $Theme4Secondary;
        padding-top: 140px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    div.boxes-wrapper {
        width: 100%;
        height: 70%;
        min-height: 500px;
        display: flex;
        align-items: center;
    }
    div.boxes-wrapper div.left-box {
        width: 50%;
        margin-left: 5%;
    }
    div.boxes-wrapper div.left-box ul {
        list-style: none;
        padding: 0;
    }
    div.boxes-wrapper div.left-box li {
        display: flex;
        align-items: center;
        margin: 35px 0;
    }
    div.boxes-wrapper div.left-box li i {
        margin-right: 20px;
        font-size: 50px;
        color: $Theme4Primary;
    }
    div.boxes-wrapper div.right-box {
        width: 50%;
    }
    div.map {
        height: 30%;
        min-height: 250px;
        width: 100%;
    }
    @media (max-width: 1150px) {
        section.theme4-contact {
            min-height: initial;
            height: initial;
        }
        div.boxes-wrapper {
            flex-direction: column;
            width: 90%;
        }
        div.boxes-wrapper div.left-box {
            width: 90%;
            margin: 0;
        }
        div.boxes-wrapper div.right-box {
            width: 100%;
        }
        div.map {
            height: 250px;
            margin-top: 20px;
        }
    }
</style>
