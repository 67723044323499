<template>
  <div class="alert-wrapper" :class="{ client: alert.site == 'client' }" v-if="alert.visibility == 1">
    <div id="alert" :class="{ green: alert.type == 1, red: alert.type == 0, blue: alert.type == 2, client: alert.site == 'client' }" v-if="alert.visibility == 1">
        <div class="close" @click="close()">
          <i class="material-icons">close</i>
        </div>
      <p>{{ alert.msg }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data(){
    return{
      timeout: 0,
      visibility: 0,
    }
  },
  computed:{
    alert(){
      return this.$store.state.alert;
    },
  },
  watch:{
    alert:{
      deep: true,
      handler(){
        if(this.alert.visibility == 1){
          this.hideTime();
        }
      }
    },
  },
  methods:{
    close(){
      clearTimeout(this.timeout)
      this.$store.commit('toggleAlert', {
        visibility: 0,
        type: 0,
        msg: ''
      });
    },
    hideTime(){
      clearTimeout(this.timeout)
      if(this.alert.type != 2){
        self = this;
        this.timeout = setTimeout(function(){ 
          self.$store.commit('toggleAlert', {
            visibility: 0,
            type: 0,
            msg: ''
          });
        }, 3000);
      }
    },
  }
}
</script>

<style scoped lang="scss">
  div#alert { display: flex; flex-direction: column; justify-content: center; align-items: center; min-height: 35px; position: fixed; bottom: 30px; 
    left: 30px; z-index: 5; border-radius: 3px; }
  div#alert.green { background: $alertGreen; box-shadow: 0px 0px 4px $alertGreen; }
  div#alert.red { background: $alertRed; box-shadow: 0px 0px 4px $alertRed; }
  div#alert.blue { background: $alertBlue; box-shadow: 0px 0px 4px $alertBlue; }
  div#alert div.close { display: flex; justify-content: center; align-items: center; width: 20px; height: 20px; margin-left: auto; 
    cursor: pointer; position: absolute; right: 5px; border-radius: 50%; }
  div#alert div.close:hover { background: rgba(255, 255, 255, 0.5); }
  div#alert div.close i.material-icons { color: #fff; font-size: 18px; }
  div#alert div.close:hover i.material-icons { color: rgba(0, 0, 0, 0.5); }
  div#alert p { margin: -2.5px 0 0 0; padding: 5px 35px 5px 15px; text-align: center; font-weight: 300; font-size: 16px; color: #fff; }

  div.alert-wrapper.client {
    width: 100%;
    position: fixed;
    bottom: 30px;
    display: flex;
    justify-content: center;
  }
  div#alert.client {
    flex-direction: row-reverse;
    align-items: flex-start;
    position: initial;
    justify-content: start;
    padding: 10px;
  }
  div#alert.client p {
    padding: 0 35px 0 15px;
    font-weight: 500;
    font-size: 18px;
  }
  div#alert.client div.close {
    position: initial;
  }

  @media (max-width: 700px) {
    div#alert { max-width: calc(100vw - 115px); left: 25px; bottom: 25px; }
    div#alert p { font-size: 14px; }
  }
</style>
