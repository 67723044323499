<template>
  <div class="theme2-contact">
    <header>
      <h3>Formularz kontaktowy</h3>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id sollicitudin lectus. Proin rhoncus dui ut turpis imperdiet, in dignissim sem finibus. Aenean auctor nulla sit amet erat dictum.</p>
      <div class="circle first">
        <div class="circle-small"></div>
      </div>
      <div class="circle second">
        <div class="circle-small"></div>
      </div>
      <div class="circle third">
        <div class="circle-small"></div>
      </div>
    </header>
    <form1></form1>
    <div class="map">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.7708189388136!2d17.039452815754117!3d51.0757805795665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc2f9ae63608d%3A0xa80ec26f8880e6d0!2sBiuro%20Rachunkowe%20AFK%20Centrum%20Obs%C5%82ugi%20Biznesu!5e0!3m2!1spl!2spl!4v1617876451230!5m2!1spl!2spl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
  </div>
</template>

<script>
import form1 from '@/components/themes/forms/form1.vue'


export default {
  name: 'Theme2Contact',
  components: {
    form1,
  }
}
</script>

<style scoped lang="scss">
  div.theme2-contact { min-height: 100vh; margin: 0; overflow: auto; }
  header { background: #5041a0; padding: 150px; overflow: hidden; position: relative; }
  header h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 500; color: #fff; text-align: center; }
  header p { width: 600px; margin: 30px auto; padding: 0; font-size: 16px; color: rgba(255, 255, 255, 0.8); line-height: 25px; text-align: center; }
  div.circle { display: flex; justify-content: center; align-items: center; width: 180px; height: 180px; border-radius: 50%; background: rgb(108,52,163); background: linear-gradient(135deg, rgba(108,52,163,1) 0%, rgba(0,237,147,0.3) 100%);
    position: absolute; z-index: 2; }
  div.circle div.circle-small { width: 160px; height: 160px; border-radius: 50%; background: #5041a0; }
  div.circle.first { bottom: 100px; left: 50px; }
  div.circle.second { width: 80px; height: 80px; top: 100px; left: 400px; }
  div.circle.second div.circle-small { width: 65px; height: 65px; }
  div.circle.third { width: 100px; height: 100px; top: 30%; right: 100px; }
  div.circle.third div.circle-small { width: 80px; height: 80px; }
  div.map { width: 100vw; height: 200px; margin: 20px auto 0 auto; border-radius: 2px; opacity: 0.5; }
  div.map:hover { opacity: 1; }
</style>
