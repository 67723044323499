import Vue from 'vue'
import VueRouter from 'vue-router'

import Wrapper from '../views/Wrapper.vue'
import Home from '../views/Home.vue'
import Offer from '../views/Offer.vue'
import Portfolio from '../views/Portfolio.vue'
import Project from '../views/Project.vue'
import Contact from '../views/Contact.vue'
import Error404 from '../views/Error404.vue'

import Theme1Home from '../views/themes/theme1/Theme1Home.vue'

import Theme2Wrapper from '../views/themes/theme2/Theme2Wrapper.vue'
import Theme2Home from '../views/themes/theme2/Theme2Home.vue'
import Theme2Gallery from '../views/themes/theme2/Theme2Gallery.vue'
import Theme2Contact from '../views/themes/theme2/Theme2Contact.vue'
import Theme2Offer from '../views/themes/theme2/Theme2Offer.vue'

import Theme3Home from '../views/themes/theme3/Theme3Home.vue'

import Theme4Wrapper from '../views/themes/theme4/Theme4Wrapper.vue';
import Theme4Home from '../views/themes/theme4/Theme4Home.vue';
import Theme4Sections from '../views/themes/theme4/Theme4Sections.vue';
import Theme4Gallery from '../views/themes/theme4/Theme4Gallery.vue';
import Theme4Contact from '../views/themes/theme4/Theme4Contact.vue';

import AllElements from '../components/themes/AllElements.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/oferta',
        name: 'Offer',
        component: Offer,
      },
      {
        path: '/portfolio',
        name: 'Portfolio',
        component: Portfolio,
      },
      {
        path: '/projekt/:number',
        name: 'Project',
        component: Project,
      },
      {
        path: '/kontakt',
        name: 'Contact',
        component: Contact,
      },
      //TEST ROUTE
      {
        path: '/test',
        name: 'AllElements',
        component: AllElements
      },
    ]
  },
  //THEMES
  {
    path: '/szablony/onepage',
    name: 'Theme1Home',
    component: Theme1Home,
  },
  {
    path: '/szablony/onepage2',
    name: 'Theme3Home',
    component: Theme3Home,
  },
  {
    path: '/szablony/landingpage1',
    component: Theme2Wrapper,
    children: [
      {
        path: '',
        name: 'Theme2Home',
        component: Theme2Home,
      },
      {
        path: 'galeria',
        name: 'Theme2Gallery',
        component: Theme2Gallery,
      },
      {
        path: 'kontakt',
        name: 'Theme2Contact',
        component: Theme2Contact,
      },
      {
        path: 'oferta',
        name: 'Theme2Offer',
        component: Theme2Offer,
      }
    ]
  },
  {
    path: '/szablony/landingpage2',
    component: Theme4Wrapper,
    children: [
      {
        path: '',
        name: 'Theme4Home',
        component: Theme4Home,
      },
      {
        path: 'sekcje',
        name: 'Theme4Sections',
        component: Theme4Sections,
      },
      {
        path: 'galeria',
        name: 'Theme4Gallery',
        component: Theme4Gallery,
      },
      {
        path: 'kontakt',
        name: 'Theme4Contact',
        component: Theme4Contact,
      },
    ]
  },
  {
    path: '*',
    name: 'Error404',
    component: Error404,
  },
]

const router = new VueRouter({
  routes,
  mode: process.env.VUE_APP_ROUTER_MODE,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 }
  }
})

export default router
