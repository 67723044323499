<template>
    <div class="section-wrapper">
        <div class="image-wrapper">
            <div class="image"></div>
        </div>
        <div class="section-content">
            <div class="box-wrapper">
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">face</i>
                    </div>
                    <h4>Lorem ipsum dolor</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">face</i>
                    </div>
                    <h4>Lorem ipsum dolor</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">face</i>
                    </div>
                    <h4>Lorem ipsum dolor</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">face</i>
                    </div>
                    <h4>Lorem ipsum dolor</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">face</i>
                    </div>
                    <h4>Lorem ipsum dolor</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
                <div class="box">
                    <div class="icon">
                        <i class="material-icons">face</i>
                    </div>
                    <h4>Lorem ipsum dolor</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
            </div>
        </div>
        <div class="circle first"></div>
        <div class="circle second"></div>
        <div class="circle third"></div>
        <div class="circle fourth"></div>
    </div>
</template>

<script>
export default {
    name: 'Section5',
}
</script>

<style lang="scss" scoped>
    p {
        font-weight: 400;
        font-size: 13px;
        text-align: justify;
    }
    .section-wrapper {
        padding: 100px 0;
        width: 100vw;
        display: flex;
        align-items: center;
        position: relative;
    }
    .image-wrapper {
        border-top-right-radius: 400px;
        border-bottom-right-radius: 400px;
        background: linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
        width: 50%;
        height: 800px;
        margin-right: 50px;
        display: flex;
        align-items: center;
    }
    .image {
        border-top-right-radius: 400px;
        border-bottom-right-radius: 400px;
        width: 95%;
        height: 90%;
        background-image: url('https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
        background-position: 60% 30%;
        background-size: cover;
    }
    .section-content {
        width: 450px;
    }
    .box-wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    .box-wrapper .box {
        width: 200px;
        margin: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        box-shadow: 0 0 10px #e0e0e0;
        padding: 10px;
        border-radius: 20px;
        background-color: #fff;
    }
    .box-wrapper .icon {
        border-radius: 50%;
        background: linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
        width: 50px;
        height: 50px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .box-wrapper h4 {
        margin: 0;
    }
    .circle {
        background: rgba(#ff6a00, .7);
        border-radius: 50%;
        z-index: -1;
        position: absolute;
    }
    .circle.first {
        width: 400px;
        height: 400px;
        left: 80%;
        top: -10%;
    }
    .circle.second {
        width: 600px;
        height: 600px;
        left: 80%;
        top: 50%;
    }
    .circle.third {
        width: 100px;
        height: 100px;
        left: 60%;
        top: 5%;
    }
    .circle.fourth {
        width: 200px;
        height: 200px;
        left: 40%;
        top: 90%;
    }
    @media (max-width: 1000px) {
        .section-wrapper {
            flex-direction: column;
            align-items: initial;
        }
        .image-wrapper {
            width: 80%;
            height: 600px;
            margin-bottom: 70px;
        }
        .section-content {
            margin: 0 auto;
        }
    }
    @media (max-width: 500px) {
        .image-wrapper {
            height: 400px;
        }
        .section-content {
            width: 100vw;
        }
        .box-wrapper {
            flex-direction: column;
            align-items: center;
            width: 100vw;
        }
    }
</style>
