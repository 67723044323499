<template>
    <section class="theme4-section3">
        <div class="section-wrapper">
            <div class="mask">
                <div class="content">
                    <h3>Lorem ipsum dolor sit amet</h3>
                    <p>Sed interdum vitae libero ac eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus at ipsum consectetur, consequat elit eu, semper tellus.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Theme4Section3',
    
}
</script>

<style lang="scss" scoped>
    h3 {
        color: $Theme4Primary;
    }
    p {
        color: #fff;
    }
    div.section-wrapper {
        width: 100vw;
        height: 85vh;
        background-color: $Theme4Secondary;
        background-image: url('https://images.unsplash.com/photo-1600607688969-a5bfcd646154?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
        background-size: cover;
        background-position: center;
        position: relative;
    }
    div.section-wrapper div.mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($Theme4Secondary, .7);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.section-wrapper div.mask div.content {
        max-width: 60%;
        text-align: center;
    }
</style>
