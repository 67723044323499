<template>
  <section id="theme1-card">
    <div class="container">
      <h2>Lorem ipsum</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean id sollicitudin lectus. Proin rhoncus dui ut turpis imperdiet, in dignissim sem finibus.</p>
      <a href="#">Dowiedź się więcej</a>
    </div>
    <img src="../../../assets/themes/theme1/img1.png">
  </section>
</template>

<script>
export default {
  name: 'Card',
 
}
</script>

<style scoped lang="scss">
  section#theme1-card { min-height: calc(100vh); padding-top: 100px; display: flex; justify-content: center; align-items: center; position: relative; }
  section#theme1-card div.container { width: 500px; margin: -100px 50px 0 0; }
  section#theme1-card h2 { margin: 0 0 30px 0; padding: 0; color: #0182e2; font-size: 60px; font-weight: 400; }
  section#theme1-card p { margin: 0 0 30px 0; padding: 0; font-size: 16px; color: rgba(0, 0, 0, 0.5); line-height: 30px; }
  section#theme1-card a { display: inline-block; padding: 13px 18px; background: #00ed93; border-radius: 25px; text-decoration: none; color: #fff; text-transform: uppercase; letter-spacing: 1px; font-size: 12px; font-weight: 600;
    border: 2px solid #00ed93; }
  section#theme1-card a:hover { background: #fff; color: #00ed93; }
  section#theme1-card img { display: block; margin: -100px 0 0 50px; }

  @media (max-width: 1500px) {
    section#theme1-card div.container { margin: -100px 0 0 100px; }
    section#theme1-card img { width: 500px; }
  }

  @media (max-width: 1100px) {
    section#theme1-card { flex-wrap: wrap; }
    section#theme1-card div.container { width: calc(100vw - 40px); margin: 0 100px; }
    section#theme1-card img { width: 500px; }
  }
  @media (max-width: 1000px) {
    section#theme1-card div.container { margin: 0; }
    section#theme1-card img { margin: 50px 0; }
  }
  @media (max-width: 600px) {
    section#theme1-card h2 { font-size: 40px; }
    section#theme1-card img { width: calc(100vw - 40px); }
  }
</style>
