<template>
    <section class="faq1-wrapper">
        <h3>Najczęściej zadawane pytania (FAQ)</h3>
        <div class="qa-wrapper" v-for="(qa, index) in qaContent" :key="qa + index">
            <div class="question">
                <span>Q.</span>
                <p>{{ qa.question }}</p>
            </div>
            <div class="answer">
                <span>A.</span>
                <p>{{ qa.answer }}</p>
            </div>
        </div>
        <div class="circle first"></div>
        <div class="circle third"></div>
        <div class="circle fourth"></div>
    </section>
</template>

<script>
export default {
    name: 'Faq1',
    data() {
        return {
            qaContent:[{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
            },],
        }
    },
}
</script>

<style lang="scss" scoped>
    section {
        color: rgba(#000, .6);
        background: rgba(#ff6a00, .1);
    }
    p {
        font-weight: 400;
        font-size: 13px;
        text-align: justify;
        margin: 10px 20px;
        margin-left: 0;
    }
    h3 {
        font-size: 40px;
        font-weight: 600;
        color: #fff;
        text-shadow: 0 0 7px rgba(#000, .7);
    }
    .faq1-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
    }
    div.qa-wrapper {
        max-width: 1000px;
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        background-color: #fff;
        box-shadow: 0 0 5px gray;
    }
    div.qa-wrapper div.question,
    div.qa-wrapper div.answer {
        display: flex;
        align-items: center;
    }
    div.qa-wrapper div.question {
        // background-color: rgba(#ff6a00, .2);
        border-left: 5px solid #ee0979;
        border-bottom: 1px solid rgba(gray, .2);
    }
    div.qa-wrapper div.answer {
        // background-color: rgba(#ee0979, .2);
        border-left: 5px solid #ff6a00;
    }
    div.qa-wrapper div.question span,
    div.qa-wrapper div.answer span {
        font-size: 50px;
        font-weight: 500;
        margin: 10px;
        margin-right: 15px;
    }
    div.qa-wrapper div.question span {
        color: #ee0979;
    }
    div.qa-wrapper div.answer span {
        color: #ff6a00;
    }
    div.qa-wrapper div.question p {
        font-size: 15px;
        font-weight: 600;
    }
    div.qa-wrapper div.answer p {

    }
    .circle {
        background: linear-gradient(90deg, #ee0979 0%, #ff6a00 100%);
        border-radius: 50%;
        z-index: -1;
        position: absolute;
    }
    .circle.first {
        width: 600px;
        height: 600px;
        left: -340px;
        top: -300px;
    }
    .circle.third {
        width: 100px;
        height: 100px;
        left: 100px;
        top: 650px;
    }
    .circle.fourth {
        width: 200px;
        height: 200px;
        left: 600px;
        top: -80px;
    }
</style>
