<template>
    <section class="section6-wrapper">
        <div class="text">
            <h3>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h3>
            <p>
                Sed interdum vitae libero ac eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus at ipsum consectetur, consequat elit eu, semper tellus. Vivamus ante massa, dignissim vitae maximus at, auctor id arcu. 
            </p>
            <p>
                Sed interdum vitae libero ac eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Phasellus at ipsum consectetur, consequat elit eu, semper tellus. Vivamus ante massa, dignissim vitae maximus at, auctor id arcu. 
            </p>
        </div>
        <div class="images-wrapper">
            <div class="big-image">
                <img src="https://images.unsplash.com/photo-1563979026-05df925d8354?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=852&q=80">
            </div>
            <div class="small-images">
                <img src="https://images.unsplash.com/photo-1525351326368-efbb5cb6814d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80">
                <img src="https://images.unsplash.com/photo-1546039907-7fa05f864c02?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Section6'
}
</script>

<style lang="scss" scoped>
    h3 {
        font-size: 30px;
        text-align: center;
        font-weight: 600;
        letter-spacing: 1px;
    }
    p {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
    }
    section.section6-wrapper {
        padding: 50px 0;
    }
    div.text {
        width: 1050px;
        margin: 0 auto;
        padding: 0 5px;
    }
    div.images-wrapper {
        width: 100%;
        height: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.big-image {
        width: 700px;
        height: 700px;
        padding: 5px;
    }
    div.big-image img {
        width: 100%;
        height: 100%;
        background: chartreuse;
    }
    div.small-images {
        width: 350px;
        height: 700px;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    div.small-images img {
        width: 100%;
        height: 340px;
    }
    @media (max-width: 1000px) {
        div.text {
            width: 90%;
        }
        div.images-wrapper {
            margin-top: 40px;
            flex-direction: column;
            height: initial;
        }
        div.small-images {
            flex-direction: row;
            width: 700px;
            height: 350px;
        }
    }
    @media (max-width: 750px) {
        div.big-image {
            width: 400px;
            height: 400px;
        }
        div.small-images img {
            width: 400px;
            height: 400px;
            padding: 5px;
        }
        div.small-images {
            flex-direction: column;
            width: 100%;
            height: 800px;
            align-items: center;
            padding: 0;
        }
    }
    @media (max-width: 450px) {
        div.big-image {
            width: 250px;
            height: 250px;
        }
        div.small-images img {
            width: 250px;
            height: 250px;
        }
        div.small-images {
            height: 500px;
        }
    }
</style>
