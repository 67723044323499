import { render, staticRenderFns } from "./Theme3TheHeader.vue?vue&type=template&id=56081810&scoped=true&"
import script from "./Theme3TheHeader.vue?vue&type=script&lang=js&"
export * from "./Theme3TheHeader.vue?vue&type=script&lang=js&"
import style0 from "./Theme3TheHeader.vue?vue&type=style&index=0&id=56081810&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56081810",
  null
  
)

export default component.exports