<template>
    <div class="gallery-wrapper">
        <div class="box" v-for="(element, index) in gallery" :key="index + element" @click="toggleZoom(element.img)">
            <div class="image" :style="{ 'background-image': `url( ${ element.img } )`, height: photoHeight + 'px' }">
                <div class="mask">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur facilisis nunc justo, id aliquet felis pulvinar et.</p>
                </div>
            </div>
            <h4>{{ element.title }}</h4>
        </div>
        <div class="image-zoom" v-if="imageZoom" @click="toggleZoom('')">
            <div :style="{ 'background-image': `url( ${ imageZoom } )`, height: zoomPhotoHeight + 'px' }" @click.stop>
                <button class="material-icons" @click="toggleZoom('')">close</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Gallery3',
    data() {
        return {
            gallery: [{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },{
                title: 'Lorem ipsum dolor',
                img: 'https://images.unsplash.com/photo-1577980906127-4ea7faa2c6f0?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
            },],
            imageZoom: '',
            windowWidth: 0,
            photoHeight: null,
            zoomPhotoHeight: null,
        }
    },
    watch: {
        windowWidth() {
            this.setPhotoHeight();
        },
    },
    methods: {
        toggleZoom(img) {
            if(img) {
                document.body.style = "overflow-y: hidden";
            } else {
                document.body.style = "overflow-y: overlay";
            };
            this.imageZoom = img;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        setPhotoHeight() {
            if(this.windowWidth <= 650) {
                this.photoHeight = this.windowWidth * 0.9 * 0.5;
                this.zoomPhotoHeight = this.windowWidth * 0.9 * 0.5;
            } else if(this.windowWidth <= 1050) {
                this.zoomPhotoHeight = this.windowWidth * 0.9 * 0.5;
                this.photoHeight = 300;
            } else {
                this.photoHeight = 300;
                this.zoomPhotoHeight = 500;
            };
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    p {
        margin: 0;
        padding: 50px;
        font-size: 15px;
        font-weight: 500;
    }
    h4 {
        margin: 10px 0;
        font-size: 18px;
        font-weight: 500;
    }
    .gallery-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: -80px 0 50px 0;
        position: relative;
    }
    div.box {
        width: 600px;
        margin: 15px;
        background-color: #fff;
        border-radius: 3px;
        overflow: hidden;
        text-align: center;
        box-shadow: 0 0 5px gray;
    }
    div.box div.image {
        width: 100%;
        background-color: teal;
        background-size: cover;
        box-shadow: 0 0 7px gray;
        position: relative;
    }
    div.box div.mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(80,65,160,0.8);
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: opacity .3s;
    }
    div.box div.mask p {
        top: 50%;
        color: white;
        text-align: center;
    }
    div.box:hover {
        cursor: pointer;
    }
    div.box:hover > div.image div.mask {
        opacity: 1;
    }
    div.image-zoom {
        position: fixed;
        top: 0;
        background: rgba(#000, .8);
        width: 100vw;
        height: 100vh;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    div.image-zoom div {
        width: 1000px;
        display: flex;
        background-size: cover;
        justify-content: flex-end;
        align-items: flex-start;
    }
    div.image-zoom div button {
        color: #fff;
        background: rgba(#000, .5);
        border: none;
        padding: 0;
        border-radius: 50%;
        font-size: 30px;
        margin: 10px;
    }
    div.image-zoom div button:hover {
        cursor: pointer;
    }
    @media (max-width: 1050px) {
        div.image-zoom div {
            width: 90vw;
        }
    }
    @media (max-width: 650px) {
        div.box {
            width: 90vw;
        }
    }
</style>
