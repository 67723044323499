<template>
  <div class="portfolio">
    <div class="particles">
      <Particles
        id="particles3"
        :particlesInit="particlesInit"
        :particlesLoaded="particlesLoaded"
        :options="particles"
      />
    </div>
    <div class="wrapper">
      <div class="grid" :style="{ padding: cellMargin + 'px 0' }">
        <div class="row" v-for="(array, index1) in projects" :key="index1">
          <router-link
            :to="'/projekt/' + project.number"
            v-for="(project, index2) in array"
            :key="index2"
          >
            <div class="cell" :style="{ margin: cellMargin + 'px 0' }">
              <div
                class="photo"
                @click="setPhotoHeight"
                :style="{ height: photoHeight + 'px' }"
              >
                <transition name="fade">
                  <img
                    :src="require('@/assets/portfolio/' + project.img)"
                    v-show="project.imgLoaded"
                    :load="(project.imgLoaded = 1)"
                  />
                </transition>
                <div class="cover"></div>
                <p class="title">{{ project.title }}</p>
                <p class="more">{{ project.more }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Portfolio",
  data() {
    return {
      particlesInit: 0,
      particlesLoaded: 0,
      windowWidth: 0,
      photoHeight: null,
      cellMargin: null,
      projects: [
        [
          {
            title: "DoradzamyAuta",
            more: "Kliknij, żeby zobaczyć projekt",
            img: "doradzamyauta.png",
            imgLoaded: 0,
            number: 1,
          },
          {
            title: "AFK Wirtualne Biuro",
            more: "Kliknij, żeby zobaczyć projekt",
            img: "afkwirtualnebiuro.png",
            imgLoaded: 0,
            number: 2,
          },
        ],
        [
          {
            title: "NovaHolding",
            more: "Kliknij, żeby zobaczyć projekt",
            img: "novaholding.jpg",
            imgLoaded: 0,
            number: 3,
          },
        ],
      ],
    };
  },
  watch: {
    windowWidth() {
      this.setPhotoHeight();
      this.setCellMargin();
    },
  },
  methods: {
    setPhotoHeight() {
      if (this.windowWidth > 1200) {
        this.photoHeight = this.windowWidth * 0.4 * 0.5;
      } else {
        this.photoHeight = this.windowWidth * 0.86 * 0.5;
      }
    },
    setCellMargin() {
      this.cellMargin = this.windowWidth * 0.02;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    },
  },
};
</script>

<style scoped lang="scss">
div.portfolio {
  position: relative;
  min-height: calc(100vh - 40px);
  padding-top: 70px;
  background: rgb(0, 6, 35);
  background: linear-gradient(
    45deg,
    rgba(0, 6, 35, 1) 0%,
    rgba(0, 22, 62, 1) 15%,
    rgba(0, 38, 89, 1) 30%,
    rgba(0, 54, 112, 1) 45%,
    rgba(0, 78, 132, 1) 60%,
    rgba(0, 129, 168, 1) 75%,
    rgba(0, 206, 220, 1) 90%,
    rgba(0, 228, 236, 1) 100%
  );
}
div.wrapper {
  position: relative;
  z-index: 2;
}
a {
  display: block;
}
div.grid {
  display: flex;
  flex-direction: column;
}
div.row {
  display: flex;
  justify-content: space-between;
  margin: 0 4vw;
}
a:nth-child(odd) {
  margin-right: 2vw;
}
a:nth-child(even) {
  margin-left: 2vw;
}
div.photo {
  width: 44vw;
  background: transparent;
  overflow: hidden;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  box-shadow: 0 0 10px rgba(0, 0, 0, 8);
  border-radius: 2px;
}
div.photo img {
  width: 100%;
}
div.cover {
  width: 100%;
  height: calc(100% * 2);
  position: absolute;
  border-radius: 50%;
  transition: all 0.5s;
}
a:nth-child(odd) div.cover {
  background-color: rgba(0, 206, 220, 0.8);
  top: 40%;
  left: 50%;
}
a:nth-child(even) div.cover {
  background-color: rgba(0, 38, 89, 0.8);
  top: 40%;
  left: -50%;
}
div.photo p {
  color: #fff;
  font-size: 1.1vw;
  font-weight: 600;
  position: absolute;
  top: 75%;
  transition: all 0.2s;
}
a:nth-child(odd) div.photo p {
  right: 3%;
}
a:nth-child(even) div.photo p {
  left: 3%;
}
div.photo p.more {
  color: transparent;
  font-size: 0.8vw;
  top: 77%;
  font-weight: 200;
  transition: all 0.2s;
}
div.photo:hover > div.cover {
  transform: scale(3);
}
div.photo:hover > div.cover-second {
  transform: scale(3);
}
div.photo:hover > p.title {
  top: 65%;
  font-size: 1.4vw;
}
div.photo:hover > p.more {
  color: #fff;
}

@media (max-width: 1200px) {
  div.row {
    flex-direction: column;
  }
  div.photo {
    width: 92vw;
  }
  div.photo p {
    font-size: 3vw;
    top: 71%;
  }
  div.photo p.more {
    font-size: 1.5vw;
  }
  div.photo:hover > p.title {
    top: 61%;
    font-size: 4vw;
  }
  div.cover {
    width: 200%;
    height: calc(200% * 2);
  }
  a:nth-child(odd) {
    margin-right: 0;
  }
  a:nth-child(even) {
    margin-left: 0;
  }
  a:nth-child(odd) div.cover {
    top: 10%;
    left: 10%;
  }
  a:nth-child(even) div.cover {
    top: 10%;
    left: -110%;
  }
}
@media (max-width: 800px) {
  div.photo p {
    font-size: 4vw;
    top: 71%;
  }
  div.photo p.more {
    font-size: 2.5vw;
  }
  div.photo:hover > p.title {
    top: 55%;
    font-size: 6vw;
  }
}
</style>
