<template>
    <section class="theme4-gallery">
        <theme4-gallery-component></theme4-gallery-component>
        <theme-changer></theme-changer>
    </section>
</template>

<script>
import Theme4GalleryComponent from '@/components/themes/theme4/Theme4GalleryComponent.vue';

import ThemeChanger from '@/components/ThemeChanger.vue';

export default {
  name: 'Theme4Gallery',
  components: {
    ThemeChanger,
    Theme4GalleryComponent,
  }
}
</script>

<style scoped lang="scss">
  div.theme4-gallery { margin: 0; }
</style>
