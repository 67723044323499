<template>
  <div class="contact">
    <div class="particles">
      <Particles id="particles5" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
    <div class="wrapper">
      <div class="boxes">
        <div class="box">
          <h4>Dane kontaktowe:</h4>
          <div class="flex">
            <i class="material-icons">email</i>
            <h5>bok@afkcob.pl</h5>
          </div>
          <div class="flex">
            <i class="material-icons">smartphone</i>
            <h5>+48 71 336 95 21</h5>
          </div>
        </div>
        <div class="box">
          <h4>AFK Centrum Obsługi Biznesu Sp. z o.o.</h4>
          <div class="flex">
            <i class="material-icons">place</i>
            <h5>ul. Świeradowska 51-57 , 50-559 Wrocław</h5>
          </div>
          <div class="flex">
            <i class="material-icons">home</i>
            <h5>I piętro Centrum Handlowe Ferio Gaj</h5>
          </div>
        </div>
         <div class="box">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2506.7708189388136!2d17.039452815754117!3d51.0757805795665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fc2f9ae63608d%3A0xa80ec26f8880e6d0!2sBiuro%20Rachunkowe%20AFK%20Centrum%20Obs%C5%82ugi%20Biznesu!5e0!3m2!1spl!2spl!4v1617876451230!5m2!1spl!2spl" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        </div>
      </div>
      <div class="form">
        <h3>Wyślij zapytanie</h3>
        <div class="input">
          <h6>Imię i nazwisko</h6>
          <input type="text" placeholder="Imię i nazwisko" v-model="message.nameAndSurname" :class="{ error: validationField === 1 }">
        </div>
        <div class="input">
          <h6>Email</h6>
          <input type="text" placeholder="Email" v-model="message.email" :class="{ error: validationField === 2 }">
        </div>
        <div class="input">
          <h6>Telefon</h6>
          <input type="text" placeholder="Telefon (Opcjonalnie)" v-model="message.phone" :class="{ error: validationField === 3 }">
        </div>
        <div class="input">
          <h6>Zapytanie</h6>
          <textarea placeholder="Zapytanie" v-model="message.question" :class="{ error: validationField === 4 }"></textarea>
        </div>
        <div class="agreement">
          <div class="checkbox" @click="toggleAgreement()" :class="{ error: validationField === 5 }">
            <i class="material-icons" v-if="agreement">done</i>
          </div>
          <p>Wyrażam zgodę na przetwarzanie moich danych osobowych przez AFK Centrum Obsługi Biznesu Sp. z o.o. zgodnie z ustawą o ochronie danych osobowych w celu przedstawienia odpowiedzi na wysłane zapytanie.</p>
        </div>
        <p class="error">{{ validationText }}</p>
        <div class="flex">
          <button @click="sendMail()">Wyślij</button>
        </div>
      </div>
      <alert></alert>
    </div>
  </div>
</template>

<script>
import Alert from '../components/Alert.vue';

export default {
  name: 'Contact',
  components: {
    Alert,
  },
  data(){
    return{
      particlesInit: 0,
      particlesLoaded: 0,
      agreement: 0,
      message: {
        nameAndSurname: '',
        email: '',
        phone: '',
        question: ''
      },
      validationText: '',
      validationField: 0,
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    },
    api(){
      return this.$store.state.api;
    },
  },
  methods: {
    toggleAgreement(){
      if(this.agreement){
        this.agreement = 0;
      }else{
        this.agreement = 1;
        if(this.validationField === 5) {
          this.validationField = 0;
        };
      }
    },
    asd() {
      this.$store.commit('toggleAlert', {
        visibility: 1,
        type: 1,
        msg: 'Pomyślnie wysłano formularz',
        site: 'client'
      });
    },
    async sendMail() {
      if(!this.agreement) {
        this.validationText = 'Wymagane jest wyrażenie zgody';
        this.validationField = 5;
        return;
      };
      const self = this;
      await this.axios
      .post(this.api + 'mailing/', { message: this.message })
      .then(response => {
        if(response.data.status === 1) {
          self.validationText = '';
          self.validationField = 0;
          this.$store.commit('toggleAlert', {
            visibility: 1,
            type: 1,
            msg: 'Pomyślnie wysłano formularz',
            site: 'client'
          });
        } else {
          self.validationText = response.data.msg;
          self.validationField = response.data.validation;
        };
      });
    },
  },
}
</script>

<style scoped lang="scss">
  div.contact { position: relative; min-height: calc(100vh - 40px); padding-top: 70px; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  div.wrapper { position: relative; z-index: 2; min-height: calc(100vh - 40px - 70px); }

  div.boxes { display: flex; flex-wrap: wrap; justify-content: center; padding: 20px 0; }
  div.boxes div.box { margin: 20px; width: 400px; height: 300px; padding: 20px; border: 2px solid rgba(255, 255, 255, 0.1); border-radius: 2px; background: rgba(0,78,132,0.4); }
  div.boxes div.box div.flex { display: flex; margin: 20px 0; }
  div.boxes div.box i.material-icons { display: block; width: 24px; height: 24px; font-size: 23px; color: rgba(255, 255, 255, 0.3); }
  div.boxes div.box h4 { margin: 0 0 30px 0; padding: 0; color: #fff; font-weight: 400; font-size: 19px; line-height: 24px; }
  div.boxes div.box h5 { margin: 0 0 0 10px; padding: 0; color: rgba(255, 255, 255, 0.8); font-weight: 400; font-size: 16px; line-height: 24px; }

  div.form { width: 1280px; margin: 0 auto; overflow: hidden; }
  div.form h3 { margin: 0 0 40px 0; padding: 0; font-size: 30px; color: #fff; font-weight: 300; }
  div.form div.input { margin: 20px 0;}
  div.form h6 { margin: 0 0 10px 0; padding: 0; font-size: 16px; color: rgba(255, 255, 255, 0.5); font-weight: 400; text-shadow: 1px 1px rgba(0, 0, 0, 0.1); }
  div.form input { display: block; width: 100%; height: 40px; padding: 0 15px; border: 0; outline: 0; background: rgba(255, 255, 255, 0.1); border-radius: 2px; font-size: 15px; color: #fff; font-weight: 500; }
  div.form textarea { display: block; width: 100%; height: 200px; padding: 15px; border: 0; outline: 0; background: rgba(255, 255, 255, 0.1); border-radius: 2px; font-size: 15px; color: #fff; font-weight: 400; }
  div.form textarea::placeholder { font-weight: 500; }
  div.form div.agreement { display: flex; }
  div.form div.agreement div.checkbox { display: flex; justify-content: center; align-items: center; flex-shrink: 0; width: 20px; height: 20px; background: rgba(255, 255, 255, 0.1); border-radius: 2px; cursor: pointer; }
  div.form div.agreement div.checkbox i.material-icons { font-size: 16px; color: #fff; }
  div.form div.agreement p { margin: 0 0 0 10px; padding: 0; color: rgba(255, 255, 255, 0.5); font-size: 15px; line-height: 20px; }
  div.form div.flex { display: flex; justify-content: flex-end; }
  div.form button { display: inline-block; margin: 20px 0; padding: 15px 35px; color: #fff; text-decoration: none; text-transform: uppercase; letter-spacing: 2px; font-size: 12px; font-weight: 500; border-radius: 3px;
    border: 1px solid #fff; background: rgba(255, 255, 255, 0.1); cursor: pointer; }
  div.form button:hover { background: #fff; color: $blue; font-weight: 600; border: 1px solid $blue; transition: 0.5s; }

  div.form input.error { border: 1px solid red; }
  div.form textarea.error { border: 1px solid red; }
  div.form p.error {
    color: red;
    margin: 0;
    font-size: 15px;
    text-align: center;
  }
  div.form div.agreement div.checkbox.error { border: 1px solid red; }
  
  @media (max-width: 1300px) {
    div.form { width: 100%; }
    div.form h3 { margin: 20px 20px 40px 20px; }
    div.form div.input { width: calc(100% - 40px); margin: 20px auto; }
    div.form div.agreement { width: calc(100% - 40px); margin: 0 auto; }
    div.form div.flex { width: calc(100% - 40px); margin: 0 auto; }
  }

  @media (max-width: 440px) {
    div.boxes div.box:nth-child(1),  div.boxes div.box:nth-child(2) { height: initial; }
    div.boxes div.box { width: calc(100% - 40px); margin: 10px 20px; }
  }

</style>
