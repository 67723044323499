<template>
    <div class="gallery-wrapper">
        <h3>Lorem ipsum</h3>
        <div class="boxes-wrapper">
            <div class="box" v-for="(element, index) in gallery" :key="index + element">
                <div class="image" :style="{ 'background-image': `url( ${ element.img } )`, height: photoHeight + 'px' }"></div>
                <div class="text">
                    <h4>{{ element.title }}</h4>
                    <p>{{ element.content }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Gallery1',
    data() {
        return {
            gallery: [{
                title: 'Lorem ipsum',
                content: '$ 9.99',
                img: 'https://images.unsplash.com/photo-1464219551459-ac14ae01fbe0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            },{
                title: 'Lorem ipsum',
                content: '$ 9.99',
                img: 'https://images.unsplash.com/photo-1485704686097-ed47f7263ca4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1369&q=80'
            },{
                title: 'Lorem ipsum',
                content: '$ 9.99',
                img: 'https://images.unsplash.com/photo-1544068192-4c4af19868c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80'
            },{
                title: 'Lorem ipsum',
                content: '$ 9.99',
                img: 'https://images.unsplash.com/photo-1519699788450-ad34386a3bfc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80'
            },{
                title: 'Lorem ipsum',
                content: '$ 9.99',
                img: 'https://images.unsplash.com/photo-1519624014191-508652cbd7b5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1489&q=80'
            },{
                title: 'Lorem ipsum',
                content: '$ 9.99',
                img: 'https://images.unsplash.com/photo-1519624213695-6819a985fb0b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80'
            },{
                title: 'Lorem ipsum',
                content: '$ 9.99',
                img: 'https://images.unsplash.com/photo-1613482084286-41f25b486fa2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1267&q=80'
            },{
                title: 'Lorem ipsum',
                content: '$ 9.99',
                img: 'https://images.unsplash.com/photo-1565290538967-a18f519b6cae?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            },],
            windowWidth: 0,
            photoHeight: null,
        }
    },
    watch: {
        windowWidth() {
            this.setPhotoHeight();
        },
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        setPhotoHeight() {
            if(this.windowWidth <= 650) {
                this.photoHeight = this.windowWidth * 0.9 * ( 2 / 3 );
            } else {
                this.photoHeight = 400;
            };
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    h3 {
        margin: 0;
        margin-bottom: 50px;
        font-size: 50px;
        letter-spacing: 1px;
    }
    h4 {
        margin: 0;
        font-size: 20px;
        margin-bottom: 10px;
        text-align: center;
    }
    p {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
        text-align: center;
    }
    .gallery-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 0;
    }
    div.boxes-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    div.box {
        width: 600px;
        margin: 15px;
        background-color: #fff;
    }
    div.box div.image {
        background-color: #000;
        background-size: cover;
    }
    div.box div.text {
        padding: 15px;
    }
    @media (max-width: 650px) {
        div.box {
            width: 90vw;
        }
    }
</style>
