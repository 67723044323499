<template>
  <div class="home">
    <home-card></home-card>
    <home-offer></home-offer>
    <home-identification></home-identification>
    <home-internet></home-internet>
    <home-features></home-features>
    <home-cms></home-cms>
    <home-technologies></home-technologies>
  </div>
</template>

<script>
import HomeCard from '@/components/home/HomeCard.vue'
import HomeOffer from '@/components/home/HomeOffer.vue'
import HomeIdentification from '@/components/home/HomeIdentification.vue'
import HomeInternet from '@/components/home/HomeInternet.vue'
import HomeCms from '@/components/home/HomeCms.vue'
import HomeTechnologies from '@/components/home/HomeTechnologies.vue'
import HomeFeatures from '@/components/home/HomeFeatures.vue'

export default {
  name: 'Home',
  components: {
    HomeCard,
    HomeOffer,
    HomeIdentification,
    HomeInternet,
    HomeCms,
    HomeTechnologies,
    HomeFeatures,
  }
}
</script>

<style scoped lang="scss">
  div.home { margin: 0; }
</style>
