<template>
    <section class="carousel1-wrapper">
        <carousel-1-wrapper class="carousel" :style="{ height: imgHeight + 'px' }">
            <carousel-1-slide v-for="(slide, index) in slides"
                class="carousel-slider"
                :key="slide" 
                :index="index"
                :visibleSlide="visibleSlide"
                :direction="direction"
            >
                <img :src="slide" />
            </carousel-1-slide>
            <button class="next" @click="next()">
                <i class="material-icons">expand_more</i>
            </button>
            <button class="prev" @click="prev()">
                <i class="material-icons">expand_more</i>
            </button>
            <div class="circles">
                <div v-for="(circleSlide, circleIndex) in slides" 
                    class="circle" 
                    :class="{ active: circleIndex === visibleSlide }"  
                    :key="'circle' + circleSlide"
                    @click="changeVisibleSlide(circleIndex)"
                ></div>
            </div>
        </carousel-1-wrapper>
    </section>
</template>

<script>
import Carousel1Slide from './Carousel1Slide';
import Carousel1Wrapper from './Carousel1Wrapper';

export default {
    name: 'Carousel1',
    components: {
        Carousel1Slide,
        Carousel1Wrapper
    },
    data() {
        return {
            slides: [
                'https://images.unsplash.com/photo-1579958837693-a5fffd0a9847?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
                'https://images.unsplash.com/photo-1526653662218-3ab1e4d6ec2a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80',
                'https://images.unsplash.com/photo-1580150083503-b69bbabc96c3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80',
                'https://images.unsplash.com/photo-1544121378-0326a232266b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1350&q=80',
                'https://images.unsplash.com/photo-1617357790396-0ea69ab0738c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            ],
            visibleSlide: 0,
            direction: 'left',
            windowWidth: 0,
            imgHeight: 0,
            imgScale: {
                width: 16,
                height: 9
            },
        }
    },
    methods: {
        next() {
            if(this.visibleSlide >= this.slides.length - 1) {
                this.visibleSlide = 0;
            } else {
                this.visibleSlide++;
            };
            this.direction = 'left';
        },
        prev() {
            if(this.visibleSlide <= 0) {
                this.visibleSlide = this.slides.length - 1;
            } else {
                this.visibleSlide--;
            };
            this.direction = 'right';
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        getImgHeight() {
            this.imgHeight = this.windowWidth / this.imgScale.width * this.imgScale.height;
        },
        changeVisibleSlide(index) {
            if(this.visibleSlide > index) {
                this.direction = 'right';
            } else {
                this.direction = 'left';
            };
            this.visibleSlide = index;
        },
    },
    watch: {
        windowWidth() {
            this.getImgHeight();
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    section.carousel1-wrapper {
        width: 100vw;
        max-height: 100vh;
        overflow: hidden;
    }
    section.carousel1-wrapper .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        max-height: 100vh;
    }
    section.carousel1-wrapper .carousel-slider {
        position:absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    section.carousel1-wrapper img {
        width: 100%;
        height: 100%;
    }
    section.carousel1-wrapper button {
        position: absolute;
        background: rgba(#000, .5);
        color: #fff;
        font-size: 0;
        border: none;
        padding: 5px;
        top: calc(50% - 30px);
    }
    section.carousel1-wrapper button {
        cursor: pointer;
    }
    section.carousel1-wrapper i {
        font-size: 50px;
    }
    section.carousel1-wrapper button.next {
        right: 8px;
    }
    section.carousel1-wrapper button.next i {
        transform: rotate(-90deg);
    }
    section.carousel1-wrapper button.prev i {
        transform: rotate(90deg);
    }
    section.carousel1-wrapper div.circles {
        position: absolute;
        bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    section.carousel1-wrapper div.circles div.circle {
        width: 15px;
        height: 15px;
        // background-color: rgba(#fff, .5);
        border: 2px solid rgba(#fff, .5);
        margin: 3px;
        border-radius: 50%;
    }
    section.carousel1-wrapper div.circles div.circle:hover {
        cursor: pointer;
    }
    section.carousel1-wrapper div.circles div.circle.active {
        background-color: #fff;
    }
</style>
