<template>
  <div class="home-card">
    <div class="particles">
      <Particles id="particles1" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
    <div class="flex">
      <div class="container">
        <h2>Tworzymy dla Ciebie</h2>
        <p>Zajmujemy się tworzeniem stron internetowych, aplikacji WWW oraz rozmaitych projektów graficznych. Chcesz przyciągnąć potencjalnych klientów, budować swój wizerunek lub ułatwić z nimi kontakt? Napisz do nas. Poznamy Twoje potrzeby i przygotujemy indywidualną ofertę dla Ciebie.</p>
        <div class="flex2">
          <a href="#" v-scroll-to="{
            el: '#offer',
            duration: 0,
            offset: -140,        
          }">
            sprawdź
          </a>
        </div>
      </div>
      <transition name="fade">
        <img src="../../assets/card.webp" alt="Infografika" v-show="staticImg" :load="staticImg = 1">
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCard',
  data(){
    return{
      particlesInit: 0,
      particlesLoaded: 0,
      staticImg: 0,
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    }
  }
}
</script>
<style scoped lang="scss">
  div.home-card { width: 100vw; min-height: 100vh; position: relative; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%);
    box-shadow: 0 0 5px #000;}

  div.flex { display: flex; flex-wrap: wrap; justify-content: center; align-items: center; width: 100vw; height: calc(100vh - 70px); position: absolute; top: 70px; left: 0; z-index: 2; }
  div.flex div.container { width: 600px; height: 400px; margin-right: 30px; }
  div h2 { margin: 0 0 20px 0; padding: 0; font-weight: 300; color: #fff; font-size: 55px; }
  div p { margin: 0 0 40px 0; padding: 0; color: rgba(255, 255, 255, 0.8); line-height: 25px; font-weight: 400; font-size: 16px; text-align: justify;}
  div a { display: inline-block; padding: 15px 35px; color: #fff; text-decoration: none; text-transform: uppercase; letter-spacing: 2px; font-size: 12px; font-weight: 500; border-radius: 3px;
    border: 1px solid #fff; background: rgba(255, 255, 255, 0.1); animation: pulse 3s infinite; }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    }
    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }
  div a:hover { background: #fff; color: $blue; font-weight: 600; border: 1px solid $blue; }
  img { width: 1013px; height: 878px; margin-left: 30px; }
  @media (max-width: 1700px) {
    img { width: 713px; height: 618px;  }
  }
  @media (max-width: 1400px) {
    img { width: 613px; height: 531px; }
  }
  @media (max-width: 1300px) {
    img { width: 500px; height: 433px; }
  }
  @media (max-width: 1100px) {
    div.flex div.container { order: 2; }
    img { order: 1; }
    div h2 { text-align: center; }
    div p { text-align: center; }
    div.flex2 { display: flex; justify-content: center; }
  }
  @media (max-width: 700px) {
    img { width: 400px; height: 347px; }
  }
 @media (max-width: 640px) {
    div.flex div.container { width: calc(100vw - 40px); margin: 0 auto; }
    div h2 { font-size: 40px; }
    div p { font-size: 15px; }
  }
  @media (max-width: 440px) {
    img { width: 300px; height: 260px; }
  }
</style>
