<template>
  <div class="home-features">
    <div class="particles">
      <Particles id="particles2" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
    <div class="container">
      <article class="content">
        <h4>Aplikacje WWW - funkcjonalności</h4>
      </article>
      <div class="wrapper">
        <div class="big-circle">
          <article>
            <transition name="slide-fade" mode="out-in">
              <h1 :key="display.title">{{ display.title }}</h1>
            </transition>
            <transition name="slide-fade" mode="out-in">
              <p :key="display.text">{{ display.text }}</p>
            </transition>
          </article>
        </div>
        <div class="small-circle" 
          v-for="(feature, index) in features" 
          :key="index+feature.title" 
          @click="pickFeature(feature)"
          :class="{ picked: feature.title === display.title }"
        >
          <div class="circle" v-show="feature.title === display.title"></div>
          <i class="material-icons">{{ feature.icon }}</i>
          <span>{{ feature.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCard',
  data() {
    return {
      features: [
        {
          title: 'Bezpieczeństwo',
          icon: 'verified_user',
          text: 'Bezpieczeństwo jest jednym z kluczowych aspektów przy tworzeniu aplikacji www oraz stron internetowych. Dokładamy wszelkich starań, aby nasze strony były możliwie jak najbardziej bezpieczne. SSL jest na obecną chwilę standardem szyfrowania danych w internecie, bez niego strona w nowoczesnych przeglądarkach jest specjalnie oznaczana co może odstraszać potencjalnych uzytkowników.'
        },
        {
          title: 'Responsywność',
          icon: 'phonelink',
          text: 'Każda zbudowana przez nas strona jest responsywna. To znaczy, że jej układ automatycznie dostosowuje się do wielkości ekranu urządzenia, a wyświetlane treści skalują się tak, aby były one wygodne w odbiorze dla użytkownika. Strony responsywne cechuje to, że dobrze wyświetlają się na urządzeniach mobilnych, tabletach, laptopach czy desktopach.'
        },
        {
          title: 'Lekkość',
          icon: 'flight_takeoff',
          text: 'Nasze strony charakteryzują się krótkim czasem wczytywania oraz szybką reakcją na działania użytkownika. Dodatkowo tam gdzie jest to opłacalne używamy obrazów w formacie WebP, aby zmniejszyć ich wagę, dzięki czemu szybciej się wczytują.'
        },
        {
          title: 'Nowoczesne technologie',
          icon: 'fiber_new',
          text: 'Technologie do tworzenia stron oraz aplikacji www bardzo szybko się rozwijają. Ważnym jest, aby śledzić na bieżąco rozwój nowych technologii jak i zmiany w tych, które są już z nami jakiś czas. Dzięki temu możemy wybrać narządzia, które dostarczą najlepsze rozwiązania.'
        },
        {
          title: 'SEO Friendly',
          icon: 'find_in_page',
          text: 'W naszych projektach stawiamy duży nacisk na prawidłowe budowanie struktury aplikacji zgodnie z najnowszymi standardami. Skutkuje to tym, że wyszukiwarki internetowe dużo lepiej i szybciej zaindeksują taką stronę www, co może wpłynąć pozytywnie na wyższe pozycje w wynikach wyszukiwania.'
        },
        {
          title: 'REST API',
          icon: 'compare_arrows',
          text: 'REST(Representational state transfer - zmiana stanu poprzez reprezentacje) jest to styl architektury oprogramowania dla systemów rozproszonych. Wykorzystanie go umożliwia odseparowanie interfejsu użytkownika od operacji na serwerze, co z kolei pozwala na wykorzystanie kodu serwera nie tylko dla stron internetowych ale także dla aplikacji mobilnych oraz desktopowych.'
        },
        {
          title: 'SPA (Single Page Application)',
          icon: 'description',
          text: 'Wszystkie nasze strony budujemy jako Single Page Application(tłumacząc dosłownie - jednostronnicowa aplikacja internetowa). Nie oznacza to jednak, że SPA nie posiada podstron. Od zwykłej strony internetowej rożni się tylko tym, że ma tylko jeden plik html, dzieki czemu strona nie musi przeładowywać się w trakcie użytkowania. Głównymi zaletami SPA są mniejsza zasobożerność oraz bardziej płynna i interaktywna strona.'
        },
        {
          title: 'Indywidualny design',
          icon: 'brush',
          text: 'Dla osób chcących strony skrojonej pod siebie proponujemy projekty indywidualne tworzone przy współpracy z grafikiem.'
        },
        {
          title: 'Czynności techniczne',
          icon: 'build',
          text: 'Na potrzeby projektu zajmiemy się takimi czynnościami jak: konfiguracja domen, generacja certyfikatów SSL, zakładanie baz danych oraz umieszczenie aplikacji/strony na hostingu.'
        }
      ],
      display: {
        title: '',
        text: '',
      },
      particlesInit: 0,
      particlesLoaded: 0,
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    }
  },
  created() {
    this.display.title = this.features[0].title;
    this.display.text = this.features[0].text;
  },
  methods: {
    pickFeature(feature) {
      this.display.title = feature.title;
      this.display.text = feature.text;
    },
  },
}
</script>
<style scoped lang="scss">
  div.home-features {
    width: 100vw; 
    height: 900px; 
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%);
    box-shadow: 0 0px 5px #000;
  }
  div.container { display: flex; justify-content: center; flex-direction: column; width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 2; }
  article.content {
    color: white;
    
  }
  article.content h4 {
    font-size: 200%;
    font-weight: 300;
    text-align: center;
  }
  .wrapper {
    // position: absolute;
    margin: -150px auto 0 auto;
    width: 800px;
    height: 800px;
    border-radius: 50%;
    div:nth-child(2) {
      top: -7.5%;
    }
    div:nth-child(3) {
      top: -6.2%;
      left: 3.2%;
    }
    div:nth-child(4) {
      top: -7.5%;
      left: 12.5%;
    }
    div:nth-child(5) {
      top: -13.3%;
      left: 26.23%;
    }
    div:nth-child(6) {
      top: -25%;
      left: 42.5%;
    }
    div:nth-child(7) {
      top: -43.2%;
      left: 58.8%;
    }
    div:nth-child(8) {
      top: -67.45%;
      left: 72.55%;
    }
    div:nth-child(9) {
      top: -96.25%;
      left: 81.72%;
    }
    div:nth-child(10) {
      top: -127.5%;
      left: 85%;
    }
  }
  .big-circle {
    width: 50%;
    height: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    position: relative;
    left: 25%;
    top: 25%;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
  .big-circle article {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .big-circle article h1 {
    width: 80%;
    text-align: center;
    font-size: 150%;
    font-weight: 300;
  }
  .big-circle article p {
    width: 80%;
    text-align: center;
    font-size: 100%;
    
  }
  .small-circle {
    width: 15%;
    height: 15%;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.3);
    transition: all .3s ease-in-out;
    &:hover {
      color: rgba(255, 255, 255, 0.9);
      cursor: pointer;
      transform: scale(1.05);
    }
    &.picked {
      transform: scale(1.05);
      color: rgba(255, 255, 255, 0.9);
    }
  }
  .small-circle .circle {
    display: block;
    width: 105%;
    height: 105%;
    border-radius: 50%;
    position: absolute;
    background-clip: content-box;
    animation: spin 20s linear infinite;
    border: 1px dashed rgba(255, 255, 255, 0.9);
  }
  .small-circle i {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    font-size: 300%;
  }
  .small-circle span {
    text-align: center;
    font-size: 80%;
  }
  @keyframes spin { 
    100% { 
      transform: rotateZ(360deg);
    }
  }
  .slide-fade-enter-active {
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .3s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: scale(0.95);
    opacity: 0;
  }
  @media(max-width: 1000px) {
    div.home-features {
      height: initial;
      position: initial;
    }
    div.container {
      position: initial;
      padding: 25px 0;
    }
    article.content h4 {
      margin-top: 0;
    }
    .big-circle {
      width: 94%;
      margin: 0;
      border-radius: 2%;
      position: initial;
      margin-bottom: 1%;
    }
    div.small-circle {
      width: 30%;
      margin: 1% 1%;
      position: initial;
      border-radius: 2%;
      justify-content: center;
      &.picked {
        border: 1px solid rgba(255, 255, 255, 0.9);
      }
    }
    .small-circle .circle {
      display: none;
    }
    div.wrapper {
      max-width: 100vw;
      margin: 0;
      min-width: 100vw;
      padding: 0 3vh;
      height: 70vh;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      div {
        
      }
      div:nth-child(1) {

      }
    }
    .big-circle article h1 {
      font-size: 140%;
      margin-bottom: 5px;
    }
    .big-circle article p {
      font-size: 80%;
      margin-top: 0;
    }
    .small-circle i {
      font-size: 150%;
      align-items: flex-end;
    }
    .small-circle span {
      font-size: 50%;
    }
  }
</style>
