<template>
    <header class="theme3-mobile-nav">
        <div class="button-wrapper">
            <button class="nav-button" :class="{ animate: animation }" @click="animateButton">
                <i class="material-icons">menu</i>
            </button>
            <transition
                @before-enter="beforeTransition"
                @enter="enter"
                @after-enter="afterTransition"
                
                @before-leave="beforeTransition"
                @leave="leave"
                @after-leave="afterTransition" 
            >
                <div class="menu" v-if="opened">
                    <ul>
                        <li @click="scrollToElement('carousel-4')" :class="{ active: activeSection == 'carousel-4' }"><span>Slider</span></li>
                        <li @click="scrollToElement('section-6')" :class="{ active: activeSection == 'section-6' }"><span>Sekcja 1</span></li>
                        <li @click="scrollToElement('section-7')" :class="{ active: activeSection == 'section-7' }"><span>Sekcja 2</span></li>
                        <li @click="scrollToElement('gallery-1')" :class="{ active: activeSection == 'gallery-1' }"><span>Galeria</span></li>
                        <li @click="scrollToElement('form-2')" :class="{ active: activeSection == 'form-2' }"><span>Formularz</span></li>
                        <li @click="scrollToElement('theme3-contact')" :class="{ active: activeSection == 'theme3-contact' }"><span>Kontakt</span></li>
                    </ul>
                </div>
            </transition>
            <div class="menu-mask" v-if="opened" @click="opened = false"></div>
        </div>
    </header>
</template>

<script>
const raf = window.requestAnimationFrame;

export default {
    name: 'Theme3MobileNav',
    data() {
        return {
            headerFixed: 0,
            scrollListener: 0,
            animation: false,
            opened: false,
        }
    },
    computed: {
        activeSection() {
            return this.$store.state.onePage2ActiveSection;
        },
    },
    methods: {
        animateButton() {
            this.animation = true;
            this.opened = !this.opened
            setTimeout(() => { this.animation = false;  }, 400);
        },
        scrollToElement(id) {
            const element = document.getElementById(id);
            const offset = 0;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - offset + window.pageYOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
            if(this.opened) {
                this.opened = false;
            };
        },
        nextFrame (fn) {
            raf(() => {
                raf(fn);
            });
        },
        beforeTransition (el) {
            el.classList.add('height-transition');
        },
        enter (el, done) {
            const height = el.clientHeight;

            el.style['max-height'] = `0px`;

            this.nextFrame(() => {
                el.style['max-height'] = `${height + 5}px`;
            });
        },
        leave (el) {
            el.style['max-height'] = `${el.clientHeight}px`;

            this.nextFrame(() => {
                el.style['max-height'] = `0px`;
            });
        },
        afterTransition (el) {
            el.classList.remove('height-transition');
            el.style['max-height'] = '';
        },
    },
    created() {
        let self = this;
        this.scrollListener = window.addEventListener("scroll", function () {
        if (document.documentElement.scrollTop > 0) {
            self.headerFixed = 1;
        } else {
            self.headerFixed = 0;
        }
        });
        if (document.documentElement.scrollTop > 0) {
            self.headerFixed = 1;
        } else {
            self.headerFixed = 0;
        };
  },
  destroyed() {
    window.removeEventListener("scroll");
  },
}
</script>

<style lang="scss" scoped>
    header.theme3-mobile-nav {
        z-index: 10;
        position: fixed;
        bottom: 1%;
        right: 1%;
        display: none;
    }
    div.button-wrapper {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    button.nav-button {
        z-index: 12;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #f5af19;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    button.nav-button.animate {
        animation: pulse .4s ease-in-out;
    }
    button.nav-button i {
        color: #fff;
        font-size: 28px;
    }
    div.button-wrapper div.menu {
        position: fixed;
        display: flex;
        justify-content: center;
        width: 100vw;
        background: #000;
        left: 0;
        bottom: 0;
        z-index: 11;
        border-top: 1px solid #f5af19;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        color: #fff;
        text-transform: lowercase;
        font-weight: 600;
        text-align: center;
    }
    ul li {
        padding: 15px 30px;
    }
    ul li span {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }
    ul li.active span {
        color: #f5af19;
    }
    div.menu-mask {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }
    .height-transition {
        transition: all .5s ease;
    }
    @media (max-width: 1000px) {
        header.theme3-mobile-nav {
            display: initial;
        }
    }
    @keyframes pulse {
        0% {
            width: 100%;
            height: 100%;
        }
        50% {
            width: 90%;
            height: 90%;
        }
        100% {
            width: 100%;
            height: 100%;
        }
    }
</style>
