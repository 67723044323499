<template>
  <section class="theme4-home-card">
      <div class="home-wrapper">
          <div class="mask">
              <div class="content">
                <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
                <p>Sed interdum vitae libero ac eleifend. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.</p>
                <button @click="scrollToElement('theme4-section1')">dowiedz się więcej</button>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: 'Theme4HomeCard',
  methods: {
      scrollToElement(id) {
        const element = document.getElementById(id);
        const offset = 70;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset + window.pageYOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    },
  }
}
</script>

<style scoped lang="scss">
    h3 {
        color: $Theme4Primary;
    }
    p {
        color: #fff;
        margin-bottom: 50px;
    }
    section.theme4-home-card {

    }
    div.home-wrapper {
        width: 100vw;
        height: 100vh;
        background-color: $Theme4Secondary;
        background-image: url('https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
        background-size: cover;
        background-position: center;
        position: relative;
    }
    div.home-wrapper div.mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($Theme4Secondary, .7);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
    div.home-wrapper div.mask div.content {
        margin-left: 10%;
        width: 40%;
    }
    div.home-wrapper div.mask div.content button {
        background: transparent;
        outline: none;
        color: #fff;
        border: 1px solid #fff;
        padding: 10px;
        font-size: 20px;
        font-weight: 300;
        letter-spacing: 1px;
        transition: all .1s linear;
        animation: pulse 3s infinite;
    }
    div.home-wrapper div.mask div.content button:hover {
        cursor: pointer;
        border-color: $Theme4Primary;
        color: $Theme4Primary;
    }
    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba(#fff, 0.6);
        }
        50% {
            box-shadow: 0 0 0 8px rgba(#fff, 0);
        }
        100% {
            box-shadow: 0 0 0 0 rgba(#fff, 0);
        }
    }
    @media (max-width: 1000px) {
        div.home-wrapper div.mask {
            align-items: center;
        }
        div.home-wrapper div.mask div.content {
            width: 90%;
            margin: 0;
        }
    }
</style>
