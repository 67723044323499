<template>
  <div class="home-cms">
    <transition name="fade">
    <div class="imgs" v-show="staticImg1 == 1 && staticImg2 == 1">
      <img src="../../assets/cms1.webp" alt="Cms1" :load="staticImg1 = 1">
      <img src="../../assets/cms2.webp" alt="Cms2" :load="staticImg2 = 1">
    </div>
    </transition>
    <article>
      <h4>Autorski Headless CMS</h4>
      <p>Do każdej aplikacji dołączamy autorski system Headless CMS do zarządzania treścią na stronie. Przy bardziej rozbudowanych aplikacjach będzie dodatkowo służył jako panel administracyjny.</p>
      <ul>
        <li>Lekki i szybki panel, który nie spowalnia strony/aplikacji</li>
        <li>Możliwość zmiany treści w każdej chwili bez ingerencji programisty</li>
        <li>W zależności od zapotrzebowania klienta tworzone są dodatkowe autorskie komponenty</li>
      </ul>
    </article>
  </div>
</template>

<script>
export default {
  name: 'HomeCms',
  data(){
    return{
      staticImg1: 0,
      staticImg2: 0,
    }
  }
}
</script>

<style scoped lang="scss">
  div.home-cms { display: flex; justify-content: center; flex-wrap: wrap; margin: 100px 0; background: #fff; }

  div.imgs { width: 600px; height: 381px; margin: 0 50px; position: relative; }
  div.imgs img { display: block; position: absolute; top: 0; left: 0; box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.6); }
  div.imgs img:nth-child(2) { top: 100px; left: 100px; }

  article { width: 600px; margin: 0 50px; }
  article h4 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 300; }
  article p { margin: 0; padding: 0; font-size: 18px; color: #616161; line-height: 30px; }
  article ul { margin: 30px 0 30px 25px; padding: 0; list-style: none; }
  article ul li { font-size: 18px; color: #616161; line-height: 30px; }
  article  ul li::before { content: "\2022";  color: $blue;  font-weight: bold;  display: inline-block;  width: 1em; margin-left: -1em;  font-size: 25px; }

  @media (max-width: 1400px) {
    div.imgs { margin: 0 0 50px 0; }
  }

  @media (max-width: 1200px) {
    article h4 { text-align: center; }
  }
  @media (max-width: 1100px) {
    div.imgs { margin: 0 0 50px 0; }
    article { margin: 25px 0 0 0; }
    article p { text-align: center; }
    article ul li { text-align: center; }
  }
  @media (max-width: 700px) {
    article { width: calc(100vw - 40px); margin: 0 auto; }
  }
  @media (max-width: 620px) {
    div.imgs { width: 330px; height: 199px; }
    div.imgs img { width: 300px; height: 169px; }
    div.imgs img:nth-child(2) { top: 30px; left: 30px; }
  }
  @media (max-width: 500px) {
    div.home-cms { margin: 50px 0; }
    article h4 { font-size: 40px; }
  }
</style>
