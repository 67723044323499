<template>
  <footer>
    <h6><span>Company Name</span> 2021 &copy; All right reserved.</h6>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
 
}
</script>

<style scoped lang="scss">
  footer { height: 40px; border-top: 1px solid rgba(0, 0, 0, 0.1);; overflow: hidden;}
  footer h6 { margin: 0; padding: 0; text-align: center; line-height: 40px; color: rgba(0, 0, 0, 0.5); font-weight: 500; font-size: 14px; }
  footer h6 span { color:#5041a0; }
</style>
