<template>
    <div class="form-wrapper">
        <div class="form-box">
            <div class="input-box" :class="{ error: checkError(1) }">
                <input type="text" required>
                <span class="label">Imię i nazwisko</span>
                <transition name="expand">
                    <div class="error" v-if="checkError(1)">
                        <i class="material-icons">error_outline</i>
                        <span>Nieudana walidacja</span>
                    </div>
                </transition>
            </div>
        </div>
        <div class="form-box">
            <div class="input-box" :class="{ error: checkError(2) }">
                <input type="text" required>
                <span class="label">Imię i nazwisko</span>
                <transition name="expand">
                    <div class="error" v-if="checkError(2)">
                        <i class="material-icons">error_outline</i>
                        <span>Nieudana walidacja</span>
                    </div>
                </transition>
            </div>
            <div class="input-box" :class="{ error: checkError(3) }">
                <input type="text" required>
                <span class="label">Imię i nazwisko</span>
                <transition name="expand">
                    <div class="error" v-if="checkError(3)">
                        <i class="material-icons">error_outline</i>
                        <span>Nieudana walidacja</span>
                    </div>
                </transition>
            </div>
        </div>
        <div class="form-box">
            <div class="input-box" :class="{ error: checkError(4) }">
                <input type="text" required>
                <span class="label">Imię i nazwisko</span>
                <transition name="expand">
                    <div class="error" v-if="checkError(4)">
                        <i class="material-icons">error_outline</i>
                        <span>Nieudana walidacja</span>
                    </div>
                </transition>
            </div>
            <div class="input-box" :class="{ error: checkError(5) }">
                <input type="text" required>
                <span class="label">Imię i nazwisko</span>
                <transition name="expand">
                    <div class="error" v-if="checkError(5)">
                        <i class="material-icons">error_outline</i>
                        <span>Nieudana walidacja</span>
                    </div>
                </transition>
            </div>
            <div class="input-box" :class="{ error: checkError(6) }">
                <input type="text" required>
                <span class="label">Imię i nazwisko</span>
                <transition name="expand">
                    <div class="error" v-if="checkError(6)">
                        <i class="material-icons">error_outline</i>
                        <span>Nieudana walidacja</span>
                    </div>
                </transition>
            </div>
        </div>
        <div class="form-box">
            <div class="input-box textarea" :class="{ error: checkError(7) }">
                <textarea cols="30" rows="20" required></textarea>
                <span class="label">Imię i nazwisko</span>
                <transition name="expand">
                    <div class="error" v-if="checkError(7)">
                        <i class="material-icons">error_outline</i>
                        <span>Nieudana walidacja</span>
                    </div>
                </transition>
            </div>
        </div>
        <div class="flex">
            <button>Wyślij</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Form1',
    data() {
        return {
            error: [1],
        }
    },
    methods: {
        checkError(index) {
            if(this.error.includes(index)) {
                return 1;
            } else {
                return 0;
            };
        },
    },
}
</script>

<style lang="scss" scoped>
    .form-wrapper {
        margin: -80px auto 50px auto;
        padding: 50px;
        width: calc(50vw + 100px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 5px #000;
        background: #fff;
        border-radius: 3px;
        position: relative;
    }
    .form-box {
        width: 50vw;
        display: flex;
        justify-content: center;
    }
    .input-box {
        width: 100%;
        margin: 5px;
        position: relative;
    }
    .input-box input,
    .input-box textarea {
        width: 100%;
        border: 1px solid #5041a0;
        border-radius: 4px;
        padding-left: 10px;
        outline: none;
    }
    .input-box textarea {
        resize: none;
        padding: 10px;
    }
    .input-box input {
        height: 40px;
        padding: 6px;
    }
    .input-box span.label {
        position: absolute;
        left: 8px;
        top: calc(50% - 7px);
        font-size: 13px;
        pointer-events: none;
        color: gray;
        background-color: white;
        transition: 0.2s ease all;
    }
    .input-box.textarea span.label {
        top: 10px;
        left: 10px;
    }
    .input-box input:focus ~ span.label,
    .input-box input:not(:focus):valid ~ span.label,
    .input-box textarea:focus ~ span.label,
    .input-box textarea:not(:focus):valid ~ span.label {
        color: teal;
        font-size: 10px;
        top: -6px;
        padding: 0 3px;
    }
    .input-box div.error {
        color: red;
        background-color: white;
        display: flex;
        align-items: center;
        font-size: 10px;
        padding: 0 3px;
        position: absolute;
        right: 15px;
        bottom: -6px;
        overflow: hidden;
    }
    .input-box.textarea div.error {
        bottom: -2px;
    }
    .input-box div.error i.material-icons {
        font-size: 15px;
        margin-right: 3px;
    }
    .input-box.error input,
    .input-box.error textarea {
        border-color: red;
    }
    .input-box.error input:focus ~ span.label,
    .input-box.error input:not(:focus):valid ~ span.label,
    .input-box.error textarea:focus ~ span.label,
    .input-box.error textarea:not(:focus):valid ~ span.label {
        color: red;
    }
    .expand-enter-active,
    .expand-leave-active {
        transition: all .1s linear;
    }
    .expand-enter,
    .expand-leave-to {
        opacity: 0;
    }

    div.flex { display: flex; justify-content: flex-end; width: 50vw; }
    button { display: inline-block; width: 200px; margin: 10px 0 0 0; padding: 13px 0; text-align: center; background: transparent; border-radius: 2px; text-decoration: none; color: #5041a0; letter-spacing: 1px; font-size: 14px; font-weight: 500;
        border: 1px solid #5041a0; }
    button:hover { background: none; color: #00ed93; border: 1px solid #00ed93; cursor: pointer; }
</style>
