<template>
  <div class="error-404">
    <div class="particles">
      <Particles id="particles7" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
    <div class="wrapper">
      <div class="container">
        <h5>404</h5>
        <h6>Ta strona nie istnieje</h6>
        <router-link class="default" to="/">Przejdź do strony głównej</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Project',
  data(){
    return{
      particlesInit: 0,
      particlesLoaded: 0,
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    }
  },
}
</script>

<style scoped lang="scss">
  div.error-404 { position: relative; min-height: calc(100vh - 40px); padding-top: 70px; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  div.wrapper { display: flex; flex-wrap: wrap; justify-content: center; align-items: center; position: relative; z-index: 2; min-height: calc(100vh - 40px - 70px); }
  div.container { display: flex; flex-direction: column; justify-content: center; }
  h5 { margin: 0; padding: 0; font-size: 150px; font-weight: 400; color: #fff; text-align: center; }
  h6 { margin: 0 0 20px 0; padding: 0; font-size: 28px; font-weight: 400; color: rgba(255, 255, 255, 0.5); text-align: center; }

  @media (max-width: 400px) {
    h5 { font-size: 100px; }
    h6 { font-size: 22px; }
  }
</style>
