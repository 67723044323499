<template>
  <section id="theme1-section4">
    <div class="container">
      <h3>Lorem psium si dolor</h3>
       <ul>
        <li>
          <i class="material-icons">done</i>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </li>
        <li>
          <i class="material-icons">done</i>
          <p>In aliquam massa magna, egestas volutpat tortor fringilla nec. Integer pharetra porta rutrum.</p>
        </li>
        <li>
          <i class="material-icons">done</i>
          <p>In aliquet massa quis mauris pellentesque</p>
        </li>
        <li>
          <i class="material-icons">done</i>
          <p>Sed gravida semper enim, sit amet semper orci. Fusce dignissim ante nec dictum condimentum</p>
        </li>
        <li>
          <i class="material-icons">done</i>
          <p>n aliquet massa quis mauris pellentesque</p>
        </li>
      </ul>
      <p>Integer pharetra porta rutrum. Nulla imperdiet, sem ullamcorper pretium convallis, neque dui hendrerit lacus, quis congue ligula arcu et mauris.</p>
    </div>
    <img src="../../../assets/themes/theme1/img1.png">
  </section>
</template>

<script>
export default {
  name: 'Theme1Section4',
 
}
</script>

<style scoped lang="scss">
  section#theme1-section4 { display: flex; align-items: center; justify-content: center; flex-wrap: wrap; padding: 100px 0; background: rgb(1,130,226); 
    background: linear-gradient(90deg, rgba(1,130,226,1) 0%, rgba(0,237,147,1) 100%); }

  img { display: block; width: 500px; height: 497px; margin-left: 50px; }
  div.container { width: 600px; margin-right: 50px; }
  div.container h3 { margin: 0 0 30px 0; padding: 0; font-size: 50px; font-weight: 300; color: #fff; }
  div.container ul { margin: 0; padding: 0; list-style: none; }
  div.container ul li { display: flex; align-items: flex-start; margin-bottom: 15px; }
  div.container ul li i.material-icons { flex-shrink: 0; display: flex; justify-content: center; align-items: center; width: 20px; height: 20px; margin-top: 2.5px; border-radius: 50%; border: 1px solid #00ed93; font-size: 12px; color: #00ed93; }
  div.container ul li p { margin: 0 0 0 10px; padding: 0; font-size: 18px; color:#fff; line-height: 25px; }
  div.container p { margin: 30px 0 0 0; padding: 0; color: #fff; font-size: 19px; line-height: 25px; }

  @media (max-width: 1200px) {
    div.container { width: calc(100vw - 40px); margin: 0 auto; }
    img { margin: 50px auto 0 auto; }
  }

  @media (max-width: 700px) {
     section#theme1-section4 { padding: 50px 0; }
     div.container h3 { font-size: 36px; }
  } 
  @media (max-width: 540px) {
     img { width: calc(100vw - 40px); height: inherit; }
  }  

</style>
