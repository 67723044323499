<template>
    <section class="carousel2-wrapper">
        <carousel-2-wrapper class="carousel">
            <carousel-2-slide v-for="(slide, index) in slides"
                class="carousel-slider"
                :key="slide + index" 
                :index="index"
                :visibleSlide="visibleSlide"
                :direction="direction"
            >
                <div class="text">
                    <h4>{{ slide.title }}</h4>
                    <p>{{ slide.content }}</p>
                </div>
                <div class="image-wrapper">
                    <div class="image">
                        <img :src="slide.image" />
                    </div>
                </div>
            </carousel-2-slide>
            <button class="next" @click="next()">
                <i class="material-icons">expand_more</i>
            </button>
            <button class="prev" @click="prev()">
                <i class="material-icons">expand_more</i>
            </button>
            <div class="circles">
                <div v-for="(circleSlide, circleIndex) in slides" 
                    class="circle" 
                    :class="{ active: circleIndex === visibleSlide }"  
                    :key="circleIndex + circleSlide.title"
                    @click="changeVisibleSlide(circleIndex)"
                ></div>
            </div>
        </carousel-2-wrapper>
    </section>
</template>

<script>
import Carousel2Slide from './Carousel2Slide';
import Carousel2Wrapper from './Carousel2Wrapper';

export default {
    name: 'Carousel1',
    components: {
        Carousel2Slide,
        Carousel2Wrapper
    },
    data() {
        return {
            slides: [{
                title: 'Lorem ipsum dolor',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis elit nunc. Aenean nec ipsum urna. Nunc sagittis tincidunt quam commodo efficitur. Aenean porttitor dictum risus et bibendum. Aenean volutpat est et turpis rhoncus scelerisque. Maecenas nec purus nunc. Curabitur a metus sollicitudin, tempor tellus id, ullamcorper velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
                image: 'https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
            },{
                title: 'Lorem ipsum dolor',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis elit nunc. Aenean nec ipsum urna. Nunc sagittis tincidunt quam commodo efficitur. Aenean porttitor dictum risus et bibendum. Aenean volutpat est et turpis rhoncus scelerisque. Maecenas nec purus nunc. Curabitur a metus sollicitudin, tempor tellus id, ullamcorper velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
                image: 'https://images.unsplash.com/photo-1498837167922-ddd27525d352?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
            },{
                title: 'Lorem ipsum dolor',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis elit nunc. Aenean nec ipsum urna. Nunc sagittis tincidunt quam commodo efficitur. Aenean porttitor dictum risus et bibendum. Aenean volutpat est et turpis rhoncus scelerisque. Maecenas nec purus nunc. Curabitur a metus sollicitudin, tempor tellus id, ullamcorper velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
                image: 'https://images.unsplash.com/photo-1476224203421-9ac39bcb3327?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
            },{
                title: 'Lorem ipsum dolor',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis elit nunc. Aenean nec ipsum urna. Nunc sagittis tincidunt quam commodo efficitur. Aenean porttitor dictum risus et bibendum. Aenean volutpat est et turpis rhoncus scelerisque. Maecenas nec purus nunc. Curabitur a metus sollicitudin, tempor tellus id, ullamcorper velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
                image: 'https://images.unsplash.com/photo-1473093295043-cdd812d0e601?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80',
            },{
                title: 'Lorem ipsum dolor',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In quis elit nunc. Aenean nec ipsum urna. Nunc sagittis tincidunt quam commodo efficitur. Aenean porttitor dictum risus et bibendum. Aenean volutpat est et turpis rhoncus scelerisque. Maecenas nec purus nunc. Curabitur a metus sollicitudin, tempor tellus id, ullamcorper velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.',
                image: 'https://images.unsplash.com/photo-1534422298391-e4f8c172dddb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            },
            ],
            visibleSlide: 0,
            direction: 'left',
            windowWidth: 0,
            imgHeight: 0,
            imgScale: {
                width: 16,
                height: 9
            },
        }
    },
    methods: {
        next() {
            if(this.visibleSlide >= this.slides.length - 1) {
                this.visibleSlide = 0;
            } else {
                this.visibleSlide++;
            };
            this.direction = 'left';
        },
        prev() {
            if(this.visibleSlide <= 0) {
                this.visibleSlide = this.slides.length - 1;
            } else {
                this.visibleSlide--;
            };
            this.direction = 'right';
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        getImgHeight() {
            this.imgHeight = this.windowWidth / this.imgScale.width * this.imgScale.height;
        },
        changeVisibleSlide(index) {
            if(this.visibleSlide > index) {
                this.direction = 'right';
            } else {
                this.direction = 'left';
            };
            this.visibleSlide = index;
        },
    },
    watch: {
        windowWidth() {
            this.getImgHeight();
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    p {
        font-weight: 300;
        font-size: 13px;
        line-height: 25px;
        margin: 0;
    }
    h4 {
        font-weight: 300;
        font-size: 40px;
        margin: 0;
        margin-bottom: 30px;
    }
    section.carousel2-wrapper {
        width: 100vw;
        max-height: 100vh;
        overflow: hidden;
        background: #5041a0;
    }
    section.carousel2-wrapper .carousel {
        position:relative;
        overflow: hidden;
        width: 100%;
        max-height: 100vh;
        z-index: 1;
        min-height: 75vh;
    }
    section.carousel2-wrapper .carousel-slider {
        position:absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
    }
    section.carousel2-wrapper div.text {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        padding-left: 100px;
        color: #fff;
    }
    section.carousel2-wrapper div.image-wrapper {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        padding-right: 0;
    }
    section.carousel2-wrapper div.image-wrapper div.image {

    }
    section.carousel2-wrapper img {
        width: 100%;
        height: 100%;
    }
    section.carousel2-wrapper button {
        position: absolute;
        background: rgba(#000, .5);
        color: #fff;
        font-size: 0;
        border: none;
        padding: 5px;
        top: calc(50% - 30px);
    }
    section.carousel2-wrapper button {
        cursor: pointer;
    }
    section.carousel2-wrapper i {
        font-size: 50px;
    }
    section.carousel2-wrapper button.next {
        right: 8px;
    }
    section.carousel2-wrapper button.next i {
        transform: rotate(-90deg);
    }
    section.carousel2-wrapper button.prev i {
        transform: rotate(90deg);
    }
    section.carousel2-wrapper div.circles {
        position: absolute;
        bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    section.carousel2-wrapper div.circles div.circle {
        width: 50px;
        height: 4px;
        background-color: rgba(#fff, .5);
        // border: 2px solid rgba(#fff, .5);
        margin: 5px;
        // border-radius: 50%;
    }
    section.carousel2-wrapper div.circles div.circle:hover {
        cursor: pointer;
    }
    section.carousel2-wrapper div.circles div.circle.active {
        background-color: #fff;
    }
</style>
