<template>
    <header :class="{ fixed: headerFixed, 'no-bg': navMobile }">
        <h1 @click="scrollToElement('carousel-4')">Company Name</h1>
        <ul>
            <li @click="scrollToElement('carousel-4')" :class="{ active: activeSection == 'carousel-4' }"><span>Slider</span></li>
            <li @click="scrollToElement('section-6')" :class="{ active: activeSection == 'section-6' }"><span>Sekcja 1</span></li>
            <li @click="scrollToElement('section-7')" :class="{ active: activeSection == 'section-7' }"><span>Sekcja 2</span></li>
            <li @click="scrollToElement('gallery-1')" :class="{ active: activeSection == 'gallery-1' }"><span>Galeria</span></li>
            <li @click="scrollToElement('form-2')" :class="{ active: activeSection == 'form-2' }"><span>Formularz</span></li>
            <li @click="scrollToElement('theme3-contact')" :class="{ active: activeSection == 'theme3-contact' }"><span>Kontakt</span></li>
        </ul>
        <div class="menu">
            <i class="material-icons">{{ menuIcon }}</i>
        </div>
    </header>
</template>

<script>
export default {
  name: "TheHeader",
  data() {
    return {
      headerFixed: 0,
      scrollListener: 0,
      activeRoute: this.$route.name,
      menuIcon: "menu",
    };
  },
  computed: {
    navMobile() {
      return this.$store.state.navMobile;
    },
    activeSection() {
      return this.$store.state.onePage2ActiveSection;
    },
  },
  created() {
    let self = this;
    this.scrollListener = window.addEventListener("scroll", function () {
      if (document.documentElement.scrollTop > 0) {
        self.headerFixed = 1;
      } else {
        self.headerFixed = 0;
      }
    });
    if (document.documentElement.scrollTop > 0) {
        self.headerFixed = 1;
    } else {
        self.headerFixed = 0;
    };
  },
  watch: {
    $route(to, from) {
      this.activeRoute = this.$route.name;
    },
    navMobile() {
      if (this.navMobile) {
        this.menuIcon = "close";
      } else {
        this.menuIcon = "menu";
      }
    },
  },
  methods: {
    toggleNavMobile() {
      if (!this.navMobile) {
        this.$store.commit("toggleNavMobile", 1);
        document.body.style = "overflow-y: hidden";
      } else {
        this.$store.commit("toggleNavMobile", 0);
        document.body.style = "overflow-y: overlay";
      }
    },
    scrollToElement(id) {
        const element = document.getElementById(id);
        const offset = 70;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset + window.pageYOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    },
  },
  destroyed() {
    window.removeEventListener("scroll");
  },
};
</script>
<style scoped lang="scss">
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        height: 100px;
        padding: 0 100px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 5;
        transition: all .2s ease-in-out;
    }
    header.fixed {
        background: #000;
        height: 70px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    h1 {
        margin: 0;
        padding: 0;
    }
    h1 {
        display: block;
        color: #fff;
        text-decoration: none;
        font-weight: 600;
        font-size: 24px;
    }
    h1:hover {
        color: #f5af19;
        cursor: pointer;
    }
    ul {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
    }
    ul li span{
        display: inline-block;
        padding: 7px 5px;
        margin: 0 50px 0 0;
        color: rgba(#fff, .9);
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none;
    }
    ul li:last-child span {
        margin-right: 0;
    }
    ul li:hover span {
        color: #f5af19;
        cursor: pointer;
    }
    ul li.active span {
        color: #f5af19;
        border-bottom: 2px solid #f5af19;
    }
    div.menu {
        display: none;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
    }
    div.menu:hover {
        background: rgba(0, 129, 168, 0.1);
        transition: 0.5s;
        cursor: pointer;
    }
    div.menu i.material-icons {
        color: #fff;
        font-size: 32px;
    }
    @media (max-width: 1000px) {
      ul {
        display: none;
      }
      header {
        height: 70px;
        padding: 0 30px;
        position: absolute;
      }
      header.fixed {
        background: transparent;
        box-shadow: none;
      }
    }
</style>
