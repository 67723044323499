<template>
    <section class="theme4-section1">
        <div class="boxes-wrapper" :style="{ height: boxHeight + 'px' }">
            <div class="box" v-for="(box, index) in boxContent" :key="box.title + index">
                <i class="material-icons">{{ box.icon }}</i>
                <h4>{{ box.title }}</h4>
                <p>{{ box.content }}</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Theme4Section1',
    data() {
        return {
            boxContent: [{
                icon: 'engineering',
                title: 'Lorem ipsum',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend.'
            },{
                icon: 'construction',
                title: 'Lorem ipsum',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend.'
            },{
                icon: 'square_foot',
                title: 'Lorem ipsum',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend.'
            },{
                icon: 'roofing',
                title: 'Lorem ipsum',
                content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed interdum vitae libero ac eleifend.'
            },],
            windowWidth: 0,
            boxHeight: null,
        }
    },
    watch: {
        windowWidth() {
            this.setboxHeight();
        },
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        setboxHeight() {
            this.boxHeight = this.windowWidth * 0.25 / 0.8;
            if(this.windowWidth <= 500) {
                this.boxHeight = this.windowWidth * 4 / 0.8;
            }else if(this.windowWidth <= 1000) {
                this.boxHeight = this.windowWidth / 0.8;
            }
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style lang="scss" scoped>
    h4 {
        margin: 10px 2vw;
    }
    p {
        text-align: justify;
        margin: 10px 2vw;
    }
    div.boxes-wrapper {
        width: 100vw;
        display: flex;
    }
    div.box {
        width: 25%;
        height: 100%;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    div.box:nth-child(odd) {
        background-color: $Theme4Primary;
    }
    div.box:nth-child(even) {
        background-color: $Theme4Secondary;
    }
    div.box i {
        font-size: 10vw;
    }
    @media (max-width: 1000px) {
        div.boxes-wrapper {
            flex-wrap: wrap;
        }
        div.box {
            width: 50%;
            height: 50%;
        }
        div.box:nth-child(odd) {
            background-color: $Theme4Primary;
        }
        div.box:nth-child(even) {
            background-color: $Theme4Primary;
        }
        div.box:nth-child(4n-2) {
            background-color: $Theme4Secondary;
        }
        div.box:nth-child(4n-1) {
            background-color: $Theme4Secondary;
        }
        div.box i {
            font-size: 20vw;
        }
    }
    @media (max-width: 500px) {
        div.box {
            width: 100%;
            height: 25%;
        }
        div.box:nth-child(odd) {
            background-color: $Theme4Primary;
        }
        div.box:nth-child(even) {
            background-color: $Theme4Secondary;
        }
        div.box i {
            font-size: 40vw;
        }
    }
</style>
