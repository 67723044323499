<template>
  <div class="offer">
    <div class="particles">
      <Particles id="particles4" :particlesInit="particlesInit" :particlesLoaded="particlesLoaded" :options="particles" />
    </div>
    <div class="wrapper">
      <header>
        <h3>Wycena</h3>
      </header>
      <section id="price">
        <p>Chcemy aby projekty wykonane przez nas były jak najlepszej jakości i idealnie wpasowywały się w potrzeby klienta. Z tego powodu cena ustalana jest indywidualnie. Skontaktuj się z nami w celu ustalenia najlepszej oferty dla Ciebie.</p>
        <div class="flex">
          <router-link class="default" to="/kontakt">zapytaj o wycenę</router-link>
        </div>
      </section>
      <header>
        <h3>Web</h3>
      </header>
      <section id="web">
        <article>
          <div class="icon">
            <i class="material-icons">apps</i>
          </div>
          <div class="content">
            <h4>Gotowe szablony i elementy</h4>
            <p>Udostępniamy cztery gotowe szablony. Można z nich skorzystać w całości lub wybrać elementy, które nam pasują i z nich złożyć nową stronę. To rozwiązanie jest szybsze  i tańsze w porównaniu z indywidualnym projektem. </p>
          </div>
        </article>
        <article>
          <div class="icon">
            <i class="material-icons">brush</i>
          </div>
          <div class="content">
            <h4>Indywidualny projekt</h4>
            <p>Dla osób chcących strony skrojonej pod siebie polecamy skorzystać z usługi indywidualnego projektu. Przy współpracy z grafikiem przygotuj unikatowy projekt, który wyróżni Cię na tle konkurencji.</p>
          </div>
        </article>
        <article>
          <div class="icon">
            <img src="../assets/offer/icon1.png" alt="Ikona1">
          </div>
          <div class="content">
            <h4>Strona One Page</h4>
            <p>Jest to strona firmowa czy produktu, która nie zawiera oddzielnych podstron. Strona internetowa składa się z jednej strony, bardzo często wydłużonej. Poruszać się można po niej dzięki nawigacji która odsyła do wybranych sekcji na stronie lub używając scrolla.</p>
          </div>
        </article>
        <article>
          <div class="icon">
            <img src="../assets/offer/icon2.png" alt="Ikona2">
          </div>
          <div class="content">
            <h4>Strona Landing Page</h4>
            <p>Jest to strona internetowa, która ma na celu promocję lub sprzedaż produktu lub usługi. Użytkownik po kliknięciu w baner reklamowy czy link z mailingu zostanie przekierowany na landing page, który ma za zadanie nakłonić go do określonego działania.</p>
          </div>
        </article>
        <article>
          <div class="icon">
            <img src="../assets/offer/icon3.png" alt="Ikona3">
          </div>
          <div class="content">
            <h4>Strona firmowa</h4>
            <p>To najważniejsze narzędzie do kreowania wizerunku firmy w Internecie. Może zawierać m.in. informacje o produkcie/usłudze, lokalizacji firmy, godzinach pracy czy kontakcie.</p>
          </div>
        </article>
        <article>
          <div class="icon">
            <img src="../assets/offer/icon4.png" alt="Ikona4">
          </div>
          <div class="content">
            <h4>Aplikacje WWW</h4>
            <p>Zaawansowane skrypty usprawniające i urozmaicające tworzone strony internetowe, które rozszerzają możliwości zwykłych stron. Bierzemy pod uwagę życzenia oraz pomysły klienta i na ich podstawie opracujemy rozwiązania informatyczne, które spełniają określone wymagania.</p>
          </div>
        </article>
        <article>
          <div class="icon">
            <i class="material-icons">dashboard</i>
          </div>
          <div class="content">
            <h4>System zarządzania treścią</h4>
            <p>Do każdego projektu dołączamy autorski system zarządzania treścią. Klient w dowolnym momencie ma możliwość edycji treści na stronie całkowicie bezpłatnie. Na życzenie klienta możemy dorobić komponenty, które umożliwią jeszcze większą kontrolę nad stroną. W większych i trudniejszych projektach system ten zamienia się w pełnoprawny panel administracyjny do zarządzania aplikacją www.</p>
          </div>
        </article>
        <article>
          <div class="icon">
            <i class="material-icons">build</i>
          </div>
          <div class="content">
            <h4>Czynności techniczne</h4>
            <p>Na potrzeby projektu zajmiemy się takimi czynnościami jak: konfiguracja domen, generacja certyfikatów SSL, zakładanie baz danych oraz umieszczenie aplikacji/strony na hostingu.</p>
          </div>
        </article>
      </section>
      <header>
        <h3>Design</h3>
      </header>
      <section id="design">
        <section>
          <article>
            <div class="icon">
              <img src="../assets/offer/icon5.png" alt="Ikona5">
            </div>
            <div class="content">
              <h4>Identyfikacja wizualna</h4>
              <p>Logo – tworzone zawsze od podstaw na podstawie szczegółowej analizy rynku oraz potrzeb Klienta. W skład identyfikacji wizualnej firmy wchodzi również nieograniczona ilość i rodzaj nośników prezentujących logo – wszystko według potrzeb i pomysłu na promocję marki.</p>
            </div>
          </article>
          <article>
            <ul>
              <li>logo</li>
              <li>wizytówki</li>
              <li>papier firmowy</li>
              <li>teczki</li>
              <li>identyfikatory</li>
              <li>nadruki na dowolnym nośniku (smycz, kubek, pendrive, odzież)</li>
            </ul>
          </article>
        </section>
        <section>
          <article>
            <div class="icon">
              <img src="../assets/offer/icon6.png" alt="Ikona6">
            </div>
            <div class="content">
              <h4>Reklama Internetowa</h4>
              <p>W obecnych czasach internet stał się wiodącym kanałem sprzedaży, dlatego tak ważna jest promocja firmy, usług lub produktów online. Tworzymy każdy rodzaj reklamy internetowej – od pojedynczego okienka po rozbudowane kampanie.</p>
            </div>
          </article>
          <article>
            <ul>
              <li>banery</li>
              <li>slidery</li>
              <li>okna</li>
              <li>reklamy internetowe</li>
              <li>Google Ads</li>
              <li>posty social media</li>
              <li>mailingi</li>
              <li>newslettery</li>
              <li>wizualizacje produktów</li>
            </ul>
          </article>
        </section>
        <section>
          <article>
            <div class="icon">
              <img src="../assets/offer/icon7.png" alt="Ikona7">
            </div>
            <div class="content">
              <h4>Reklama wewnętrzna</h4>
              <p>Ulotki, plakaty czy katalogi to sprawdzony sposób na dotarcie do Klienta i przedstawienie oferowanych usług bądź produktów w tradycyjny sposób. Nasze projekty są zawsze estetyczne, spójne i oparte na najnowszych trendach.</p>
            </div>
          </article>
          <article>
            <ul>
              <li>foldery</li>
              <li>oferty</li>
              <li>ulotki</li>
              <li>katalogi</li>
              <li>plakaty</li>
              <li>roll-upy</li>
              <li>nadruki</li>
              <li>opakowania</li>
              <li>etykiety</li>
              <li>reklamy prasowe</li>
              <li>kalendarze</li>
            </ul>
          </article>
        </section>
        <section>
          <article>
            <div class="icon">
              <img src="../assets/offer/icon8.png" alt="Ikona8">
            </div>
            <div class="content">
              <h4>Reklama zewnętrzna</h4>
              <p>Najlepsza reklama to taka, która zapada w pamięć mimo, iż widzieliśmy ją przez moment, np. przejeżdżając autem obok billboardu czy przechodząc obok baneru zawieszonego na płocie. Taką właśnie reklamę zewnętrzną proponujemy naszym Klientom.</p>
            </div>
          </article>
          <article>
            <ul>
              <li>billboardy</li>
              <li>banery</li>
              <li>szyldy</li>
              <li>ulotki</li>
              <li>witryny</li>
              <li>tablice</li>
              <li>naklejki</li>
            </ul>
          </article>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Project',
  data(){
    return{
      particlesInit: 0,
      particlesLoaded: 0,
    }
  },
  computed: {
    particles() {
      return this.$store.state.particles;
    }
  },
}
</script>

<style scoped lang="scss">
  div.offer { position: relative; min-height: calc(100vh - 40px); padding-top: 70px; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  div.wrapper { position: relative; z-index: 2; min-height: calc(100vh - 40px - 70px); }

  div.wrapper > header { padding: 25px; }
  div.wrapper > header h3 { margin: 0; padding: 0; font-weight: 300; font-size: 50px; color: #fff; }

  section#price { width: 1200px; margin: 25px auto; background: rgba(255, 255, 255, 0.1); box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); border-radius: 2px; overflow: auto; }
  section#price p { margin: 30px; color: #fff; text-align: justify; line-height: 24px; font-size: 16px;}
  section#price a { margin: 0 30px 30px 30px; }
  section#price div.flex { display: flex; justify-content: flex-end; }

  section#web { display: flex; flex-wrap: wrap; justify-content: center; padding: 25px; }
  article { display: flex; flex-shrink: 0; width: 600px; height: 210px; margin: 25px; box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); border-radius: 2px; }
  article div.content { margin: 30px 30px 30px 15px; display: flex; flex-direction: column; justify-content: center; }
  article h4 { margin: 0 0 10px 0; padding: 0; color: #fff; font-weight: 500; font-size: 20px; }
  article p { margin: 0; padding: 0; font-size: 15px; color: rgba(255, 255, 255, 0.8); line-height: 20px; text-align: justify; }
  div.icon { display: flex; justify-content: center; align-items: center; flex-shrink: 0; align-self: center; width: 60px; height: 60px; margin: 30px 15px 30px 30px;
    border-radius: 50%; background: rgb(0,6,35);
    background: linear-gradient(45deg, rgba(0,6,35,1) 0%, rgba(0,22,62,1) 15%, rgba(0,38,89,1) 30%, rgba(0,54,112,1) 45%, rgba(0,78,132,1) 60%, rgba(0,129,168,1) 75%, rgba(0,206,220,1) 90%, rgba(0,228,236,1) 100%); }
  div.icon i.material-icons { font-size: 28px; color: #fff; }
  div.icon img { display: block; }

  section#design { padding: 25px; }
  section#design section { display: flex; flex-wrap: wrap; justify-content: center; }
  section#design ul { display: flex; flex-wrap: wrap; align-content: flex-start; margin: 0; padding: 30px; list-style: none; }
  section#design ul li { margin: 2px; padding: 7px; background: rgb(168,0,95); background: linear-gradient(45deg, rgba(168,0,95,1) 0%, rgba(255,103,87,1) 100%); border-radius: 2px; color: #fff; text-transform: uppercase;
   letter-spacing: 1px; font-size: 13px; }
  section#design div.icon { background: rgb(168,0,95); background: linear-gradient(45deg, rgba(168,0,95,1) 0%, rgba(255,103,87,1) 100%); }

  @media (max-width: 1240px) {
   section#price { width: calc(100% - 40px); }
  }
  @media (max-width: 650px) {
    div.wrapper > header h3 { font-size: 40px; }

    section#price p { text-align: center; }
    section#price div.flex { justify-content: center; }

    section#web, section#design { padding: 10px; }

    article { flex-direction: column; width: calc(100% - 40px); height: inherit; margin: 15px 10px; }
    article div.content { margin: 10px 20px 20px 20px; }
    article h4 { text-align: center; }
    article p { text-align: center; }
    div.icon { margin: 20px 0; }

    section#design ul { justify-content: center; }
    section#design ul li { text-align: center; }
  }
</style>
