<template>
  <header :class="{ fixed: headerFixed, 'no-bg': navMobile}">
    <h1><router-link :to="{ name: 'Theme2Home' }">Company Name</router-link></h1>
    <ul>
      <li :class="{ active: activeRoute == 'Theme2Home' }"><router-link :to="{ name: 'Theme2Home' }">Strona główna</router-link></li>
      <li :class="{ active: activeRoute == 'Theme2Offer' }"><router-link :to="{ name: 'Theme2Offer' }">Oferta</router-link></li>
      <li :class="{ active: activeRoute == 'Theme2Gallery' }"><router-link :to="{ name: 'Theme2Gallery' }">Galeria</router-link></li>
      <li :class="{ active: activeRoute == 'Theme2Contact' }"><router-link :to="{ name: 'Theme2Contact' }">Kontakt</router-link></li>
    </ul>
    <div class="menu">
      <i class="material-icons">{{ menuIcon }}</i>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  data(){
    return{
      headerFixed: 0,
      scrollListener: 0,
      activeRoute: this.$route.name,
      menuIcon: 'menu',
    }
  },
  computed:{
    navMobile(){
      return this.$store.state.navMobile;
    },
  },
  created(){
    let self = this;
    this.scrollListener = window.addEventListener("scroll", function(){
      if(document.documentElement.scrollTop > 0){
        self.headerFixed = 1;
      }else{
        self.headerFixed = 0;
      }
    });
    if(document.documentElement.scrollTop > 0){
      self.headerFixed = 1;
    }else{
      self.headerFixed = 0;
    }
  },
  watch: {
    $route(to, from){
      this.activeRoute = this.$route.name;
    },
    navMobile(){
      if(this.navMobile){
        this.menuIcon = 'close';
      }else{
        this.menuIcon = 'menu';
      }
    }
  },
  methods: {
    toggleNavMobile(){
      if(!this.navMobile){
        this.$store.commit('toggleNavMobile', 1);
        document.body.style = "overflow-y: hidden";
      }else{
        this.$store.commit('toggleNavMobile', 0);
        document.body.style = "overflow-y: overlay";
      }
    }
  },
  destroyed(){
    window.removeEventListener('scroll', this.scrollListener);
  }
}
</script>
<style scoped lang="scss">
  header { display: flex; align-items: center; justify-content: space-between; width: 100vw; height: 70px; padding: 0 100px; position: fixed; top: 0; left: 0; z-index: 5; }
  header.fixed { background: #5041a0; box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); }
  h1 { margin: 0; padding: 0; }
  h1 a { display: block; color: #fff; text-decoration: none; font-weight: 500; font-size: 24px; }
  ul { display: flex; margin: 0; padding: 0; list-style: none; }
  ul li a { display: inline-block; padding: 7px 5px; margin: 0 25px 0 0; color: rgba(255, 255, 255, 0.5); text-decoration: none; font-size: 14px; font-weight: 400; }
  ul li:last-child a { margin-right: 0; }
  ul li:hover a { color: #fff; }
  ul li.active a { color: #fff; }
  div.menu { display: none; justify-content: center; align-items: center; width: 70px; height: 70px; }
  div.menu:hover { background: rgba(0,129,168,0.1); transition: .5s; cursor: pointer; }
  div.menu i.material-icons { color: #fff; font-size: 32px; }
</style>
