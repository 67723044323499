<template>
    <section class="faq1-wrapper">
        <h3>Najczęściej zadawane pytania (FAQ)</h3>
        <div class="qa-wrapper" v-for="(qa, index) in qaContent" :key="qa + index">
            <div class="question">
                <p>{{ qa.question }}</p>
                <button class="material-icons" @click="qa.expanded = !qa.expanded" :class="{ rotate: qa.expanded }">expand_more</button>
            </div>
            <transition 
            @before-enter="beforeTransition"
            @enter="enter"
            @after-enter="afterTransition"
            
            @before-leave="beforeTransition"
            @leave="leave"
            @after-leave="afterTransition" 
            >
                <div class="answer" v-if="qa.expanded">
                    <p>{{ qa.answer }}</p>
                </div>
            </transition>
        </div>
        <!-- <div class="question-mark first">&#63;</div> -->
        <div class="question-mark second">&#63;</div>
        <div class="question-mark third">&#63;</div>
        <div class="question-mark fourth">&#63;</div>
        <div class="question-mark fifth">&#63;</div>
    </section>
</template>

<script>
const raf = window.requestAnimationFrame;

export default {
    name: 'Faq1',
    data() {
        return {
            qaContent:[{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                expanded: 0
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                expanded: 0
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                expanded: 0
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                expanded: 0
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                expanded: 0
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                expanded: 0
            },{
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ',
                expanded: 0
            },],
        }
    },
    methods: {
        nextFrame (fn) {
            raf(() => {
                raf(fn);
            });
        },
        beforeTransition (el) {
            el.classList.add('height-transition');
        },
        enter (el, done) {
            const height = el.clientHeight;

            el.style['max-height'] = `0px`;

            this.nextFrame(() => {
                el.style['max-height'] = `${height + 5}px`;
            });
        },
        leave (el) {
            el.style['max-height'] = `${el.clientHeight}px`;

            this.nextFrame(() => {
                el.style['max-height'] = `0px`;
            });
        },
        afterTransition (el) {
            el.classList.remove('height-transition');
            el.style['max-height'] = '';
        },
    },
}
</script>

<style lang="scss" scoped>
    section {
        color: rgba(#000, .6);
        position: relative;
    }
    p {
        font-weight: 400;
        font-size: 13px;
        text-align: justify;
        margin: 10px 20px;
        margin-left: 0;
    }
    h3 {
        font-size: 40px;
        font-weight: 500;
        color: #5041a0;
        max-width: 1000px;
        width: 80%;
        text-align: center;
    }
    button {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        transition: all .3s linear;
        color: #5041a0;
        color: white;
    }
    button:hover {
        cursor: pointer;
    }
    button.rotate {
        transition: all .3s linear;
        transform: rotateX(180deg);
    }
    .faq1-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
    }
    div.qa-wrapper {
        max-width: 1000px;
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        background-color: #fff;
        box-shadow: 0 0 5px gray;
    }
    div.qa-wrapper div.question,
    div.qa-wrapper div.answer {
        display: flex;
        align-items: center;
    }
    div.qa-wrapper div.question {
        border-bottom: 1px solid rgba(gray, .2);
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        color: white;
        background-color: #5041a0;
    }
    div.qa-wrapper div.answer {
        overflow: hidden;
    }
    div.qa-wrapper div.question p {
        font-size: 15px;
        font-weight: 600;
    }
    div.qa-wrapper div.answer p {
        padding: 10px 20px;
    }
    .height-transition {
        transition: all .4s ease;
    }
    div.question-mark {
        font-weight: 600;
        color: rgba(108,52,163, .5);
        position: absolute;
        z-index: -1;
    }
    div.question-mark.first {
        font-size: 200px;
        top: 20px;
        left: 1%;
    }
    div.question-mark.second {
        font-size: 100px;
        top: 200px;
        left: 95%;
    }
    div.question-mark.third {
        font-size: 150px;
        top: 400px;
        left: 1%;
    }
    div.question-mark.fourth {
        font-size: 200px;
        top: 600px;
        left: 92%;
    }
    div.question-mark.fifth {
        font-size: 200px;
        top: 650px;
        left: 1%;
    }
</style>
